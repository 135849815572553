import { Axios } from "../Shoothill.Core/Utils";

// App
import * as AppUrls from "../Globals/AppUrls";

export const GetAll = (excludeExcludeFromMainGroup: boolean = false) =>
	Axios.Get<Summerfield.Data.appointmentType[]>(
		`${
			AppUrls.Server.Api.AppointmentType.GetAll
		}?excludeExcludeFromMainGroup=${excludeExcludeFromMainGroup.toString()}`,
	);

export const GetAllForGroup = (appointmentTypeGroupId: string) =>
	Axios.Get<Summerfield.Data.appointmentType[]>(
		`${AppUrls.Server.Api.AppointmentType.GetAllForGroup}/${appointmentTypeGroupId}`,
	);

export const SingleProductCheck = (aptTypeRef: string) =>
	Axios.Post<boolean>(`${AppUrls.Server.Api.Booking.SingleProductCheck}?aptTypeRef=${aptTypeRef}`);
