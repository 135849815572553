import { Register, RegisterUserModel } from "Shoothill.Components/Account";
import React from "react";
import { Theme } from "@material-ui/core";
import { Centered } from "Shoothill.Components/General";
import { useTheme, makeStyles } from "@material-ui/styles";
import { useDataApi } from "Shoothill.Core/Utils";
import { MyDetailsForm } from "../Shoothill.Components/Forms/MyDetailsForm";
import { UseRouter } from "../Shoothill.Core/Utils/Hooks/UseReactRouter";
import { CustomerWrapper } from "../Shoothill.Components/StylesAppSpecific/Customer";
import styled from "styled-components";
import Logo from "../Content/images/summerfieldLogo@2x.png";

const useStyles = makeStyles((theme: Theme) => ({
	main: {
		width: "auto",
		display: "block", // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(6))]: {
			width: "auto",
			marginLeft: "auto",
			marginRight: "auto",
		},
	},
}));

export const RegisterPage: React.FC = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const { history } = UseRouter();
	const { data, isLoading, isError, doRequest } = useDataApi();

	return (
		<Centered>
			<main className={classes.main}>
				<RegisterContainer>
					<CustomerWrapper backgroundcolor="#ffffff" padding="55px 46px" fontcolor="#263959">
						<div className="customer-header">
							<div className="customer-header-img">
								<img className="summerfieldLogo" src={Logo}></img>
								<h1>Registration</h1>
							</div>
							<div className="customer-mydetails">
								<MyDetailsForm
									cancelMsg="Cancel registration"
									onCancel={() => history.push(`/`)}
									onSubmit={() => history.push(`/`)}
									saveMsg="Register as Employee"
									showExtra={true}
									showFurtherInfo={false}
									showPWD
									employerReg
									onlyReg
								/>
							</div>
						</div>
					</CustomerWrapper>
				</RegisterContainer>
			</main>
		</Centered>
	);
};

const RegisterContainer = styled.div`
	#detailsPanelForm > div:nth-of-type(8) {
		border-bottom: solid 1px #337ab7;
		padding-bottom: 15px;
	}

	.customer-header-img {
		flex-direction: column;
		flex-grow: 1 !important;
		align-items: flex-start !important;

		h1 {
			font-size: 1.1rem;
			margin-top: 30px;
			padding-bottom: 20px;
			margin-bottom: 30px;
			border-bottom: 1px solid #337ab7;
		}
	}
`;
