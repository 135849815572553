//EN: To support IE 11
// Uncomment the two lines below
// Revert MobX to 4.12.0
// Delete node_modules directory and reinstall
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import * as MobX from "mobx";
import "index.css";
import App from "App";
import * as serviceWorker from "serviceWorker";
import * as Utils from "./Shoothill.Core/Utils/Utils";
import { useDataApi } from "./Shoothill.Core/Utils";

import * as History from "history";
import { AppErrorBoundary } from "./AppErrorBoundary";
import { Centered, Loader } from "Shoothill.Components/General";
import { StoresContext } from "Stores";
import { InitialState } from "Models";

MobX.configure({ enforceActions: "observed" });

const baseUrl: string | undefined = Utils.getBaseUrl();
const history = History.createBrowserHistory({
	basename: baseUrl,
});

const isDev: boolean = process.env.NODE_ENV === "development";
(window as any).isDev = isDev;
// const devTools: JSX.Element | null = isDev ? <MobXReactDevTools /> : null;

function renderApp() {
	ReactDOM.render(<Init />, document.getElementById("root"));
}

const Init: React.FC = () => {
	const { data, isLoading, isError, doRequest } = useDataApi<InitialState>("/api/initialstate/getinitialstatejson");
	const store = useContext(StoresContext);

	if (isLoading) {
		// EN: Delayed in code behind so we can see the loading text
		return <Loader delayBeforeShow={2000} />;
	} else if (isError) {
		return (
			<Centered>
				<span>Error!!!</span>
			</Centered>
		);
	}

	if (data !== null) {
		// const { Provider, Consumer } = React.createContext(new StoresContext(data, history));
		store.init(data, history);
		return (
			<>
				<AppErrorBoundary>
					<App />
				</AppErrorBoundary>
			</>
		);
	}
	return <></>;
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// if ((module as any).hot) {
//     (module as any).hot.accept("./AppRoutes", () => renderApp());
// }
