import { createContext } from "react";
import * as Utils from "Shoothill.Core/Utils/Utils";
import { InitialState } from "./../../Models/InitialState";
import { Stores } from "./../Stores";
import * as Models from "Models";
import * as MobX from "mobx";
import * as ServerApi from "../../ServerApi";
// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";
import EnquiryViewModel from "ViewModels/EnquiryViewModel";

export class AccountStore extends BaseStore {
	@observable public IsLoggedIn: boolean = false;
	@observable public UserName: string = "";
	@observable public DisplayName: string = "";
	@observable public UserRoles: string[] = [];
	@observable public UseAdminGrids: boolean = false;
	@observable public UseAdminControlsLight: boolean = false;
	@observable public ChangeDetailsOnly = false;
	@observable public ChangeDetailsToken = "";

	private jwt: string | null = null;

	public constructor() {
		super();
	}

	public init(stores: Stores, initialState: InitialState) {
		this.getLoginState();
	}

	@action
	public setIsLoggedIn(state: boolean) {
		this.IsLoggedIn = state;
	}

	public isInRole = (role: string): boolean => {
		if (this.UserRoles && this.UserRoles.length > 0) {
			return this.UserRoles.includes(role);
		}

		return false;
	};

	@action
	public getLoginState = (jwt: string | undefined | null = null) => {
		this.ChangeDetailsOnly = false;
		if (!jwt || jwt.length === 0) {
			this.jwt = sessionStorage.getItem(".auth") as string;
		} else {
			this.jwt = jwt;
		}

		if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
			const data = Utils.parseJwt(this.jwt);
			sessionStorage.setItem(".auth", this.jwt);
			this.IsLoggedIn = true;
			this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
			this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
			this.UserRoles = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string[];
			// EN: For Debugging
			(window as any).jwt = this.jwt;
		}
	};

	@computed get GetUseAdminGrids(): boolean {
		return this.UseAdminGrids;
	}

	@computed get GetUseAdminControlsLight(): boolean {
		return this.UseAdminControlsLight;
	}

	@action
	public ChangeDetailsLogin(token: string) {
		this.IsLoggedIn = true;
		this.ChangeDetailsOnly = true;
		this.ChangeDetailsToken = token;
	}

	/* @MobX.action
	public login = (emailAddress: string, password: string, rememberMe: boolean) => {
		this.setIsLoading();

		return ServerApi.Account.({ emailAddress, password, rememberMe })
			.catch(this.handleHttpError)
			.then(apiResult => {
				return MobX.runInAction(() => {
					if (apiResult.wasSuccessful) {
						const accountStatus = apiResult.payload;
						(window as any).jwt = accountStatus.jwt;
						this.getUserData();
						this.updateFromAccountStatus(accountStatus);
						// axios.defaults.headers.common["Authorization"] = "Bearer " + accountStatus.jwt;
						// don't do this yet this.unsetIsLoading();
					} else {
						this.unsetIsLoading();
					}
					return apiResult;
				});
			});
	}; */

	/* 	@MobX.action
	public logout = () => {
		this.setIsLoading();

		return ServerApi.Account.logout()
			.catch(this.handleHttpError)
			.then(apiResult => {
				return MobX.runInAction(() => {
					if (apiResult.wasSuccessful) {
						const accountStatus = apiResult.payload;

						this.unsetIsLoading();
						sessionStorage.removeItem("jwt");
						(window as any).jwt = "";
					} else {
						this.unsetIsLoading();
					}
					return apiResult;
				});
			});
	}; */

	@action
	public Logout = (keepLocation?: boolean): void => {
		sessionStorage.removeItem(".auth");
		this.IsLoggedIn = false;
		this.UserName = "";
		this.DisplayName = "";
		this.UserRoles = [];
		(window as any).jwt = null;
		if (!keepLocation) {
			window.location.href = "/";
		}
	};
}

export const AccountStoresContext = createContext(
	new AccountStore(),
); /*
	};

	@MobX.action
	private updateFromAccountStatus = (accountStatus: Models.Dto.AccountStatus) => {
		const { id, permissions, displayName, isLoggedIn, jwt } = accountStatus;

		this.id = id;
		this.isLoggedIn = isLoggedIn;
		this.displayName = displayName;
		this.jwt = jwt;
		sessionStorage.setItem("jwt", this.jwt as string);

		if (permissions) {
			this.permissions.replace(permissions);
		} else {
			this.permissions.clear();
		}
	};

	private parseJwt = (token: string) => {
		const base64Url = token.split(".")[1];
		const base64 = decodeURIComponent(
			atob(base64Url)
				.split("")
				.map(c => {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join(""),
		);

		return JSON.parse(base64);
	};
} */

/* export class AccountStore extends BaseStore {
	public models = MobX.observable<Models.Domain.IdentityUser>([]);

	@MobX.observable public id: string | undefined;
	@MobX.observable public displayName = "";
	@MobX.observable public isLoggedIn = false;
	@MobX.observable public jwt: string | undefined = "";

	@MobX.computed
	public get hasGlobalPermission() {
		const hasGlobalPermission = this.hasPermission("global");
		return hasGlobalPermission;
	}

	@MobX.computed
	public get isSysAdminViewMode() {
		const hasGlobalPermission = this.hasGlobalPermission;
		const isSysAdminViewMode = hasGlobalPermission === true;

		return isSysAdminViewMode;
	}

	public permissions = MobX.observable<string>([]);

	public constructor(stores: Stores.StoresContext, initialState: Models.InitialState) {
		super(stores);

		if (initialState) {
			this.id = initialState.accountStatus.id!;
			this.isLoggedIn = initialState.accountStatus.isLoggedIn;
			this.displayName = initialState.accountStatus.displayName;
		}
		if (initialState && initialState.accountStatus.permissions) {
			this.permissions.replace(initialState.accountStatus.permissions);
		}
	}
	@MobX.action
	public getLoginState = () => {
		this.jwt = sessionStorage.getItem("jwt") as string;
		if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
			const data = this.parseJwt(this.jwt);
			const accountStatus: Models.Dto.AccountStatus = {} as Models.Dto.AccountStatus;
			accountStatus.id = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
			accountStatus.isLoggedIn = true;
			accountStatus.displayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
			accountStatus.jwt = this.jwt;
			accountStatus.permissions = [];
			accountStatus.permissions.push(data["http://shoothill.com/identity/claims/permission"][0] as string);
			(window as any).jwt = this.jwt;
			this.getUserData();
			this.updateFromAccountStatus(accountStatus);
		}
	};

	public hasPermission = (target: string, level?: "read" | "write") => {
		for (const userPermission of this.permissions) {
			const userPermissionComponents = userPermission.split(":");
			const userPermissionTarget = userPermissionComponents[0];

			if (userPermissionTarget === "global") {
				return true;
			}

			if (userPermissionTarget === target) {
				if (userPermissionComponents.length === 1) {
					return true;
				}

				if (level == null) {
					return true;
				}

				const userPermissionLevel = userPermissionComponents[1];

				if (level === userPermissionLevel) {
					return true;
				}

				if (level === "write" && userPermissionLevel === "read") {
					return true;
				}
			}
		}

		return false;
	};



	@MobX.action
	public resetPassword = (currentPassword: string, newPassword: string, confirmNewPassword: string) => {
		this.setIsLoading();

		return ServerApi.Account.resetPassword({ confirmNewPassword, currentPassword, newPassword })
			.catch(this.handleHttpError)
			.then(apiResult => {
				this.unsetIsLoading();
			});
	};

	@MobX.action
	public setPassword = (setPassword: Models.Dto.SetPassword) => {
		this.setIsLoading();

		return ServerApi.Account.setPassword(setPassword)
			.catch(this.handleHttpError)
			.then(apiResult => {
				return MobX.runInAction(() => {
					if (apiResult.wasSuccessful) {
						// #2945 temp stopping auto login, since not working
						/// const accountStatus = apiResult.payload;
						// this.getUserData();
						// this.updateFromAccountStatus(accountStatus);
						this.unsetIsLoading();
					} else {
						this.unsetIsLoading();
					}
					return apiResult;
				});
			});
	};

	@MobX.action
	private getUserData = () => {
		this.setIsLoading();
// TODO CMR This isn't implemented
/* 		return ServerApi.ClientUser.getUserAndRelated()
			.catch(this.handleHttpError)
			.then(apiResult => {
				return MobX.runInAction(() => {
					if (apiResult.wasSuccessful) {
						this.stores!.domain.clientUserStore.syncDto(apiResult.payload);
						this.unsetIsLoading();
					}
					this.unsetIsLoading();
					return apiResult;
				});
			}); */
