import { HttpError } from "./HttpError";

//AJ: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/403
export class HttpForbiddenError extends HttpError {
	constructor() {
		//AJ: https://stackoverflow.com/questions/47941324/inherited-exceptions-and-instanceof
		const targetPrototype = new.target.prototype;
		super(403, "Forbidden");
		Object.setPrototypeOf(this, targetPrototype);
	}
}
