import * as ServerApi from "../../ServerApi";

import { Domain, InitialState } from "../../Models";
import { action, autorun, computed, observable, runInAction } from "mobx";

import { ApiResult } from "../../Shoothill.Components/Models";
import { AppointmentVouchers } from "../../Models/Domain/AppointmentVouchers";
import { BaseStore } from "./BaseStore";
import { EnquiryData } from "../../Models/Domain/EnquiryData";
import { PresentHubSignalrClient } from "../SignalrClients/PresentHubSignalrClient";
import { Stores } from "../Stores";
import { locationSearchFormModel } from "Models/FormModels";
import { MultipleProductSetting } from "Models/MultipleProductSetting";

export class EnquiryStore extends BaseStore {
	private presentHubSignalrClient!: PresentHubSignalrClient;

	private multipleProductSettings: MultipleProductSetting[] = [];

	@observable public enquiry: EnquiryData = new EnquiryData(); //Domain.Enquiry = new Domain.Enquiry();
	@observable public vouchers = new AppointmentVouchers();
	@observable public appointmentDuration: number = 0;
	@observable public appointmentCost: number = 0;
	@observable public isProduct: boolean = false;
	@observable public isAllowed?: boolean;
	@observable public requiresId: boolean = false;
	@observable public idUpload: File | null = null;
	@observable public hasExpress: boolean = false;
	@observable public expressCost?: number;
	@observable public useExpress: boolean = false;
	@observable public hasCert: boolean = false;
	@observable public allowVoucher: boolean = false;
	@observable public addCert: boolean = false;
	@observable public sameDay: boolean = false;
	@observable public certLang: string = "";
	@observable public sameDayAvailable: boolean = false;
	@observable public seperateDeliveryAddress = false;
	@observable public seperateSelfIsolationAddress = false;
	@observable public passport?: string = undefined;
	@observable public boxes?: number = 1;
	@observable public quantityString = "";
	@observable public appointmentTypeFromURL = false;
	@observable public showDuration = true;

	public constructor() {
		super();
		//console.log(this);

		this.presentHubSignalrClient = new PresentHubSignalrClient();

		autorun(() => {
			if (this.enquiry.enquiryDetails.enquiryReference) {
				this.presentHubSignalrClient.updateEnquiryRef(this.enquiry.enquiryDetails.enquiryReference);
			}
		});
	}

	@action
	public clampQuantity(quantityString: string) {
		if (!this.canBuyMultipleOfProduct) return;
		this.quantityString = "";
		quantityString = isNaN(parseInt(quantityString)) ? "0" : quantityString;
		this.boxes = Math.max(1, Math.ceil(Math.min(parseInt(quantityString), this.maxUnits!) / this.boxSize!)!);
		requestAnimationFrame(() => {
			runInAction(() => {
				this.quantityString = (this.boxes! * this.boxSize!).toString();
			});
		});
	}

	@action
	public increaseQuantity() {
		this.quantityString = "";
		const quantity = Math.min(this.boxes! * this.boxSize! + this.boxSize!, this.maxUnits!);
		this.boxes = Math.max(1, quantity / this.boxSize!);
		requestAnimationFrame(() => {
			runInAction(() => {
				this.quantityString = (this.boxes! * this.boxSize!).toString();
			});
		});
	}

	@action
	public decreaseQuantity() {
		this.quantityString = "";
		this.boxes = Math.max(1, this.boxes! - 1);
		requestAnimationFrame(() => {
			runInAction(() => {
				this.quantityString = (this.boxes! * this.boxSize!).toString();
			});
		});
	}

	@computed
	public get basePrice() {
		if (!this.canBuyMultipleOfProduct) return 0;
		return this.boxes! * this.boxCost;
	}

	@computed
	public get multiplePrice() {
		if (!this.canBuyMultipleOfProduct) return 0;
		const baseCost = this.basePrice;
		const discount = this.multipleDiscount;
		return baseCost - (discount / 100) * baseCost - this.multipleDiscountValue;
	}

	@computed
	public get multipleDiscount() {
		if (!this.canBuyMultipleOfProduct) return 0;
		let discount = 0;
		/* if (this.boxes! >= 5) {
			discount = 10;
		}
		if (this.boxes! >= 25) {
			discount = 20;
		} */
		return discount;
	}

	@computed
	public get multipleDiscountValue() {
		if (!this.canBuyMultipleOfProduct) return 0;
		let discount = 0;
		if (this.boxes! >= 5) {
			discount = 0.6 * this.boxSize! * this.boxes!;
		}
		if (this.boxes! >= 25) {
			discount = 1.2 * this.boxSize! * this.boxes!;
		}
		return discount;
	}

	public async doProductSingleCheck() {
		this.setIsAllowed(true);
		//? Uncomment this to only allow purchase of one of each product
		// const response = await ServerApi.AppointmentType.SingleProductCheck(this.enquiry.appointmentType!.reference!);
		// if (response && response.wasSuccessful) {
		// 	this.setIsAllowed(response.payload);
		// }
	}

	@computed
	public get price() {
		return this.getPrice(this.useExpress, this.addCert, this.sameDay);
	}

	public getPrice(useExpress: boolean, addCert: boolean, sameDay: boolean, noDiscount: boolean = false) {
		let price =
			useExpress && this.expressCost !== undefined
				? this.appointmentCost + this.expressCost
				: this.appointmentCost;

		price = this.canBuyMultipleOfProduct ? (noDiscount ? this.basePrice : this.multiplePrice) : price;

		if (this.vouchers.isValid) {
			price = this.vouchers.newCost!;
		}

		price += addCert ? 25 : 0;
		price += sameDay ? 49 : 0;
		return price;
	}

	public init(stores: Stores, initialState: InitialState) {
		this.stores = stores;

		this.multipleProductSettings = initialState.multipleProductSettings;
	}

	@computed
	public get canBuyMultipleOfProduct() {
		if (!this.enquiry.appointmentType) {
			return false;
		}
		return (
			this.multipleProductSettings.findIndex(p => p.appointmentTypeId === this.enquiry.appointmentType!.id) >= 0
		);
	}

	@computed
	public get boxSize() {
		if (!this.canBuyMultipleOfProduct) return;
		return this.multipleProductSettings.find(p => p.appointmentTypeId === this.enquiry.appointmentType!.id)!
			.boxSize;
	}

	@computed
	public get maxUnits() {
		if (!this.canBuyMultipleOfProduct) return;
		return this.multipleProductSettings.find(p => p.appointmentTypeId === this.enquiry.appointmentType!.id)!
			.maxUnits;
	}

	@computed
	public get boxCost() {
		if (!this.canBuyMultipleOfProduct) return 0;
		return this.multipleProductSettings.find(p => p.appointmentTypeId === this.enquiry.appointmentType!.id)!
			.boxCost;
	}

	@action
	public setShowDuration(show: boolean) {
		this.showDuration = show;
	}

	@action
	public setAppointmentDuration(duration: number) {
		this.appointmentDuration = duration;
	}

	@action
	public setAppointmentCost(cost: number) {
		this.appointmentCost = cost;
	}

	@action
	public setIsProduct(isProduct: boolean) {
		this.isProduct = isProduct;
		this.setIsAllowed(isProduct ? undefined : true);
	}

	@action
	public setIsAllowed(isAllowed?: boolean) {
		this.isAllowed = isAllowed;
	}

	@action
	public setRequiresId(requiresId: boolean) {
		this.requiresId = requiresId;
	}

	@action
	public setIdUpload(idUpload: File | null) {
		this.idUpload = idUpload;
	}

	@action
	public setHasExpress(hasExpress: boolean) {
		this.hasExpress = hasExpress;
	}

	@action
	public setHasCert(hasCert: boolean) {
		this.hasCert = hasCert;
	}

	@action
	public setAllowVoucher(allowVoucher: boolean) {
		this.allowVoucher = allowVoucher;
	}

	@action
	public setExpressCost(cost?: number) {
		this.expressCost = cost;
	}

	@action
	public setUseExpress(useExpress: boolean) {
		this.useExpress = useExpress;
	}

	@action
	public setAddCert(addCert: boolean) {
		this.addCert = addCert;
	}

	@action
	public setSameDay(sameDay: boolean) {
		this.sameDay = sameDay;
	}

	@action
	public setCertLang(certLang: string) {
		this.certLang = certLang;
	}

	@action
	public setPassport(passport?: string) {
		this.passport = passport;
	}

	@action
	public setSeperateDeliveryAddress(seperateDeliveryAddress: boolean) {
		this.seperateDeliveryAddress = seperateDeliveryAddress;
	}

	@action
	public setSeperateSelfIsolationAddress(seperateSelfIsolationAddress: boolean) {
		this.seperateSelfIsolationAddress = seperateSelfIsolationAddress;
	}

	@action
	public setAppointmentTypeFromURL(appointmentTypeFromURL: boolean) {
		this.appointmentTypeFromURL = appointmentTypeFromURL;
	}
}
