import * as AppUrls from "./Globals/AppUrls";
import { Home } from "./Pages/Home";
import { PrivateRoute } from "Shoothill.Core/Utils";
import React, { useContext } from "react";
import { ForgotPasswordPage } from "./Pages/Account/ForgotPasswordPage";
import { ForgotPasswordComplete } from "./Pages/Account/ForgotPasswordComplete";
import { Route } from "react-router";
import { StoresContext, Stores } from "./Stores";
import { Admin } from "./Pages//Admin/Admin";
import { User } from "./Pages/User";
import { Booking } from "./Pages/Booking";
import { RegisterPage } from "./Pages/RegisterPage";

export const AppRoutes: React.FC = () => {
	const store = useContext<Stores>(StoresContext);
	return (
		<>
			<Route exact path="/" component={Home} />
			<Route exact path="/account/forgotpassword" component={ForgotPasswordPage} />
			<Route exact path={AppUrls.Client.SetPassword} component={ForgotPasswordComplete} />
			<Route exact path="/booking" component={Booking} />
			<Route exact path="/register" component={RegisterPage} />
			{/* 			<PrivateRoute
				exact
				path="/booking/add"
				component={Booking}
				isAllowed={() => {
					return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("Client");
				}}
			/> */}

			{/* 			<PrivateRoute
				exact
				path="/user"
				component={User}
				isAllowed={() => {
					return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
				}}
			/>
			<PrivateRoute
				exact
				path="/admin"
				component={Admin}
				isAllowed={() => {
					return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
				}}
			/> */}
		</>
	);
};
