import React, { useState, useContext, useEffect, useRef } from "react";
import { Theme, Fade, Button } from "@material-ui/core";
import { Centered } from "../../Shoothill.Components/General/";
import { Login, LoginModel } from "../../Shoothill.Components/Account";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useDataApi, UseRouter } from "../../Shoothill.Core/Utils";
import { Redirect } from "react-router";
import { StoresContext, Stores, StoresInstance } from "../../Stores";
import { ApiResult } from "../../Shoothill.Components/Models";
import { runInAction } from "mobx";

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		backgroundRepeat: "none",
		backgroundSize: "cover",
		height: "100%",
	},
	main: {
		width: "100%",
		display: "block", // Fix IE 11 issue.
		height: "100%",
	},
	loginRegisterBox: {
		maxWidth: "300px",
		"@media (min-width: 1024px)": {
			maxWidth: "600px",
		},
		borderRadius: "9px",
		minHeight: "350px",
		/* display: "flex", */
		flexDirection: "column",
		backgroundColor: "#FFFFFF",
		flexGrow: 1,
		justifyContent: "space-between",
		/* flexBasis: 0, */
		padding: "0",
		flexWrap: "wrap",
		overflow: "hidden", // needed to get the rounded corners on the
	},
	registerBox: {
		backgroundColor: "#FFFFFF",
		display: "flex",
		flexGrow: 1,
		flexShrink: 1,
		flexDirection: "column",
		flexBasis: 0,
		minWidth: "300px",
		padding: "0px 20px 10px",
		"@media (min-width: 1024px)": {
			padding: "0px 60px 25px",
		},
	},
	summerfieldLogo: {
		width: "203px",
	},
	loginBox: {
		backgroundColor: "#263959",
		color: "#FFFFFF",
		display: "flex",
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
		flexDirection: "column",
		minWidth: "300px",
		padding: "0px 15px 15px",
		"@media (min-width: 1024px)": {
			padding: "0px 60px 25px",
		},
	},
	registerSpiel: {
		color: "#263959",
		fontSize: "1.2rem",
		fontWeight: "bold",
	},
	loginSpiel: {
		color: "#FFFFFF",
		fontSize: "2rem",
		fontWeight: "bold",
		lineHeight: "1.9rem",
	},
	orSpiel: {
		textAlign: "center",
		padding: "15px 0px 0px",
	},
}));

export const LoginRegister: React.FunctionComponent = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const [errorMessage, setErrorMessage] = useState("");
	const { history, location } = UseRouter();
	const email = useRef("");
	const { data, hasLoaded, isLoading, doRequest } = useDataApi<
		ApiResult<
			| string
			| {
					changeDetails: boolean;
					detailsToken: string;
					customerDetails: Summerfield.Data.customer;
			  }
		>
	>();
	const accountStore = useContext<Stores>(StoresContext).domain.AccountStore;

	useEffect(() => {
		checkLoginReturn();
	}, [hasLoaded]);

	const handleBookingBtn = () => {
		history.push("/booking");
	};

	const handleRegisterEmployeeBtn = () => {
		history.push("/register");
	};

	const handleLoginSubmit = (loginModel: LoginModel) => {
		email.current = loginModel.emailAddress;
		doRequest("/api/account/login", loginModel);
	};

	const checkLoginReturn = () => {
		if (data !== null) {
			if (data.wasSuccessful) {
				if (typeof data.payload === "string") {
					accountStore.getLoginState(data.payload);
					const { from } = (location.state as any) || { from: { pathname: "/" } };
					if (from) {
						history.push(from.pathname);
					}
				} else if (data.payload.detailsToken) {
					runInAction(() => {
						if (typeof data.payload !== "string") {
							StoresInstance.domain.EnquiryStore.enquiry.customerDetails.firstName =
								data.payload.customerDetails.firstName || "";
							StoresInstance.domain.EnquiryStore.enquiry.customerDetails.lastName =
								data.payload.customerDetails.lastName || "";
							StoresInstance.domain.EnquiryStore.enquiry.customerDetails.email = email.current;
							StoresInstance.domain.EnquiryStore.isAllowed = true;
						}
					});
					accountStore.ChangeDetailsLogin(data.payload.detailsToken);
				}
			} else {
				if (data.errors.length > 0) {
					setErrorMessage("* " + data.errors[0].message);
				} else {
					setErrorMessage("* Unknown error has occurred.");
				}
			}
		}
	};

	useEffect(() => {
		checkLoginReturn();
	}, [hasLoaded]);

	if (accountStore.IsLoggedIn) {
		return <Redirect to={"/"} />;
	}

	return (
		<div className={classes.wrapper}>
			<main className={classes.main}>
				<Centered>
					<Fade in timeout={1000}>
						<div className={classes.loginRegisterBox}>
							<div className={classes.loginBox}>
								<Login onSubmit={handleLoginSubmit} errorMessage={errorMessage} isLoading={isLoading} />
							</div>
							<div className={classes.orSpiel}>
								<div className={classes.registerSpiel}>OR</div>
							</div>
							<div className={classes.registerBox}>
								<Button
									onClick={handleBookingBtn}
									variant="contained"
									color="primary"
									className="submit"
								>
									Book an Appointment
								</Button>
								<Button
									onClick={handleRegisterEmployeeBtn}
									variant="contained"
									color="primary"
									className="submit"
								>
									Register an account
								</Button>
							</div>
						</div>
					</Fade>
				</Centered>
			</main>
		</div>
	);
};
