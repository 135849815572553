export const Client = {
	Root: "/",
	SetPassword: "/Account/ForgotPasswordComplete/:email/:token1/:token2",
};

export const Server = {
	Api: {
		Account: {
			Activity: "./api/customer/GetCustomerActivity",
			TestResults: "./api/customer/GetTestResults",
			Appointments: "./api/customer/getcustomerappointments",
			Details: {
				Fetch: "./api/customer/getcustomerdetails",
				Upsert: "./api/customer/upsertdetails",
			},
			Login: "./api/account/login",
			Logout: "./api/account/logout",
			ForgotPassword: "./api/account/forgotpasswordemail",
			ForgotPasswordReset: "./api/account/ForgotPasswordReset",
			RegisterEmployee: "./api/account/registeremployee",
			ResetPassword: "./api/account/resetpassword",
			SetPassword: "./api/account/setpassword",
		},
		AppointmentType: {
			GetAll: "./api/appointmenttype/getall",
			GetAllForGroup: "./api/appointmenttype/getallforgroup",
			ProductRangeCheck: "./api/appointmenttype/productrangecheck",
			NextExpressDeliveryDate: "./api/appointmenttype/nextexpressdeliverydate",
		},
		Booking: {
			DeleteReserved: "./api/booking/deleteappointmentreservation",
			Reserve: "./api/booking/reserve",
			CheckSameDayDriveIn: "./api/booking/checksamedaydrivein",
			Details: {
				Login: "./api/booking/details",
				Registration: "./api/booking/registrationdetails",
				UserCheck: "./api/booking/usercheck",
			},
			SingleProductCheck: "./api/booking/singleproductcheck",
		},
		Clinic: {
			GetAvailableClinics: "./api/clinic/getavailableclinics",
			GetAvailableClinicsByComponents: "./api/clinic/getavailableclinicsbycomponents",
			Test: "./api/clinic/test",
			Test2: "./api/clinic/test2",
		},
		LiteFormEmail: {
			PostLiteFormAsync: "/api/LiteForm/PostLiteFormAsync",
		},
		LocationSearch: {
			GetLocations:
				"https://dev.virtualearth.net/REST/v1/Locations?query=${query}&c=${cultureCode}&key=${key}&jsonp=${callback}&maxResults=${maxNumberOfResults}",
		},
		Payment: {
			TakePayment: "./api/payment/takepayment",
			TakeProductPayment: "./api/payment/productpayment",
			RedeemProductVoucher: "./api/payment/redeemproductvoucher",
		},
		SourceOfIntroduction: {
			GetAll: "./api/sourceofintroduction/getall",
			GetItem: "./api/sourceofintroduction/getitem",
		},
	},
};

export const Admin = {
	Root: "/admin",
};
