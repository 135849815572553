import { Axios } from "../Shoothill.Core/Utils";

// App
import * as AppUrls from "../Globals/AppUrls";

export const GetAvailableClinics = (enquiry: Summerfield.Data.enquiry) =>
	Axios.Post<Summerfield.Data.apmClinic[]>(AppUrls.Server.Api.Clinic.GetAvailableClinics, enquiry);

export const Test = () => Axios.Post(AppUrls.Server.Api.Clinic.Test);

export const Test2 = (enquiry: Summerfield.Data.pwenquiry, start?: number) =>
	Axios.Post(`${AppUrls.Server.Api.Clinic.GetAvailableClinics}${start ? "?start=" + start : ""}`, enquiry);

// Following can be removed once working with 'GetAvailableClinics' if need be
export const GetAvailableClinicsByComponents = (enquiry: Summerfield.Data.pwenquiry) =>
	Axios.Post<Summerfield.Data.apmClinic[]>(AppUrls.Server.Api.Clinic.GetAvailableClinicsByComponents, enquiry);
