import * as Models from "../../Models/";
import * as Validations from "../../Shoothill.Core/Utils/Validations";

import { Customer } from "../../Models/Domain/Customer";
import { ApiResult, SourceOfInterestModel } from "../../Models/";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	InputLabel,
	Link,
	Modal,
	Snackbar,
	TextField,
	Typography,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Stores, StoresContext, StoresInstance } from "Stores";
import { TCModal, TCPaper } from "Shoothill.Components/StylesAppSpecific/TnCModalStyling";
import { useDataApi, useValidation } from "../../Shoothill.Core/Utils";

import { AltSelect } from "Shoothill.Components/StylesAppSpecific/LocationSearchStyling";
import Backdrop from "@material-ui/core/Backdrop";
import DateFnsUtils from "@date-io/date-fns";
import EnquiryViewModel from "../../ViewModels/EnquiryViewModel";
import { GridSpaced } from "../StylesAppSpecific/Customer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Redirect } from "react-router-dom";
import { RegisterUserModel } from "Shoothill.Components/Account";
import { TermsAndConditions } from "Shoothill.Components/App/TermsAndConditions";
import { clientDetailsModel } from "../../Models/FormModels/clientDetailsModel";
import { datePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import moment from "moment";
import { string } from "prop-types";
import { useMediaQuery } from "Shoothill.Core/Utils/Media/mediaQuery";
import { useObserver } from "mobx-react-lite";
import validator from "validator";
import { CovidTestAndRelease, ICovidTestAndReleaseHandles } from "../App/CovidTestAndRelease";
import { CovidTestAndReleaseModel } from "../../Models/FormModels/covidTestAndReleaseModel";
import { DeliveryAddress } from "Shoothill.Components/App/DeliveryAddress";
import { IDeliveryAddressHandles } from "../App/DeliveryAddress";
import { runInAction } from "mobx";

interface IProps {
	showPWD: boolean;
	showExtra: boolean;
	saveMsg: string;
	cancelMsg: string;
	onCancel: (onCancel: boolean) => void;
	onSubmit: (submitVal: any) => void;
	showFurtherInfo: boolean;
	isProduct?: boolean;
	disableNameAndAddress?: boolean;
	doExpressCheck?: boolean;
	showPrice?: boolean;
	employerReg?: boolean;
	onlyReg?: boolean;
	hideCancel?: boolean;
}

export function isCovidPCRFullyVac(appointmentType?: Summerfield.Data.appointmentType) {
	return (
		appointmentType &&
		(appointmentType.id === 282 ||
			// For Testing
			(appointmentType.id === 47 &&
				appointmentType.appointmentTypeName === "COVID-19 PCR Test Day 2 (Fully Vacc'd)") ||
			// For CI
			(appointmentType.id === 42 &&
				appointmentType.appointmentTypeName === "COVID-19 PCR Test Day 2 (Fully Vacc'd)"))
	);
}

export function isCovidPCRNotFullyVac(appointmentType?: Summerfield.Data.appointmentType) {
	return (
		appointmentType &&
		(appointmentType.id === 283 ||
			// For Testing
			(appointmentType.id === 48 &&
				appointmentType.appointmentTypeName === "COVID-19 PCR Tests Day 2 and 8 (Not Fully Vacc'd)") ||
			// For CI
			(appointmentType.id === 43 &&
				appointmentType.appointmentTypeName === "COVID-19 PCR Tests Day 2 and 8 (Not Fully Vacc'd)"))
	);
}

export function isTestToRelease(appointmentType?: Summerfield.Data.appointmentType) {
	return (
		appointmentType &&
		(appointmentType.id === 201 ||
			appointmentType.id === 193 ||
			appointmentType.id === 195 ||
			// For Testing
			(appointmentType.id === 37 && appointmentType.appointmentTypeName === "Covid Test to Release") ||
				(appointmentType.id === 38 && appointmentType.appointmentTypeName === "Covid Test to Release App") ||
				(appointmentType.id === 41 &&
					appointmentType.appointmentTypeName === "Test&Release COVID-19 PCR Collection") ||
			// For CI
			(appointmentType.id === 37 &&
				appointmentType.appointmentTypeName === "Covid Test and Release Appointment") ||
				(appointmentType.id === 38 && appointmentType.appointmentTypeName === "Covid Test and Release Product"))
	);
}

const enquiryViewModel = new EnquiryViewModel();

export const MyDetailsForm: React.FC<IProps> = props => {
	const [disabledSoI, setDisabledSoI] = useState(false);
	const storesContext = useContext<Stores>(StoresContext);
	const emailBlur = useRef<boolean>(false);
	const [formState, { password, text, checkbox /* , checkbox, select, password, email */ }] = useValidation(
		{
			...storesContext.domain.EnquiryStore.enquiry.customerDetails,
			terms: false,
			easterDispatch: false,
			/* covidPCR: false, */
			useExpress: false,
			sameDay: false,
			addCert: false,
			translateCert: false,
			travel: undefined as boolean | undefined,
			passport: "",
			certLanguage: "",
			isEmployee: !!props.employerReg,
			travellingRestricted: false,
			antigenTest: false,
			sameDeliveryAddress: true,
			testToReleaseCheckbox: false,
			sameSelfIsolationAddress: true,
			bundleDeliveryAddress: false,
			plnCheckbox: false,
			fvcCheckbox: false,
		},
		{ validateOnChange: true },
	);

	let useMobile: boolean;
	useMobile = useMediaQuery("screen and (max-width: 1023px)") ? true : false;

	const { onSubmit, onCancel } = props;

	const [sourceOfInterestItems, setSourceOfInterestItems] = useState<JSX.Element[]>([]);
	const sourcesOfInterest = useRef<string[]>([]);

	const soIChanged = useRef<boolean>(false);
	const triedSubmit = useRef<boolean>(false);

	const [errorOpen, setErrorOpen] = useState<boolean>(false);
	const errorMessage = useRef<string>("");
	const hasValidatedDOB = useRef<boolean>(false);

	const covidTestAndReleaseRef = useRef<ICovidTestAndReleaseHandles>(null);
	const covidTestAndReleaseRefForMe = useRef<ICovidTestAndReleaseHandles>(null);
	const deliveryAddressRef = useRef<IDeliveryAddressHandles>(null);

	const selfIsolationAddressRef = useRef<IDeliveryAddressHandles>(null);

	const [dateOB, setdateOB] = useState<Date | null>(
		storesContext.domain.EnquiryStore.enquiry.customerDetails.dateOfBirth,
	);
	const [showModal, setShowModal] = useState<boolean>(false);
	const postcodeRef = useRef<HTMLDivElement>(null);
	const [expressDate, setExpressDate] = useState<string | null>("Friday 24th July");

	const doPostcodeRegex = (postcode: string) => {
		return /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/gim.test(postcode);
	};

	const doProductRangeCheck = (e: FocusEvent) => {
		if (props.doExpressCheck && props.isProduct) {
			const value = (e.target as HTMLInputElement).value;
			if (doPostcodeRegex(value)) {
				enquiryViewModel.doProductRangeCheck(value);
			} else {
				storesContext.domain.EnquiryStore.setUseExpress(false);
			}
		}
	};

	const getPrice = (noDiscount: boolean = false) => {
		let price = StoresInstance.domain.EnquiryStore.getPrice(
			formState.formData.useExpress,
			formState.formData.addCert,
			formState.formData.sameDay,
			noDiscount,
		);

		return price.toFixed(price % 1 === 0 ? 0 : 2);
	};

	useEffect(() => {
		storesContext.domain.EnquiryStore.vouchers.reset();
		storesContext.domain.EnquiryStore.setExpressCost(undefined);

		if (props.doExpressCheck && props.isProduct && storesContext.domain.EnquiryStore.enquiry.customerDetails) {
			const postcode = storesContext.domain.EnquiryStore.enquiry.customerDetails.postcode;

			if (postcode && doPostcodeRegex(postcode)) {
				enquiryViewModel.doProductRangeCheck(postcode);
			}
		}

		/* if (props.doExpressCheck && props.isProduct) {
			enquiryViewModel.getNextExpressDate().then(r => setExpressDate(r));
		} */

		if (postcodeRef.current) {
			postcodeRef.current.getElementsByTagName("input")![0].addEventListener("blur", doProductRangeCheck);
		}

		return () => {
			if (postcodeRef.current) {
				postcodeRef.current.getElementsByTagName("input")![0].removeEventListener("blur", doProductRangeCheck);
			}
		};
	}, []);

	// #region Conditionals

	const c_antigen = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("antigen")
		);
	};

	const c_extra = () => {
		return props.showExtra;
	};

	const c_onlyReg = () => {
		return props.onlyReg;
	};

	const c_product = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType.isProduct
		);
	};

	const c_sameDelivery = () => {
		return formState.formData.sameDeliveryAddress;
	};

	const c_delivery = () => {
		return !!deliveryAddressRef.current;
	};

	const c_covidTaR = () => {
		return !!covidTestAndReleaseRef.current;
	};

	const c_covidTaR_ForMe = () => {
		return !!covidTestAndReleaseRefForMe.current;
	};

	const c_covid_or_pcr = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			(storesContext.domain.EnquiryStore.enquiry
				.appointmentType!.appointmentTypeName!.toLowerCase()
				.includes("covid") ||
				storesContext.domain.EnquiryStore.enquiry
					.appointmentType!.appointmentTypeName!.toLowerCase()
					.includes("pcr"))
		);
	};

	const c_release_or_biograd = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			(storesContext.domain.EnquiryStore.enquiry
				.appointmentType!.appointmentTypeName!.toLowerCase()
				.includes("release") ||
				storesContext.domain.EnquiryStore.enquiry
					.appointmentType!.appointmentTypeName!.toLowerCase()
					.includes("biograd"))
		);
	};

	const c_results = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType.hasTestResults
		);
	};

	const c_covidRelease = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry
				.appointmentType!.appointmentTypeName!.toLowerCase()
				.includes("covid") &&
			storesContext.domain.EnquiryStore.enquiry
				.appointmentType!.appointmentTypeName!.toLowerCase()
				.includes("release")
		);
	};

	const c_biograd = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry
				.appointmentType!.appointmentTypeName!.toLowerCase()
				.includes("biograd")
		);
	};

	const c_release = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("release")
		);
	};

	const c_travelRestrict = () => {
		return formState.formData.travellingRestricted;
	};

	const c_expressCost = () => {
		return storesContext.domain.EnquiryStore.expressCost !== undefined;
	};

	const c_expressDate = () => {
		return expressDate !== null;
	};

	const c_sameDay = () => {
		return storesContext.domain.EnquiryStore.sameDayAvailable;
	};

	const c_cert = () => {
		return storesContext.domain.EnquiryStore.hasCert;
	};

	const c_146_or_147 = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			(storesContext.domain.EnquiryStore.enquiry.appointmentType!.id === 146 ||
				storesContext.domain.EnquiryStore.enquiry.appointmentType!.id === 147)
		);
	};

	const c_covid = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType.appointmentTypeName!.includes("COVID")
		);
	};

	const c_pwd = () => {
		return props.showPWD;
	};

	const c_further = () => {
		return props.showFurtherInfo;
	};

	const c_drive = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("drive")
		);
	};

	const c_employer = () => {
		return props.employerReg;
	};

	const c_price = () => {
		return props.showPrice;
	};

	const c_voucher = () => {
		return storesContext.domain.EnquiryStore.allowVoucher;
	};

	const c_covidBundle = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("day 2") &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("8") &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("covid") &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("pcr") &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("bundle")
		);
	};

	const c_covidGreenList = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("day 2") &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("covid") &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("pcr") &&
			!c_covidBundle()
		);
	};

	const c_covidAmberList = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("day 2") &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("covid") &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType
				.appointmentTypeName!.toLowerCase()
				.includes("amber")
		);
	};

	const c_covidPCRFullyVacc = () => {
		return isCovidPCRFullyVac(storesContext.domain.EnquiryStore.enquiry.appointmentType);
	};

	const c_covidPCRNotFullVacc = () => {
		return isCovidPCRNotFullyVac(storesContext.domain.EnquiryStore.enquiry.appointmentType);
	};

	const c_selfIsolation = () => {
		return !!selfIsolationAddressRef.current;
	};

	const c_sameSelfIsolation = () => {
		return formState.formData.sameSelfIsolationAddress;
	};

	const is_id = (id: number) => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType!.id === id
		);
	};

	const isGPAppointment = () => {
		return (
			storesContext.domain.EnquiryStore.enquiry.appointmentType &&
			storesContext.domain.EnquiryStore.enquiry.appointmentType.appointmentTypeName!.includes(
				"Minute GP Appointment",
			)
		);
	};

	// #endregion Conditionals

	// #region Conditional Logic

	const showSameDayDriveThroughOption = () => {
		return c_sameDay() && !c_travelRestrict();
	};

	const showExpressOption = () => {
		return c_expressCost() && c_expressDate() && !c_travelRestrict();
	};

	const showTravelPurposes = () => {
		return (c_results() || c_biograd()) && c_extra() && !c_onlyReg();
	};

	const covidBundleOrGreenListOrAmberList = () => {
		return c_covidBundle() || c_covidGreenList() || c_covidAmberList();
	};

	const showTravelPurposesPart1 = () => {
		return (
			(covidBundleOrGreenListOrAmberList() ||
				c_covidPCRFullyVacc() ||
				c_covidPCRNotFullVacc() ||
				c_release_or_biograd()) &&
			c_covid_or_pcr()
		);
	};

	const showTravelPurposesCheckbox = () => {
		return c_results() && c_covid_or_pcr();
	};

	const showCertOption = () => {
		return c_cert() && !c_travelRestrict();
	};

	const showTextToReleaseCheckbox = () => {
		return c_release();
	};

	const showEasterDispatchBox = () => {
		return c_product() && !c_travelRestrict();
	};

	const showCovidPCR = () => {
		return c_146_or_147() && !c_travelRestrict();
	};

	const showCovidTOS = () => {
		return c_product() && c_covid();
	};

	const showAntigenTOS = () => {
		return c_product() && c_antigen();
	};

	const showType2TOS = () => {
		return is_id(69) || is_id(222) || is_id(234) || is_id(71) || is_id(195);
	};

	const showDeliveryCheckbox = () => {
		return (
			c_extra() &&
			(c_antigen() || covidBundleOrGreenListOrAmberList() || c_covidPCRFullyVacc() || c_covidPCRNotFullVacc()) &&
			c_product()
		);
	};

	const showSelfIsolationAddress = () => {
		return c_extra() && (covidBundleOrGreenListOrAmberList() || c_covidPCRFullyVacc() || c_covidPCRNotFullVacc());
	};

	const showForMeQuestion = () => {
		return !showDeliveryCheckbox() && !showSelfIsolationAddress();
	};

	const showForMeQuestionAbove = () => {
		return showDeliveryCheckbox() || showSelfIsolationAddress();
	};

	const showLoggedInOptions = () => {
		return !c_extra() || !c_pwd();
	};

	const showFurtherInfoQuestion = () => {
		return c_further() && !c_travelRestrict() && c_extra();
	};

	const showSignUpCheckboxes = () => {
		return !c_travelRestrict();
	};

	const showSignUpAntigenCheckboxes = () => {
		return c_antigen();
	};

	const showEmployerCheckbox = () => {
		return c_employer() && !c_travelRestrict();
	};

	const showLoggedInTOS = () => {
		return !c_pwd() && !c_travelRestrict() && c_extra();
	};

	const showAntigenCheckbox = () => {
		return c_antigen();
	};

	const showVoucher = () => {
		return c_price() && c_voucher() && !c_travelRestrict();
	};

	const showPrice = () => {
		return c_price() && !c_travelRestrict();
	};

	const hideInformationForTestAndRelease = () => {
		return covidBundleOrGreenListOrAmberList() || c_covidPCRFullyVacc() || c_covidPCRNotFullVacc();
	};

	const validateSelfIsolationAddress = () => {
		return c_extra() && c_selfIsolation() && covidBundleOrGreenListOrAmberList && !c_sameSelfIsolation();
	};

	const validateDeliveryAddress = () => {
		return showDeliveryCheckbox() && !c_sameDelivery() && c_delivery();
	};

	const validateTravel = () => {
		return (
			c_covid_or_pcr() &&
			c_results() &&
			!c_covidRelease() &&
			!c_biograd() &&
			!(covidBundleOrGreenListOrAmberList() || c_covidPCRFullyVacc() || c_covidPCRNotFullVacc())
		);
	};

	const showCovidBundleCheckboxes = () => {
		return covidBundleOrGreenListOrAmberList() || c_covidPCRFullyVacc() || c_covidPCRNotFullVacc();
	};

	const showIsFullyVaccinated = () => {
		return c_covidAmberList() || c_covidPCRFullyVacc();
	};

	const showFullyVaccinatedSelectOption = () => {
		return !c_covidPCRNotFullVacc();
	};

	// #endregion Conditional Logic

	const doSubmit = async (e: any) => {
		e.preventDefault();
		triedSubmit.current = true;
		hasValidatedDOB.current = true;
		let isTravelValid = true;
		let covidTaR:
			| {
					covid: CovidTestAndReleaseModel;
					patient: Summerfield.Data.customer;
			  }
			| undefined = undefined;
		let covidTaRForMe:
			| {
					covid: CovidTestAndReleaseModel;
					patient: Summerfield.Data.customer;
			  }
			| undefined = undefined;
		let deliveryAddressValid: Summerfield.Data.address | undefined = undefined;
		let selfIsolationAddressValid: Summerfield.Data.address | undefined = undefined;
		if (validateDeliveryAddress()) {
			deliveryAddressValid = deliveryAddressRef.current!.validate();
		}
		if (validateSelfIsolationAddress()) {
			selfIsolationAddressValid = selfIsolationAddressRef.current!.validate();
		}
		if (c_covidTaR()) {
			covidTaR = covidTestAndReleaseRef.current!.validate();
		}
		if (c_covidTaR_ForMe()) {
			covidTaRForMe = covidTestAndReleaseRefForMe.current!.validate();
		}
		if (validateTravel()) {
			isTravelValid = doTravelValidation(formState.formData.travel !== undefined);
		}

		console.log(
			formState.isFormValid(),
			storesContext.domain.EnquiryStore.enquiry.customerDetails.sourceOfInterest || !props.showPWD,
			isValidDate(),
			isTravelValid,
			covidTaR !== undefined || !c_covidTaR(),
			covidTaRForMe !== undefined || !c_covidTaR_ForMe(),
			deliveryAddressValid !== undefined || !validateDeliveryAddress(),
		);
		console.log(formState.validity);
		if (
			formState.isFormValid() &&
			(storesContext.domain.EnquiryStore.enquiry.customerDetails.sourceOfInterest || !props.showPWD) &&
			isValidDate() &&
			isTravelValid &&
			(covidTaR !== undefined || !c_covidTaR()) &&
			(covidTaRForMe !== undefined || !c_covidTaR_ForMe()) &&
			(deliveryAddressValid !== undefined || !validateDeliveryAddress()) &&
			(selfIsolationAddressValid !== undefined || !validateSelfIsolationAddress())
		) {
			console.log(dateOB, dateOB!.toISOString());
			console.log(moment(dateOB!).toISOString(true));
			console.log(moment);
			storesContext.domain.EnquiryStore.enquiry.customerDetails.fromDto({
				...Models.Domain.Customer.toDto((formState.formData as unknown) as Models.Domain.Customer),
				dateOfBirth: new Date(
					`${
						moment(dateOB!)
							.startOf("day")
							.toISOString(true)
							.split("+")[0]
					}Z`,
				),
			});

			if (covidTaR === undefined && covidTaRForMe !== undefined) {
				covidTaR = covidTaRForMe;
			} else if (covidTaR !== undefined && covidTaRForMe !== undefined) {
				covidTaR.covid.forMe = covidTaRForMe.covid.forMe;
				covidTaR.patient = covidTaRForMe.patient;
			}

			if (covidTaR !== undefined && !covidTaR.covid.forMe) {
				covidTaR.patient.dateOfBirth = new Date(
					`${
						moment(covidTaR.patient.dateOfBirth)
							.startOf("day")
							.toISOString(true)
							.split("+")[0]
					}Z`,
				);
				storesContext.domain.EnquiryStore.enquiry.patientDetails.fromDto(covidTaR.patient);
			}

			if (props.showPWD && props.showExtra && !props.onlyReg) {
				// Register:
				if (formState.formData.travel) {
					storesContext.domain.EnquiryStore.setPassport(formState.formData.passport);
				} else {
					storesContext.domain.EnquiryStore.setPassport();
				}
				storesContext.domain.EnquiryStore.setAddCert(
					formState.formData.addCert && storesContext.domain.EnquiryStore.hasCert,
				);
				storesContext.domain.EnquiryStore.setCertLang(
					storesContext.domain.EnquiryStore.hasCert && formState.formData.translateCert
						? formState.formData.certLanguage
						: "",
				);
				storesContext.domain.EnquiryStore.setSameDay(
					storesContext.domain.EnquiryStore.sameDayAvailable && formState.formData.sameDay,
				);
				if (props.isProduct) {
					storesContext.domain.EnquiryStore.setSeperateDeliveryAddress(
						!!(showDeliveryCheckbox() && !formState.formData.sameDeliveryAddress),
					);
					if (deliveryAddressValid !== undefined) {
						runInAction(() => {
							storesContext.domain.EnquiryStore.enquiry.deliveryAddress = deliveryAddressValid!;
						});
					}
					storesContext.domain.EnquiryStore.setSeperateSelfIsolationAddress(
						validateSelfIsolationAddress() && !formState.formData.sameSelfIsolationAddress,
					);
					if (selfIsolationAddressValid !== undefined) {
						runInAction(() => {
							storesContext.domain.EnquiryStore.enquiry.selfIsolationAddress = selfIsolationAddressValid!;
						});
					}
					const userCheckError = await enquiryViewModel.userCheck(formState.formData.email);
					if (userCheckError) {
						errorMessage.current = userCheckError;
						setErrorOpen(true);
					} else {
						const body = storesContext.domain.EnquiryStore.enquiry.toDetailsDto(StoresInstance.domain);
						const customerDetails = {
							...body,
							registration: {
								password: formState.formData.password,
							},
							customerDetails: {
								...body.customerDetails,
								emailConsentAndSoI: {
									...body.customerDetails.emailConsentAndSoI,
									sourceOfInterest: formState.formData.sourceOfInterest,
								},
							},
							covidTaR,
						};
						storesContext.domain.EnquiryStore.setUseExpress(
							formState.formData.useExpress &&
								storesContext.domain.EnquiryStore.expressCost !== undefined,
						);

						onSubmit && onSubmit(customerDetails);
					}
				} else {
					enquiryViewModel.upsertDetailsFromRegister(formState.formData).then(errorMsg => {
						if (errorMsg) {
							errorMessage.current = errorMsg;
							setErrorOpen(true);
						} else {
							onSubmit && onSubmit(covidTaR);
						}
					});
				}
			} else if (!props.showPWD && props.showExtra) {
				// Login
				if (formState.formData.travel) {
					storesContext.domain.EnquiryStore.setPassport(formState.formData.passport);
				} else {
					storesContext.domain.EnquiryStore.setPassport();
				}
				storesContext.domain.EnquiryStore.setAddCert(
					formState.formData.addCert && storesContext.domain.EnquiryStore.hasCert,
				);
				storesContext.domain.EnquiryStore.setCertLang(
					storesContext.domain.EnquiryStore.hasCert && formState.formData.translateCert
						? formState.formData.certLanguage
						: "",
				);
				storesContext.domain.EnquiryStore.setSameDay(
					storesContext.domain.EnquiryStore.sameDayAvailable && formState.formData.sameDay,
				);
				if (props.isProduct) {
					storesContext.domain.EnquiryStore.setSeperateDeliveryAddress(
						!!(showDeliveryCheckbox() && !formState.formData.sameDeliveryAddress),
					);
					if (deliveryAddressValid !== undefined) {
						runInAction(() => {
							storesContext.domain.EnquiryStore.enquiry.deliveryAddress = deliveryAddressValid!;
						});
					}
					storesContext.domain.EnquiryStore.setSeperateSelfIsolationAddress(
						validateSelfIsolationAddress() && !formState.formData.sameSelfIsolationAddress,
					);
					if (selfIsolationAddressValid !== undefined) {
						runInAction(() => {
							storesContext.domain.EnquiryStore.enquiry.selfIsolationAddress = selfIsolationAddressValid!;
						});
					}
					storesContext.domain.EnquiryStore.setUseExpress(
						formState.formData.useExpress && storesContext.domain.EnquiryStore.expressCost !== undefined,
					);
					const detailsDto = {
						...storesContext.domain.EnquiryStore.enquiry.toDetailsDto(StoresInstance.domain),
						covidTaR,
					};
					onSubmit && onSubmit(detailsDto);
				} else {
					enquiryViewModel.upsertDetails().then(() => {
						onSubmit && onSubmit(covidTaR);
					});
				}
			} else {
				if (props.onlyReg) {
					enquiryViewModel.employeeReg(formState.formData).then(errorMsg => {
						if (errorMsg) {
							errorMessage.current = errorMsg;
							setErrorOpen(true);
						} else {
							onSubmit && onSubmit(true);
						}
					});
				} else {
					// Change Details
					enquiryViewModel.updateDetails().then(response => {
						if (response && response.wasSuccessful) {
							onSubmit && onSubmit(true);
						} else if (response && response.errors && response.errors[0] && response.errors[0].message) {
							errorMessage.current = response.errors[0].message;
							setErrorOpen(true);
						} else {
							errorMessage.current = "An unknown error occured";
							setErrorOpen(true);
						}
					});
				}
			}
		}
	};

	function handleDOBChange(date: Date | null): void {
		setdateOB(date);
	}

	function isValidDate() {
		if (dateOB !== null) {
			if (!isNaN(dateOB.getTime())) {
				return true;
			}
		}
		return false;
	}

	const doCancel = (): void => {
		onCancel(false);
	};

	const toggleThis = (toggle: boolean) => {
		let retVal: boolean = false;
		if (!toggle) {
			retVal = true;
		}
		return retVal;
	};

	useEffect(() => {
		if (props.showExtra) {
			let promise = storesContext.domain.SourceOfInterestStore.GetSourceOfInterestList().then(result => {
				sourcesOfInterest.current = result.map((r: SourceOfInterestModel) => r.name);
				if (result !== undefined && result !== null && result.length > 0) {
					setSourceOfInterestItems(
						result
							.filter(
								r =>
									!r.restricted ||
									(storesContext.domain.EnquiryStore.enquiry.appointmentType &&
										storesContext.domain.EnquiryStore.enquiry.appointmentType.id === 173) ||
									(storesContext.domain.EnquiryStore.enquiry.appointmentType &&
										storesContext.domain.EnquiryStore.enquiry.appointmentType.id === 174),
							)
							.map((item: SourceOfInterestModel, index: number) => {
								return (
									<option value={item.name} key={"SoI_" + index}>
										{item.name}
									</option>
								);
							}),
					);

					if (
						(storesContext.domain.EnquiryStore.enquiry.appointmentType &&
							storesContext.domain.EnquiryStore.enquiry.appointmentType.id === 173) ||
						(storesContext.domain.EnquiryStore.enquiry.appointmentType &&
							storesContext.domain.EnquiryStore.enquiry.appointmentType.id === 174)
					) {
						storesContext.domain.EnquiryStore.enquiry.customerDetails.setSoI("Biograd");
						formState.setValue("sourceOfInterest", "");
						formState.remount();
						soIChanged.current = true;
						setDisabledSoI(true);
					}
				} else {
					setSourceOfInterestItems([]);
				}
			});
		}
	}, []);

	const openModal = (): void => {
		setShowModal(true);
	};

	const closeModal = (): void => {
		setShowModal(false);
	};

	const doTravelValidation = (checked: boolean) => {
		formState.setValidityState({ travel: checked });
		formState.setErrorsState({ travel: checked ? [] : ["You must choose an answer"] });
		return checked;
	};

	const doTravelRestrictedValidation = (checked: boolean) => {
		formState.setValidityState({ travellingRestricted: checked });
		formState.setErrorsState({ travellingRestricted: checked ? [] : ["You must choose an answer"] });
		return checked;
	};

	const setTravel = (e: React.ChangeEvent<HTMLInputElement>, checkedValue: boolean) => {
		formState.setValue("travel", e.target.checked ? checkedValue : (undefined as any));
		doTravelValidation(e.target.checked);
	};

	const setTravelRestricted = (e: React.ChangeEvent<HTMLInputElement>, checkedValue: boolean) => {
		formState.setValue("travellingRestricted", e.target.checked ? checkedValue : (undefined as any));
		doTravelRestrictedValidation(e.target.checked);
	};

	const sameDayDriveThroughOption = () =>
		useObserver(() => (
			<>
				{showSameDayDriveThroughOption() && (
					<>
						<GridSpaced
							container
							spacing={2}
							additionalcss={useMobile ? "" : "margin-bottom: 35px;"}
							className={"formRow"}
						>
							<Grid item xs={12} sm={12} md={12}>
								<FormControlLabel
									control={<Checkbox color="primary" {...checkbox("sameDay")} />}
									label={`Do you want your test kit fast tracked to the lab the same day by our courier? (+£49)`}
								/>
								<p style={{ fontSize: "13px", fontStyle: "italic", margin: 0 }}>
									If you do not choose the courier option you will be solely responsible for postage
									of your kit.
								</p>
							</Grid>
						</GridSpaced>
					</>
				)}
			</>
		));

	const expressOption = () =>
		useObserver(() => (
			<>
				{showExpressOption() && (
					<>
						<GridSpaced
							container
							spacing={2}
							additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
							className={"formRow"}
						>
							<Grid item xs={12} sm={12} md={12}>
								<FormControlLabel
									control={<Checkbox color="primary" {...checkbox("useExpress")} />}
									label={`You are eligible for our Express Courier delivery service, the next available date will be ${expressDate}, tick this box for more information (+£${
										storesContext.domain.EnquiryStore.expressCost! % 1 === 0
											? storesContext.domain.EnquiryStore.expressCost
											: storesContext.domain.EnquiryStore.expressCost!.toFixed(2)
									})`}
								/>
								{formState.formData.useExpress && (
									<>
										<p style={{ fontSize: "13px", fontStyle: "italic", margin: 0 }}>
											By adding our express courier service to your order, a medical courier will
											deliver your kit on {expressDate}, and wait for you to undertake the swab.
											<br />
											Your kit will be returned to the laboratory the same day and you will
											receive a result within 24-48 hours.
											<br />
											Adding this service will shorten the waiting time for result caused by
											postal times to you and back to the laboratory.
											<br />
											You must be contactable on this day in order for us to give you prior
											warning of delivery and ensure you do not eat or drink for 1 hour before the
											sample being taken.
										</p>
									</>
								)}
							</Grid>
						</GridSpaced>
					</>
				)}
			</>
		));

	const travelPurposeCheckbox = () =>
		useObserver(() => (
			<>
				<Grid item xs={12} sm={12} md={12}>
					<Typography variant="body1">Is this test for travel purposes?</Typography>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={formState.formData.travel === true}
								onChange={e => setTravel(e, true)}
							/>
						}
						label={`Yes`}
					/>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={formState.formData.travel === false} // could be undefined
								onChange={e => setTravel(e, false)}
							/>
						}
						label={`No`}
					/>
					{formState.showErrors("travel")}

					{formState.formData.travel && (
						<>
							<GridSpaced
								container
								spacing={2}
								additionalcss={
									useMobile ? "margin-top: 25px;" : "margin-top: 25px; margin-bottom: 25px;"
								}
								className={"formRow"}
							>
								<Grid item xs={12} sm={12} md={6}>
									<InputLabel>Please enter your passport number</InputLabel>
									<TextField
										placeholder={""}
										fullWidth
										autoComplete="passport"
										{...text("passport")}
									/>
								</Grid>
								<GridSpaced
									item
									xs={12}
									sm={12}
									md={6}
									additionalcss={"font-size: 13px;"}
									className={"PwdInfo"}
								>
									<p>
										An increasing number of countries require your passport number on your COVID-19
										test result, we strongly advise you to provide this.
									</p>
								</GridSpaced>
							</GridSpaced>

							<GridSpaced
								container
								spacing={2}
								additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
								className={"formRow"}
							>
								<Grid item xs={12} sm={12} md={12}>
									<Typography variant="body1">
										Are you travelling to either Barbados, China or Hong Kong?
									</Typography>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												checked={formState.formData.travellingRestricted === true}
												onChange={e => setTravelRestricted(e, true)}
											/>
										}
										label={`Yes`}
									/>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												checked={formState.formData.travellingRestricted === false} // could be undefined
												onChange={e => setTravelRestricted(e, false)}
											/>
										}
										label={`No`}
									/>
									{formState.showErrors("travellingRestricted")}
								</Grid>
							</GridSpaced>

							{formState.formData.travellingRestricted && (
								<div className="coronavirus-note">
									<h4>Please Note:</h4>
									<p>
										There are more stringent requirements for these countries, please call 0333 9000
										010 for more information.
									</p>
								</div>
							)}
						</>
					)}
					<div style={{ marginBottom: "25px" }} />
				</Grid>
				<div className="hr product-hr" />
			</>
		));

	const travelPurposes = () =>
		useObserver(() => (
			<>
				{showTravelPurposes() &&
					(showTravelPurposesPart1() ? (
						<CovidTestAndRelease
							ref={covidTestAndReleaseRef}
							hideInformationForTestAndRelease={hideInformationForTestAndRelease()}
							forMeQuestion={showForMeQuestion()}
							isFullyVaccinated={showIsFullyVaccinated()}
							showFullyVaccinatedSelectOption={showFullyVaccinatedSelectOption()}
						/>
					) : (
						<>
							{showForMeQuestion() && (
								<CovidTestAndRelease
									ref={covidTestAndReleaseRefForMe}
									forMeQuestion
									onlyForMeQuestion
									isFullyVaccinated={showIsFullyVaccinated()}
									showFullyVaccinatedSelectOption={showFullyVaccinatedSelectOption()}
								/>
							)}
							{showTravelPurposesCheckbox() && travelPurposeCheckbox()}
						</>
					))}
			</>
		));

	const certOption = () =>
		useObserver(() => (
			<>
				{showCertOption() && (
					<>
						<GridSpaced
							container
							spacing={2}
							additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
							className={"formRow"}
						>
							<Grid item xs={12} sm={12} md={12}>
								<FormControlLabel
									control={<Checkbox color="primary" {...checkbox("addCert")} />}
									label={`Add Medical Certificate to order (+£25)`}
								/>
								{/* {formState.formData.addCert && (
									<>
										<br />
										<div style={{ marginBottom: "25px" }} />
										<FormControlLabel
											control={<Checkbox color="primary" {...checkbox("translateCert")} />}
											label={`I require my certificate translated to another language`}
										/>
										{formState.formData.translateCert && (
											<>
												<TextField
													placeholder="Language"
													fullWidth
													{...text("certLanguage", {
														validationRules: Validations.baseValidation,
													})}
												/>
												<p style={{ fontSize: "13px", fontStyle: "italic", margin: 0 }}>
													All certificate translations will be made within the limits of
													translation software
												</p>
											</>
										)}
									</>
								)} */}
							</Grid>
						</GridSpaced>
					</>
				)}
			</>
		));

	const fullyVaccinatedConfirmationCheckbox = () =>
		showIsFullyVaccinated() && (
			<GridSpaced
				container
				spacing={2}
				additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
				className={"formRow"}
			>
				<Grid item xs={12} sm={12} md={12}>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								{...checkbox("fvcCheckbox", {
									validationRules: [(v: boolean) => v || "You must tick this box"],
								})}
							/>
						}
						label={<>I have been fully vaccinated under a vaccination programme approved by gov.uk.</>}
					/>
					{formState.showErrors("fvcCheckbox")}
				</Grid>
			</GridSpaced>
		);

	const covidBundleCheckboxes = () =>
		showCovidBundleCheckboxes() && (
			<>
				<GridSpaced
					container
					spacing={2}
					additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
					className={"formRow"}
				>
					<Grid item xs={12} sm={12} md={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									{...checkbox("plnCheckbox", {
										validationRules: [
											(v: boolean) =>
												v ||
												!(
													covidBundleOrGreenListOrAmberList() ||
													c_covidPCRFullyVacc() ||
													c_covidPCRNotFullVacc()
												) ||
												"You must tick this box",
										],
									})}
								/>
							}
							label={
								<>
									I understand that Passenger Locator Numbers can only be provided between 8am – 6pm
									GMT on Monday – Friday, <b>and between 10am-12pm on Saturday.</b> Orders placed
									outside of these hours will have their Passenger Locator Numbers generated and
									forwarded on the next working day.
								</>
							}
						/>
						{formState.showErrors("plnCheckbox")}
					</Grid>
				</GridSpaced>

				<GridSpaced
					container
					spacing={2}
					additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
					className={"formRow"}
				>
					<Grid item xs={12} sm={12} md={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									{...checkbox("bundleDeliveryAddress", {
										validationRules: [
											(v: boolean) =>
												v ||
												!(
													covidBundleOrGreenListOrAmberList() ||
													c_covidPCRFullyVacc() ||
													c_covidPCRNotFullVacc()
												) ||
												"You must tick this box",
										],
									})}
								/>
							}
							label="I understand that it is my responsibility to ensure my delivery address will be capable of receiving these testing kits by Special Delivery post, which will be dispatched before 3pm Monday – Friday. Orders placed after 3pm will only be dispatched the next working day."
						/>
						{formState.showErrors("bundleDeliveryAddress")}
					</Grid>
				</GridSpaced>
			</>
		);

	const testToReleaseCheckbox = () =>
		showTextToReleaseCheckbox() && (
			<GridSpaced
				container
				spacing={2}
				additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
				className={"formRow"}
			>
				<Grid item xs={12} sm={12} md={12}>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								{...checkbox("testToReleaseCheckbox", {
									validationRules: [(v: boolean) => v || !c_release() || "You must tick this box"],
								})}
							/>
						}
						label="I understand and accept that I must not undertake a COVID PCR test for ‘Test to release’ purposes until at least day 5 of my return to the UK."
					/>
					{formState.showErrors("testToReleaseCheckbox")}
				</Grid>
			</GridSpaced>
		);

	const easterDispatchBox = () =>
		showEasterDispatchBox() && (
			<>
				<GridSpaced
					container
					spacing={2}
					additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
					className={"formRow"}
				>
					<Grid item xs={12} sm={12} md={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									{...checkbox("easterDispatch", {
										validationRules: [
											(v: boolean) =>
												v ||
												!storesContext.domain.EnquiryStore.isProduct ||
												"You must tick this box",
										],
									})}
								/>
							}
							label={
								<span>
									I understand that orders placed after 3pm Monday – Friday will not be dispatched
									until the following day. Orders placed after 9.45am on Saturday or on Sunday will
									not be dispatched until Monday. I also understand that Summerfield Healthcare cannot
									be held responsible for any delays caused by Royal Mail.
								</span>
							}
						/>
						{formState.showErrors("easterDispatch")}
					</Grid>
				</GridSpaced>
			</>
		);

	const covidPCR = () =>
		showCovidPCR() && (
			<GridSpaced
				container
				spacing={2}
				additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
				className={"formRow"}
			>
				<Grid item xs={12} sm={12} md={12}>
					<p style={{ fontSize: "13px", margin: 0 }}>
						Please ensure that you understand our terms and conditions which you unequivocally accepted by
						booking with us.
						<br />
						You are purchasing a self-collection Covid 19 kit and you are responsible for posting this to
						the laboratory in the prepaid Royal Mail 24 hour tracked envelope provided. You MUST note your
						tracking number on the envelope if you wish to track your delivery.
						<br />
						Although the Royal Mail endeavour to deliver mail within the specified times, Summerfield
						Healthcare are not responsible for postage delays. Latest mail updates can be found here:{" "}
						<a
							href="https://personal.help.royalmail.com/app/answers/detail/a_id/12556/~/service-update"
							target="_blank"
						>
							https://personal.help.royalmail.com/app/answers/detail/a_id/12556/~/service-update
						</a>
						<br />
						Summerfield Healthcare are not responsible for content on external websites.
						<br />
						PLEASE NOTE THE ROYAL MAIL TO DO NOT DELIVER ON SUNDAYS AND THE LABORATORY DOES NOT OPERATE ON A
						SUNDAY.
					</p>

					{/* <FormControlLabel
						control={
							<Checkbox
								color="primary"
								{...checkbox("covidPCR", {
									validationRules: [
										(v: boolean) =>
											v ||
											!(storesContext.domain.EnquiryStore.enquiry.appointmentType &&
												(storesContext.domain.EnquiryStore.enquiry.appointmentType!.id === 146 ||
													storesContext.domain.EnquiryStore.enquiry.appointmentType!.id === 147)) ||
											"You must tick this box",
									],
								})}
							/>
						}
						label="I understand ‘yes’"
					/>
					{formState.showErrors("covidPCR")} */}
				</Grid>
			</GridSpaced>
		);

	const tncModal = (
		<>
			<TCModal
				open={showModal}
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<TCPaper>
					<div className="customer-mydetails">
						<div className="customer-paymentheader">
							<GridSpaced container>
								<GridSpaced item padding={"0"} marin={"0"} xs={10}>
									Terms and Conditions
								</GridSpaced>
								<GridSpaced item padding={"0"} marin={"0"} xs={2} additionalcss={"text-align: right;"}>
									<Button onClick={closeModal}>
										<HighlightOffIcon />
									</Button>
								</GridSpaced>
							</GridSpaced>
						</div>
						<TermsAndConditions covid={showCovidTOS()} antigen={showAntigenTOS()} type2={showType2TOS()} />
					</div>
				</TCPaper>
			</TCModal>
		</>
	);

	return useObserver(() => (
		<>
			<Snackbar
				open={errorOpen}
				message={errorMessage.current}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				onClose={() => setErrorOpen(false)}
				autoHideDuration={6000}
			/>
			<div className="mydetails-form">
				<form onSubmit={doSubmit} id="detailsPanelForm" className={"myDetailsForm"}>
					<GridSpaced
						container
						spacing={2}
						additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
						className={"formRow"}
					>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>First name*</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="firstName"
								{...text("firstName", {
									validationRules: Validations.baseValidation,
								})}
								disabled={props.disableNameAndAddress}
							/>
							{formState.showErrors("firstName")}
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>Last name*</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="lastName"
								{...text("lastName", {
									validationRules: Validations.baseValidation,
								})}
								disabled={props.disableNameAndAddress}
							/>
							{formState.showErrors("lastName")}
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>Date of birth*</InputLabel>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Grid item className={"adminClasses.datePickerGridContainer"}>
									<Box textAlign="left" className={"adminClasses.datePickerBox"}>
										<KeyboardDatePicker
											className={"datePicker"}
											fullWidth
											margin="normal"
											id="date-of-birth-picker-dialog"
											format="dd/MM/yyyy"
											value={dateOB}
											minDate={new Date("1910-01-01")}
											onChange={handleDOBChange}
											inputProps={{
												onBlur: () => {
													hasValidatedDOB.current = true;
												},
											}}
											KeyboardButtonProps={{
												"aria-label": "change date",
											}}
											disabled={props.disableNameAndAddress}
										/>
										{hasValidatedDOB.current && dateOB === null && (
											<>
												<div className="error">This value is required</div>
											</>
										)}
									</Box>
								</Grid>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} sm={12} md={3}></Grid>
					</GridSpaced>

					<GridSpaced
						container
						spacing={2}
						additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
						className={"formRow"}
					>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>Contact no.(mobile)*</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="mobileNumber"
								{...text("mobileNumber", {
									validationRules: Validations.baseValidation,
								})}
							/>
							{formState.showErrors("mobileNumber")}
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>Contact no.(home):</InputLabel>
							<TextField placeholder={""} fullWidth autoComplete="number" {...text("number", {})} />
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<InputLabel>Email address*</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="emailAddress"
								{...text("email", {
									validationRules: [
										...Validations.baseValidation,
										(v: string) =>
											!emailBlur.current || validator.isEmail(v) || "This value must be an email",
									],
								})}
								onBlur={e => {
									if (e.target.value) {
										emailBlur.current = true;
										formState.validate("email");
									}
								}}
							/>
							{formState.showErrors("email")}
						</Grid>
					</GridSpaced>

					<GridSpaced
						container
						spacing={2}
						additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
						className={"formRow"}
					>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>Company name</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="organization"
								{...text("companyName")}
							/>
							{formState.showErrors("companyName")}
						</Grid>
						<Grid item xs={12} sm={12} md={9}></Grid>
					</GridSpaced>

					{props.showPWD && (
						<>
							<div className="hr product-hr" />
							<GridSpaced
								container
								spacing={2}
								additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
								className={"formRow"}
							>
								<Grid item xs={12} sm={12} md={3}>
									<InputLabel>Password*</InputLabel>
									<TextField
										placeholder="Password"
										fullWidth
										id="input-password"
										{...password("password", {
											validationRules: [
												...Validations.baseValidation,
												...[
													(v: string) =>
														Validations.isMediumPasswordStrength.test(v) ||
														"Password is too weak",
												],
											],
										})}
									/>
									{formState.showErrors("password", "error")}
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<InputLabel>Confirm Password*</InputLabel>
									<TextField
										placeholder="Confirm Password"
										fullWidth
										id="input-password"
										{...password("confirmPassword", {
											validationRules: [
												...Validations.baseValidation,
												...[
													(v: string, values: RegisterUserModel) =>
														(v && v.length > 0 && v === values.password) ||
														"Passwords must match",
												],
											],
										})}
										onKeyPress={() => formState.validate("confirmPassword")}
									/>
									{formState.showErrors("confirmPassword", "error")}
								</Grid>
								<GridSpaced
									item
									xs={12}
									sm={12}
									md={6}
									additionalcss={"font-size: 13px;"}
									className={"PwdInfo"}
								>
									<p>
										Password must contain a minimum of 8 characters combining alphanumeric, UPPER
										and lower case letters and at least one symbol (!"£$%^).
									</p>
								</GridSpaced>
							</GridSpaced>
							<div className="hr product-hr" />
						</>
					)}

					<GridSpaced
						container
						spacing={2}
						additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
						className={"formRow"}
					>
						<Grid item xs={12} sm={12} md={6}>
							<InputLabel>Address line 1*</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="addressLine1"
								{...text("addressLine1", {
									validationRules: Validations.baseValidation,
								})}
								disabled={props.disableNameAndAddress}
							/>
							{formState.showErrors("addressLine1")}
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<InputLabel>Address line 2</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="addressLine2"
								{...text("addressLine2", {})}
								disabled={props.disableNameAndAddress}
							/>
							{formState.showErrors("addressLine2")}
						</Grid>
					</GridSpaced>

					<GridSpaced
						container
						spacing={2}
						additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
						className={"formRow"}
					>
						<Grid item xs={12} sm={12} md={6}>
							<InputLabel>Address line 3</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="addressLine3"
								{...text("addressLine3", {})}
								disabled={props.disableNameAndAddress}
							/>
							{formState.showErrors("addressLine3")}
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>Town/City*</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="townOrCity"
								{...text("townOrCity", {
									validationRules: Validations.baseValidation,
								})}
								disabled={props.disableNameAndAddress}
							/>
							{formState.showErrors("townOrCity")}
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>County</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="county"
								disabled={props.disableNameAndAddress}
							/>
						</Grid>
					</GridSpaced>

					<GridSpaced
						container
						spacing={2}
						additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
						className={"formRow"}
					>
						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>Postcode*</InputLabel>
							<TextField
								placeholder={""}
								fullWidth
								autoComplete="postcode"
								{...text("postcode", {
									validationRules: Validations.baseValidation,
								})}
								disabled={props.disableNameAndAddress}
								ref={postcodeRef}
							/>
							{formState.showErrors("postcode")}
						</Grid>
						<Grid item xs={12} sm={12} md={3}></Grid>
					</GridSpaced>

					{(showLoggedInOptions() || (props.showPWD && props.showExtra)) &&
						showTravelPurposes() &&
						showForMeQuestionAbove() && (
							<CovidTestAndRelease
								ref={covidTestAndReleaseRefForMe}
								forMeQuestion
								onlyForMeQuestion
								isFullyVaccinated={showIsFullyVaccinated()}
								showFullyVaccinatedSelectOption={showFullyVaccinatedSelectOption()}
							/>
						)}

					{showDeliveryCheckbox() && (
						<>
							{/* <div className="hr product-hr" /> */}
							<GridSpaced
								container
								spacing={2}
								additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
								className={"formRow"}
							>
								<Grid item xs={12} sm={12} md={12}>
									<FormControlLabel
										control={<Checkbox color="primary" {...checkbox("sameDeliveryAddress")} />}
										label={<>Is this address to be used for postage?</>}
									/>
								</Grid>
							</GridSpaced>
							{formState.formData.sameDeliveryAddress || (
								<DeliveryAddress addressLine1Prefix="Postage" ref={deliveryAddressRef} />
							)}
							<div className="hr product-hr" />
						</>
					)}

					{showSelfIsolationAddress() && (
						<>
							{/* <div className="hr product-hr" /> */}
							<GridSpaced
								container
								spacing={2}
								additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
								className={"formRow"}
							>
								<Grid item xs={12} sm={12} md={12}>
									<FormControlLabel
										control={<Checkbox color="primary" {...checkbox("sameSelfIsolationAddress")} />}
										label={<>Is this address to be used for self-isolation?</>}
									/>
								</Grid>
							</GridSpaced>
							{formState.formData.sameSelfIsolationAddress || (
								<DeliveryAddress addressLine1Prefix="Self-Isolation" ref={selfIsolationAddressRef} />
							)}
							<div className="hr product-hr" />
						</>
					)}

					{/* {!showForMeQuestion() && showTravelPurposes() && (
						<>
							{showTravelPurposesPart1() ? (
								<CovidTestAndRelease
									ref={covidTestAndReleaseRef}
									hideInformationForTestAndRelease={hideInformationForTestAndRelease()}
								/>
							) : showTravelPurposesCheckbox() ? (
								<>{travelPurposeCheckbox()}</>
							) : null}
						</>
					)} */}

					{showLoggedInOptions() && (
						<>
							{covidPCR()}
							{travelPurposes()}
							{expressOption()}
							{certOption()}
							{fullyVaccinatedConfirmationCheckbox()}
							{sameDayDriveThroughOption()}
							{testToReleaseCheckbox()}
							{covidBundleCheckboxes()}
						</>
					)}

					{showFurtherInfoQuestion() && (
						<GridSpaced
							container
							spacing={2}
							additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
							className={"formRow"}
						>
							<GridSpaced item xs={12} sm={12} md={12}>
								<InputLabel>
									{isGPAppointment()
										? "In order to make this consultation as valuable for you as possible please explain in the broadest terms, " +
										  "your reason for visiting Summerfield Healthcare and your preferred outcome. We do appreciate that some " +
										  "patients only wish to discuss this with the GP during the consultation, but any information you can provide " +
										  "is very helpful in order for our GPs to provide the best care possible."
										: "Is there any further information we need to know about you for this appointment?"}
								</InputLabel>
								<TextField
									placeholder={
										isGPAppointment()
											? "e.g. I have a cough and I’m worried about asthma. I’m hoping for a diagnosis and possibly treatment or a referral."
											: "Please add any extra information here..."
									}
									fullWidth
									multiline={true}
									rows={"6"}
									autoComplete="note"
									value={storesContext.domain.EnquiryStore.enquiry.enquiryDetails.noteContent}
									onChange={e =>
										storesContext.domain.EnquiryStore.enquiry.enquiryDetails.setNoteContent(
											e.target.value,
										)
									}
								/>
							</GridSpaced>
						</GridSpaced>
					)}

					{props.showPWD && props.showExtra && (
						<>
							{travelPurposes()}

							{!formState.formData.travellingRestricted && (
								<>
									<GridSpaced container spacing={2} className={"formRow"}>
										<Grid item xs={12} sm={12} md={6}>
											<InputLabel>Source of introduction</InputLabel>
										</Grid>
										{storesContext.domain.EnquiryStore.enquiry.customerDetails.sourceOfInterest.includes(
											"Other",
										) && (
											<>
												<Grid item xs={12} sm={12} md={6}>
													<InputLabel>Please Specify</InputLabel>
												</Grid>
											</>
										)}
									</GridSpaced>

									<GridSpaced
										container
										spacing={2}
										additionalcss={"margin-bottom: 25px;"}
										className={"formRow"}
									>
										<Grid item xs={12} sm={12} md={6}>
											<AltSelect
												style={{ marginTop: "14px" }}
												className={"appointmentType border-correct"}
												name={"sourceofinterest"}
												onChange={(e: any) => {
													storesContext.domain.EnquiryStore.enquiry.customerDetails.setSoI(
														e.target.value,
													);
													formState.setValue("sourceOfInterest", "");
													formState.remount();
													if (!e.target.value) {
														soIChanged.current = false;
													} else {
														soIChanged.current = true;
													}
												}}
												fullWidth
												value={
													storesContext.domain.EnquiryStore.enquiry.customerDetails
														.sourceOfInterest
												}
												native
												fontsize={"17px"}
												disabled={disabledSoI}
											>
												<option value="">Please Choose...</option>
												{sourceOfInterestItems}
											</AltSelect>
											{!storesContext.domain.EnquiryStore.enquiry.customerDetails
												.sourceOfInterest &&
												(soIChanged.current || triedSubmit.current) && (
													<>
														<div className="error">Please select an option</div>
													</>
												)}
										</Grid>
										{storesContext.domain.EnquiryStore.enquiry.customerDetails.sourceOfInterest.includes(
											"Other",
										) && (
											<>
												<Grid item xs={12} sm={12} md={6}>
													<TextField
														fullWidth
														{...text("sourceOfInterest", {
															validationRules: Validations.baseValidation,
														})}
													/>
													{formState.showErrors("sourceOfInterest")}
												</Grid>
											</>
										)}
									</GridSpaced>
								</>
							)}

							{expressOption()}
							{certOption()}
							{fullyVaccinatedConfirmationCheckbox()}
							{sameDayDriveThroughOption()}

							{showSignUpCheckboxes() && (
								<>
									{showSignUpAntigenCheckboxes() && (
										<>
											<GridSpaced
												container
												spacing={2}
												additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
												className={"formRow"}
											>
												<Grid item xs={12} sm={12} md={12}>
													<FormControlLabel
														control={
															<Checkbox
																color="primary"
																{...checkbox("antigenTest", {
																	validationRules: [
																		(v: boolean) =>
																			v ||
																			!c_antigen() ||
																			"You must tick this box",
																	],
																})}
															/>
														}
														label={
															c_drive()
																? `I understand that these lateral flow tests are not for flight/travel purposes (if required for these purposes you must book a PCR test).`
																: `I understand that these COVID-19 antigen
																	lateral flow tests must be administered by a
																	trained medical professional i.e. HCA or nurse.`
														}
													/>
													{formState.showErrors("antigenTest")}
												</Grid>
											</GridSpaced>
										</>
									)}

									<GridSpaced
										container
										spacing={2}
										additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
										className={"formRow"}
									>
										<Grid item xs={12} sm={12} md={12}>
											<FormControlLabel
												control={
													<Checkbox
														checked={
															storesContext.domain.EnquiryStore.enquiry.customerDetails
																.emailConsent
														}
														onChange={e =>
															storesContext.domain.EnquiryStore.enquiry.customerDetails.setEmailConsent(
																e.target.checked,
															)
														}
														value={
															storesContext.domain.EnquiryStore.enquiry.customerDetails
																.emailConsent
														}
														color="primary"
													/>
												}
												label="Can we from time to time send you an email about discounts, promotions and events?"
												className="ClickLabel"
											/>
										</Grid>
									</GridSpaced>
								</>
							)}

							{easterDispatchBox()}

							{showSignUpCheckboxes() && (
								<>
									{testToReleaseCheckbox()}
									{covidBundleCheckboxes()}

									<GridSpaced
										container
										spacing={2}
										additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
										className={"formRow"}
									>
										<Grid item xs={12} sm={12} md={12}>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														{...checkbox("terms", {
															validationRules: [
																(v: boolean) =>
																	v || "You must accept the terms & conditions",
															],
														})}
													/>
												}
												label="I agree to the"
											/>
											<Link onClick={openModal} className="tncLink">
												terms & conditions
											</Link>
											{formState.showErrors("terms")}
										</Grid>
									</GridSpaced>
								</>
							)}

							{covidPCR()}

							{showEmployerCheckbox() && (
								<GridSpaced
									container
									spacing={2}
									additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
									className={"formRow"}
								>
									<Grid item xs={12} sm={12} md={12}>
										<FormControlLabel
											control={
												<Checkbox color="primary" {...checkbox("shareResultsWithEmployer")} />
											}
											label={
												<>
													I have read and understood who the referring party is in relation to
													the voucher code I have been provided.
													<br />I consent to my test result being viewable by the referring
													party for 7 days once it has been uploaded.
												</>
											}
										/>
										{formState.showErrors("shareResultsWithEmployer")}
									</Grid>
								</GridSpaced>
							)}
						</>
					)}
					{showLoggedInTOS() && (
						<>
							{showAntigenCheckbox() && (
								<>
									<GridSpaced
										container
										spacing={2}
										additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
										className={"formRow"}
									>
										<Grid item xs={12} sm={12} md={12}>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														{...checkbox("antigenTest", {
															validationRules: [
																(v: boolean) =>
																	v ||
																	!(
																		storesContext.domain.EnquiryStore.enquiry
																			.appointmentType &&
																		storesContext.domain.EnquiryStore.enquiry.appointmentType
																			.appointmentTypeName!.toLowerCase()
																			.includes("antigen")
																	) ||
																	"You must tick this box",
															],
														})}
													/>
												}
												label={
													storesContext.domain.EnquiryStore.enquiry.appointmentType &&
													storesContext.domain.EnquiryStore.enquiry.appointmentType
														.appointmentTypeName!.toLowerCase()
														.includes("drive")
														? `I understand that these lateral flow tests are not for flight/travel purposes (if required for these purposes you must book a PCR test).`
														: `I understand that these COVID-19 antigen
															lateral flow tests must be administered by a
															trained medical professional i.e. HCA or nurse.`
												}
											/>
											{formState.showErrors("antigenTest")}
										</Grid>
									</GridSpaced>
								</>
							)}
							{easterDispatchBox()}

							<GridSpaced
								container
								spacing={2}
								additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
								className={"formRow"}
							>
								<Grid item xs={12} sm={12} md={12}>
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												{...checkbox("terms", {
													validationRules: [
														(v: boolean) => v || "You must accept the terms & conditions",
													],
												})}
											/>
										}
										label="I agree to the"
									/>
									<Link onClick={openModal} className="tncLink">
										terms & conditions
									</Link>
									{formState.showErrors("terms")}
								</Grid>
							</GridSpaced>
						</>
					)}
					{showVoucher() && (
						<>
							<GridSpaced additionalcss="margin-bottom: 20px;" className="voucher-input">
								{/* <InputLabel>Town/City*</InputLabel> */}
								<TextField
									value={storesContext.domain.EnquiryStore.vouchers.voucherCode}
									onChange={e =>
										storesContext.domain.EnquiryStore.vouchers.updateVoucherCode(
											e.target.value,
											storesContext.domain.EnquiryStore.enquiry.appointmentType!.id!,
										)
									}
									placeholder="Voucher Code"
								/>
								{storesContext.domain.EnquiryStore.vouchers.isValid ? (
									<p>Voucher Accepted</p>
								) : (
									storesContext.domain.EnquiryStore.vouchers.voucherCode && (
										<p style={{ margin: 0, color: "red" }}>Invalid Voucher</p>
									)
								)}
							</GridSpaced>
						</>
					)}
				</form>
			</div>

			{tncModal}

			{showPrice() && (
				<>
					<label style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
						Total to Pay:{" "}
						<p style={{ display: "inline" }}>
							£{getPrice(true)}
							{storesContext.domain.EnquiryStore.multipleDiscount ? (
								<>
									{" "}
									- {storesContext.domain.EnquiryStore.multipleDiscount}% discount (£
									{storesContext.domain.EnquiryStore.basePrice -
										storesContext.domain.EnquiryStore.price}
									) = £{getPrice()}
								</>
							) : (
								""
							)}
							{storesContext.domain.EnquiryStore.multipleDiscountValue ? (
								<>
									{" "}
									- £{storesContext.domain.EnquiryStore.multipleDiscountValue} discount = £
									{getPrice()}
								</>
							) : (
								""
							)}{" "}
							Inc. VAT
						</p>
					</label>
				</>
			)}

			{!c_travelRestrict() && <div className="hr" />}

			<div className="mydetails-buttons">
				{!c_travelRestrict() && (
					<Button
						type="submit"
						variant="contained"
						color="primary"
						className="submit"
						onClick={doSubmit}
						disabled={storesContext.domain.EnquiryStore.isAllowed !== true && !props.onlyReg}
					>
						{storesContext.domain.EnquiryStore.isAllowed === undefined && !props.onlyReg ? (
							"Please wait..."
						) : props.saveMsg.length > 0 ? (
							props.saveMsg
						) : (
							<>Save</>
						)}
					</Button>
				)}

				{props.hideCancel || (
					<Button variant="contained" className="cancel" onClick={doCancel}>
						{props.cancelMsg.length > 0 ? props.cancelMsg : <>Cancel</>}
					</Button>
				)}
			</div>
			{storesContext.domain.EnquiryStore.isAllowed === false && (
				<div style={{ color: "red", textAlign: "center" }}>You have already bought a product of this type.</div>
			)}
		</>
	));
};
