import React from "react";

interface IProps {
	covid?: boolean;
	antigen?: boolean;
	type2?: boolean;
}

export const TermsAndConditions: React.FC<IProps> = props => {
	const { covid, antigen } = props;

	const renderTermsType1 = () => (
		<>
			<h6>Your agreement with us</h6>
			<h2>Terms And Conditions</h2>
			{antigen ? (
				<>
					<ol type="1">
						<li>
							"We" are Ryminster Healthcare Limited trading as Summerfield Healthcare, a company
							registered in England and Wales under company number 07844746 and with our registered office
							at Building 1, Charlesworth Court, Knights Way, Shrewsbury, SY1 3AB.
						</li>
						<li>
							“You" are the customer who has accessed our services through our websites or called our
							telephone number and has placed an order for product(s).
						</li>
						<li>
							These terms and conditions along with our Privacy Policy (together, the "Terms") apply
							throughout our website and during any telephone call or written correspondence between you
							and us. If there is any conflict between these Terms and any terms or conditions found
							elsewhere on our website, or in any written or verbal communication between you and us,
							these Terms shall prevail.
						</li>
						<li>
							Test results or interpretation provided by the CLINITEST® Rapid COVID-19 Antigen Test are
							for the purposes of information only.
						</li>
						<li>
							This service is not a substitute for proper medical investigation and advice and we do not
							provide clinical or diagnostic services in relation to the tests.
						</li>
						<li>
							Based on the results of your test(s) and/or other information you may require further
							investigation or both.
						</li>
						<li>
							You agree that you are solely responsible for acting on such guidance and that Ryminster
							Healthcare Limited accepts no liability in the event you choose not to do so.
						</li>
					</ol>
					<h2>Contacting Us</h2>
					<ol type="1" start={8}>
						<li>
							If you wish to contact us for any reason, including cancelling a contract or to make a
							complaint, you can contact us by telephoning our customer service team on 0333 9000 010 or
							by e-mailing us on{" "}
							<a target="_blank" href="mailto:enquiries@summerfieldhealthcare.co.uk">
								enquiries@summerfieldhealthcare.co.uk
							</a>
						</li>
						<li>
							If we need to contact you we will do so by e-mail, phone or occasionally by letter using the
							contact details you provide to us in your order. By providing us with your contact details
							you give us your consent to contact you about the service we provide and Products you have
							bought or enquired about. If necessary we may leave you a message.
						</li>
						<li>
							We will send test results to you by email unless you expressly ask us to do so. It is your
							responsibility to ensure that your contact details are kept up-to-date.
							<br />
							<br />
							Ryminster Healthcare Limited is not liable if personal or medical data transmitted via an
							email is received by an unauthorised third party, as both parties (Ryminster Healthcare
							Limited & the customer) agree that internet is an environment that cannot guarantee
							confidentiality.
						</li>
					</ol>
					<h2>Our Service</h2>
					<ol type="1" start={11}>
						<li>
							It is your responsibility to ensure the guidance of how to undertake the test correctly.
						</li>
						<li>
							The tests sold by Ryminster Healthcare Limited are specialist pathology tests. You agree
							that you are solely responsible for selecting tests and that any tests you do purchase from
							Ryminster Healthcare Limited are suitable for you and their intended purpose. If in doubt
							please talk to a doctor, a qualified medical professional, your occupational health advisor,
							employer or person advising you to have the test.
						</li>
					</ol>
					<h2>Medical Questionnaire and How We Use Your Personal Information</h2>
					<ol type="1" start={13}>
						<li>
							We may use your information for our own business analysis, product development and marketing
							purposes.
							<br />
							<br />
							We will not sell or pass on your personal information to third parties to enable them to
							contact you directly or market their products or services to you.
						</li>
						<li>We only use your information in accordance with our Privacy Policy.</li>
						<li>
							Covid-19 has been added by the Government to ‘the list of notifiable diseases’ and as such
							there is a statutory duty to notify the ‘proper officer’ at your local council or local
							health protection team of suspected cases of certain infectious diseases.
						</li>
						<li>
							You must ensure that you follow the latest Government Advice regarding Covid-19 available at
							<br />
							<ol type="a">
								<li>
									<a
										target="_blank"
										href="https://www.gov.uk/government/topical-events/coronavirus-covid-19-uk-government-response?utm_campaign=515394_MAND-O3-VEVA-124629-0403-VEVA-130944-0403%20coronavirus%20information&utm_medium=email&utm_source=Dotmailer&dm_i=4Y1G,B1OI,1GIU88,17MR4,1"
									>
										https://www.gov.uk/government/topical-events/coronavirus-covid-19-uk-government-response?utm_campaign=515394_MAND-O3-VEVA-124629-0403-VEVA-130944-0403%20coronavirus%20information&utm_medium=email&utm_source=Dotmailer&dm_i=4Y1G,B1OI,1GIU88,17MR4,1
									</a>
								</li>
								<li>
									<a target="_blank" href="https://www.nhs.uk/conditions/coronavirus-covid-19/">
										https://www.nhs.uk/conditions/coronavirus-covid-19/
									</a>
								</li>
							</ol>
							Ryminster Healthcare Limited is not responsible for the content of external links.
							<br />
							<br />
							The contents of links may change and be updated from time to time. You are responsible for
							ensuring that you are aware of the latest Government/NHS updates regarding the virus
						</li>
					</ol>
					<h2>How the Contract is Formed Between You and Us</h2>
					<ol type="1" start={17}>
						<li>Payment must be received in full in cleared funds prior to dispatch of goods.</li>
					</ol>
					Once payment is received, we will confirm our acceptance to you by sending you a confirmation email
					("Acceptance Confirmation"). The contract between us will only be formed on the date we send you the
					Acceptance Confirmation (the "Contract").
					<ol type="1" start={18}>
						<li>
							If, for any reason, we are unable to provide the testing equipment you have ordered, we will
							inform you by email or by phone.
						</li>
					</ol>
					If you have already paid for the testing equipment we will refund you the full amount within 3
					working days. A working day is any day other than a Saturday, Sunday or public holiday in the UK
					("Working Day").
					<h2>How to Pay</h2>
					<ol type="1" start={19}>
						<li>
							You may pay for Products using a debit or credit card and this includes MasterCard, Visa,
							Visa Electron, Switch, Solo, Maestro or JCB. Summerfield Healthcare may also request that
							you pay for products by BACS, Particularly for high value orders.
						</li>
					</ol>
					<h2>Price of Products</h2>
					<ol type="1" start={20}>
						<li>
							The price of the Products will be as quoted on our website at the time you submit your
							order.
						</li>
						<li>
							You will pay an additional charge if you choose any extra tests other than those initially
							requested prior to completing your order.
						</li>
						<li>
							Prices for our Products may change from time to time, but changes will not affect any order
							you have already placed.
						</li>
						<li>After your payment has been processed and will receive an order confirmation.</li>
					</ol>
					<h2>Cancellations and Refunds</h2>
					<ol type="1" start={24}>
						<li>
							If you would like to cancel your order please contact our customer care team on 0333 9000
							010 between 9am and 5pm Monday – Friday.
						</li>
					</ol>
					You must have your customer and payment details to hand.
					<ol type="1" start={25}>
						<li>
							If you cancel your order before your testing equipment has been dispatched then you will
							receive a full refund within 7-10 working days, but we may deduct the cost of postage if a
							postage label has already been created and paid.
						</li>
					</ol>
					We will refund you on the credit card or debit card used by you to pay.
					<ol type="1" start={26}>
						<li>No refunds can be given once the kit has been dispatched.</li>
						<li>No returns are accepted.</li>
					</ol>
					<h2>Our Liability</h2>
					<ol type="1" start={28}>
						<li>
							The service(s) you purchase from us are for private use only and you agree not to use the
							Products for any commercial purpose. We are not liable to you for any loss of profit, loss
							of business or revenue, business interruption or loss of business opportunity.
						</li>
						<li>
							Our total liability to you resulting from a Contract is limited to the total value of that
							Contract - meaning the price you paid for the Products and any additional services. In
							particular we are not liable for any costs, loss, delay, inconvenience or damage you suffer
							as a result of:
							<ol type="a">
								<li>The sample being lost, delayed or damaged in transit;</li>
								<li>
									Your failure to follow instructions provided to you in relation to sample collection
									or presample preparation requirements.
								</li>
								<li>
									Your failure to act upon our advice if we recommend that you seek medical advice or
									attention having taken a test;
								</li>
								<li>Your failure to show positive tests results to the relevant health authorities.</li>
								<li>Loss or damage that is not foreseeable;</li>
								<li>Events outside our control as described below.</li>
							</ol>
						</li>
						<li>
							If we fail to comply with the Terms, we are responsible for loss or damage you suffer that
							is a foreseeable result of our breach of the Terms or our negligence. Loss or damage is
							foreseeable if it is an obvious consequence of our breach or if it was contemplated by you
							and us at the time we entered into this Contract.
						</li>
						<li>It is your responsibility to ensure that the relevant sample has been collected.</li>
						<li>
							We do not in any way exclude or limit our liability for:
							<ol type="a">
								<li>death or personal injury caused by our negligence;</li>
								<li>fraud or fraudulent misrepresentation;</li>
								<li>
									any breach of the terms implied by section 12 of the Sale of Goods Act 1979 (title
									and quiet possession) as amended by the Consumer Rights Act 2015;
								</li>
								<li>
									any breach of the terms implied by section 9 to 11 of the Consumer Rights Act 2015
									(description, satisfactory quality, fitness for purpose and samples); and
								</li>
								<li>defective products under the Consumer Protection Act 1987.</li>
							</ol>
						</li>
					</ol>
					<h2>Events Outside Our Control</h2>
					<ol type="1" start={33}>
						<li>
							We will not be liable or responsible for any failure to perform, or delay in performance of,
							any of our obligations under a Contract that is caused by any act or event beyond our
							reasonable control (an "Event outside Our Control").
						</li>
						<li>
							If an Event Outside Our Control takes place that affects the performance of our obligations
							under a Contract:
						</li>
					</ol>
					we will contact you as soon as reasonably possible to notify you; and our obligations under a
					Contract will be suspended and the time for performance of our obligations will be extended for the
					duration of the Event outside Our Control.
					<ol type="1" start={35}>
						<li>
							You may cancel a Contract affected by an Event Outside Our Control which has continued for
							more than 30 days. To cancel, please contact us. If you opt to cancel, we will refund in
							full the total price you have paid for the Contract.
						</li>
					</ol>
					<h2>Our Right to Vary These Terms</h2>
					<ol type="1" start={36}>
						<li>We may amend the Terms from time to time.</li>
						<li>
							Every time you order a service from us, the Terms in force at the time of placing your order
							will apply to the Contract between you and us.
						</li>
						<li>
							If we have to revise the Terms as they apply to your order, we will contact you to give you
							reasonable advance notice of the changes and what orders will be affected. We will let you
							know how to cancel the Contract if you are not happy with the changes.
						</li>
					</ol>
					<h2>Other Important Terms</h2>
					<ol type="1" start={39}>
						<li>
							We may transfer our rights and obligations under a Contract to another organisation, but
							this will not affect your rights or our obligations under the Terms.
						</li>
						<li>
							This Contract is between you and us. No other person shall have any rights to enforce any of
							its terms, whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise.
						</li>
						<li>
							Each of the paragraphs of the Terms operates separately. If any court or relevant authority
							decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain
							in full force and effect.
						</li>
						<li>
							If we fail to insist that you perform any of your obligations under the Terms, or if we do
							not enforce our rights against you, or if we delay in doing so, that will not mean that we
							have waived our rights against you and will not mean that you do not have to comply with
							those obligations. If we do waive a default by you, we will only do so in writing, and that
							will not mean that we will automatically waive any later default by you.
						</li>
						<li>
							Please note that the Terms are governed by English law. This means a Contract for the
							purchase of Products through our site and any dispute or claim arising out of or in
							connection with it will be governed by English law. You and we both agree to that the courts
							of England and Wales will have exclusive jurisdiction.
						</li>
					</ol>
				</>
			) : covid ? (
				<>
					<ol type="1">
						<li>
							"We" are Ryminster Healthcare Limited trading as Summerfield Healthcare, a company
							registered in England and Wales under company number 07844746 and with our registered office
							at Building 1, Charlesworth Court, Knights Way, Shrewsbury, SY1 3AB.
						</li>
						<li>
							“You" are the customer who has accessed our services through our websites or called our
							telephone number and has placed an order for our service(s).
						</li>
						<li>
							These terms and conditions along with our Privacy Policy (together, the "Terms") apply
							throughout our website and during any telephone call or written correspondence between you
							and us. If there is any conflict between these Terms and any terms or conditions found
							elsewhere on our website, or in any written or verbal communication between you and us,
							these Terms shall prevail.
						</li>
						<li>
							Test results and comments or interpretation provided by us in relation to those results are
							for the purposes of information only.
						</li>
						<li>
							This service is not a substitute for proper medical investigation and advice and we do not
							provide clinical or diagnostic services in relation to the tests. Our comments are based on
							information available to us at the time, which information may be insufficient to gain a
							complete understanding of your health or a particular condition you may be suffering from.
							If you have any concerns regarding your health or your test results you should discuss them
							directly with your GP or by calling 111.
						</li>
						<li>
							Based on the results of your test(s) and/or other information you may require further
							investigation or both.
						</li>
						<li>
							You agree that you are solely responsible for acting on such guidance and that Ryminster
							Healthcare Limited accepts no liability in the event you choose not to do so.
						</li>
					</ol>
					<h2>Contacting Us</h2>
					<ol type="1" start={8}>
						<li>
							If you wish to contact us for any reason, including cancelling a contract or to make a
							complaint, you can contact us by telephoning our customer service team on 0333 9000 010 or
							by e-mailing us on{" "}
							<a target="_blank" href="mailto:enquiries@summerfieldhealthcare.co.uk">
								enquiries@summerfieldhealthcare.co.uk
							</a>
						</li>
						<li>
							If we need to contact you we will do so by e-mail, phone or occasionally by letter using the
							contact details you provide to us in your order. By providing us with your contact details
							you give us your consent to contact you about the service we provide and Products you have
							bought or enquired about. If necessary we may leave you a message.
						</li>
						<li>
							We will send test results to you by email unless you expressly ask us not to do so. It is
							your responsibility to ensure that your contact details are kept up-to-date
							<br />
							<br />
							Ryminster Healthcare Limited is not liable if personal or medical data transmitted via an
							email is received by an unauthorised third party, as both parties (Ryminster Healthcare
							Limited & the customer) agree that internet is an environment that cannot guarantee
							confidentiality.
						</li>
					</ol>
					<h2>Our Service</h2>
					<ol type="1" start={11}>
						<li>
							All samples include a prepaid envelope to be sent on to one of our laboratories. It is your
							responsibility to ensure the guidance of how to undertake the test correctly.
						</li>
						<li>
							The test results and any accompanying interpretation will be sent to you by email unless you
							expressly ask us not to do so.
							<br />
							<br />A printed copy of the results can be ordered and posted for an additional charge
							during the process.
						</li>
						<li>We may contact you by telephone regarding your sample and test results if necessary.</li>
						<li>
							You must also provide us with certain personal information, including your name, address,
							gender and date of birth in order to purchase our service(s). Name and date of birth are
							standard means of identifying biological samples at our partner laboratories.
						</li>
						<li>
							The tests sold by Ryminster Healthcare Limited are specialist pathology tests. You agree
							that you are solely responsible for selecting tests and that any tests you do purchase from
							Ryminster Healthcare Limited are suitable for you and their intended purpose. If in doubt
							please talk to your doctor, a qualified medical professional, your occupational health
							advisor, employer or person advising you to have the test.
						</li>
					</ol>
					<h2>Medical Questionnaire and How We Use Your Personal Information</h2>
					<ol type="1" start={16}>
						<li>
							Except as set out below, any information that is collected from the medical questionnaire
							and/or the test results will be used solely in relation to the products you have purchased
							from us.
							<br />
							<br />
							By placing an order you give us your consent to pass any and all relevant information about
							you on to our partner clinics and laboratories and/or to our employees and agents for the
							purposes of preparing your sample and analysing and interpreting the results.
							<br />
							<br />
							We may use your information for our own business analysis, product development and marketing
							purposes.
							<br />
							<br />
							We will not sell or pass on your personal information to third parties to enable them to
							contact you directly or market their products or services to you.
						</li>
						<li>We only use your personal information in accordance with our Privacy Policy.</li>
						<li>
							Covid-19 has been added by the Government to ‘the list of notifiable diseases’ and as such
							Ryminster Healthcare Limited and/or our partner laboratories have a statutory duty to notify
							the ‘proper officer’ at your local council or local health protection team of suspected
							cases of certain infectious diseases.
						</li>
						<li>
							You must ensure that you follow the latest Government Advice regarding Covid-19 available at{" "}
							<a
								target="_blank"
								href="https://www.gov.uk/government/topical-events/coronavirus-covid-19-uk-government-response?utm_campaign=515394_MAND-O3-VEVA-124629-0403-VEVA-130944-0403%20coronavirus%20information&utm_medium=email&utm_source=Dotmailer&dm_i=4Y1G,B1OI,1GIU88,17MR4,1"
								style={{ overflowWrap: "break-word" }}
							>
								https://www.gov.uk/government/topical-events/coronavirus-covid-19-uk-government-response?utm_campaign=515394_MAND-O3-VEVA-124629-0403-VEVA-130944-0403%20coronavirus%20information&utm_medium=email&utm_source=Dotmailer&dm_i=4Y1G,B1OI,1GIU88,17MR4,1
							</a>
							.
							<br />
							<br />
							Ryminster Healthcare Limited is not responsible for the content of external links.
							<br />
							<br />
							The contents of links may change and be updated from time to time. You are responsible for
							ensuring that you are aware of the latest Government updates regarding the virus
						</li>
					</ol>
					<h2>How the Contract is Formed Between You and Us</h2>
					<ol type="1" start={20}>
						<li>
							Payment must be received in full in cleared funds prior to dispatch of goods.
							<br />
							<br />
							Once payment is received, we will confirm our acceptance to you by sending you a
							confirmation email ("Acceptance Confirmation"). The contract between us will only be formed
							on the date we send you the Acceptance Confirmation (the "Contract").
						</li>
						<li>
							If, for any reason, we are unable to perform a test you have ordered, we will inform you by
							email or by phone.
							<br />
							<br />
							If you have already paid for the test we will refund you the full amount within 3 working
							days. A working day is any day other than a Saturday, Sunday or public holiday in the UK
							("Working Day").
						</li>
						<li>
							By purchasing a test and sending a sample to one of our laboratories you are giving
							Ryminster Healthcare Limited and its partner laboratories your informed consent to perform
							the tests you have ordered.
						</li>
					</ol>
					<h2>How to Pay</h2>
					<ol type="1" start={23}>
						<li>
							You may pay for Products using a debit or credit card and this includes MasterCard, Visa,
							Visa Electron, Switch, Solo, Maestro or JCB.{" "}
						</li>
					</ol>
					<h2>Price of Products</h2>
					<ol type="1" start={24}>
						<li>
							The price of the Products will be as quoted on our website at the time you submit your order
						</li>
						<li>
							You will pay an additional charge if you choose any extra tests other than those initially
							requested prior to completing your appointment.
						</li>
						<li>
							Prices for our Products may change from time to time, but changes will not affect any order
							you have already placed.
						</li>
						<li>After your payment has been processed and will receive an order confirmation.</li>
					</ol>
					<h2>Samples and Test Results</h2>
					<ol type="1" start={28}>
						<li>
							Any sample you provide (whether it is blood, tissue, bodily fluid, or other biological
							sample) to one of our laboratories for analysis will be analysed only in relation to the
							test or tests that you have ordered.
						</li>
						<li>
							Ryminster Healthcare will not be responsible for any samples that the laboratory are unable
							to process due to errors in the method of collection or in following any of the instructions
							provided.{" "}
						</li>
						<li>Further charges would apply if a retest is ordered.</li>
					</ol>
					<h2>Cancellations and Refunds</h2>
					<ol type="1" start={31}>
						<li>
							If you would like to cancel your order please contact our customer care team on 0333 9000
							010 between 9am and 5pm Monday – Friday.
							<br />
							<br />
							You must have your customer and payment details to hand.
						</li>
						<li>
							If you cancel your order before your sample collection kit has been dispatched then you will
							receive a full refund within 7-10 working days.
							<br />
							<br />
							We will refund you on the credit card or debit card used by you to pay.
						</li>
						<li>No refunds can be given once the kit has been dispatched.</li>
					</ol>
					<h2>Our Liability</h2>
					<ol type="1" start={34}>
						<li>
							The service(s) you purchase from us are for private use only and you agree not to use the
							Products for any commercial purpose. We are not liable to you for any loss of profit, loss
							of business or revenue, business interruption or loss of business opportunity.
						</li>
						<li>
							Our total liability to you resulting from a Contract is limited to the total value of that
							Contract - meaning the price you paid for the Products and any additional services. In
							particular we are not liable for any costs, loss, delay, inconvenience or damage you suffer
							as a result of:
							<ol type="1" start={1}>
								<li>The sample being lost, delayed or damaged in transit;</li>
								<li>The laboratory being unable to test your sample for any reason;</li>
								<li>
									Test results not being made available to you within the turnaround time stated for
									any product;
								</li>
								<li>
									Your failure to follow instructions provided to you in relation to sample collection
									or presample preparation requirements.
								</li>
								<li>
									Your failure to act upon our advice if we recommend that you seek medical advice or
									attention having taken a test;
								</li>
								<li>Your failure to show positive tests results to the relevant health authorities.</li>
								<li>Your failure to attend a pre-booked appointment;</li>
								<li>Loss or damage that is not foreseeable;</li>
								<li>Events outside our control as described below.</li>
							</ol>
						</li>
					</ol>
					<ol type="1" start={1}>
						<li>
							If we fail to comply with the Terms, we are responsible for loss or damage you suffer that
							is a foreseeable result of our breach of the Terms or our negligence. Loss or damage is
							foreseeable if it is an obvious consequence of our breach or if it was contemplated by you
							and us at the time we entered into this Contract.
							<ol type="i" start={1}>
								<li>
									It is your responsibility to ensure that the relevant sample has been collected.
								</li>
								<li>We do not in any way exclude or limit our liability for:</li>
								<li>death or personal injury caused by our negligence;</li>
								<li>fraud or fraudulent misrepresentation;</li>
								<li>
									any breach of the terms implied by section 12 of the Sale of Goods Act 1979 (title
									and quiet possession) as amended by the Consumer Rights Act 2015;
								</li>
								<li>
									any breach of the terms implied by section 9 to 11 of the Consumer Rights Act 2015
									(description, satisfactory quality, fitness for purpose and samples); and
								</li>
								<li>defective products under the Consumer Protection Act 1987.</li>
							</ol>
						</li>
					</ol>
					<h2>Events Outside Our Control</h2>
					<ol type="1" start={36}>
						<li>
							We will not be liable or responsible for any failure to perform, or delay in performance of,
							any of our obligations under a Contract that is caused by any act or event beyond our
							reasonable control (an "Event outside Our Control").
						</li>
						<li>
							If an Event Outside Our Control takes place that affects the performance of our obligations
							under a Contract:
							<br />
							we will contact you as soon as reasonably possible to notify you; and our obligations under
							a Contract will be suspended and the time for performance of our obligations will be
							extended for the duration of the Event outside Our Control.
						</li>
						<li>
							You may cancel a Contract affected by an Event Outside Our Control which has continued for
							more than 30 days. To cancel, please contact us. If you opt to cancel, we will refund in
							full the total price you have paid for the Contract.
						</li>
					</ol>
					<h2>Our Right to Vary These Terms</h2>
					<ol type="1" start={39}>
						<li>We may amend the Terms from time to time.</li>
						<li>
							Every time you order a service from us, the Terms in force at the time of placing your order
							will apply to the Contract between you and us.
						</li>
						<li>
							If we have to revise the Terms as they apply to your order, we will contact you to give you
							reasonable advance notice of the changes and what orders will be affected. We will let you
							know how to cancel the Contract if you are not happy with the changes.
						</li>
					</ol>
					<h2>Other Important Terms</h2>
					<ol type="1" start={42}>
						<li>
							We may transfer our rights and obligations under a Contract to another organisation, but
							this will not affect your rights or our obligations under the Terms.
						</li>
						<li>
							This Contract is between you and us. No other person shall have any rights to enforce any of
							its terms, whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise.
						</li>
						<li>
							Each of the paragraphs of the Terms operates separately. If any court or relevant authority
							decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain
							in full force and effect.
						</li>
						<li>
							If we fail to insist that you perform any of your obligations under the Terms, or if we do
							not enforce our rights against you, or if we delay in doing so, that will not mean that we
							have waived our rights against you and will not mean that you do not have to comply with
							those obligations. If we do waive a default by you, we will only do so in writing, and that
							will not mean that we will automatically waive any later default by you.
						</li>
						<li>
							Please note that the Terms are governed by English law. This means a Contract for the
							purchase of Products through our site and any dispute or claim arising out of or in
							connection with it will be governed by English law. You and we both agree to that the courts
							of England and Wales will have exclusive jurisdiction.
						</li>
					</ol>
				</>
			) : (
				<>
					<ol type="1">
						<li>
							"We" are Ryminster Healthcare Limited trading as Summerfield Healthcare, a company
							registered in England and Wales under company number 07844746 and with our registered office
							at Building 1, Charlesworth Court, Knights Way, Shrewsbury, SY1 3AB.
						</li>
						<li>
							“You" are the customer who has accessed our services through our websites or called our
							telephone number and has placed an order for our service(s).
						</li>
						<li>
							These terms and conditions along with our Privacy Policy (together, the "Terms") apply
							throughout our website and during any telephone call or written correspondence between you
							and us. If there is any conflict between these Terms and any terms or conditions found
							elsewhere on our website, or in any written or verbal communication between you and us,
							these Terms shall prevail.
						</li>
						<li>
							Test results and comments or interpretation provided by us in relation to those results are
							for the purposes of information only. They may provide a guide to help you understand your
							health but do not, and are not intended to, constitute a clinical diagnosis.
						</li>
						<li>
							This service is not a substitute for proper medical investigation and advice and we do not
							provide clinical or diagnostic services in relation to the tests. Our comments are based on
							information available to us at the time, which information may be insufficient to gain a
							complete understanding of your health or a particular condition you may be suffering from.
							If you have any concerns regarding your health or your test results you should discuss them
							directly with your GP or our Private GP.
						</li>
						<li>
							Based on the results of your test(s) and/or other information that you have provided to us,
							we may recommend that you purchase a follow-up test or that you see our private GP for
							further investigation or both.
						</li>
						<li>
							You agree that you are solely responsible for acting on such guidance and that Ryminster
							Healthcare Limited accepts no liability in the event you choose not to do so.
						</li>
					</ol>
					<h2>Contacting Us</h2>
					<ol type="1" start={8}>
						<li>
							If you wish to contact us for any reason, including cancelling a contract or to make a
							complaint, you can contact us by telephoning our customer service team on 0333 9000 010 or
							by e-mailing us on{" "}
							<a target="_blank" href="mailto:enquiries@summerfieldhealthcare.co.uk">
								enquiries@summerfieldhealthcare.co.uk
							</a>
						</li>
						<li>
							If we need to contact you we will do so by e-mail, phone or occasionally by letter using the
							contact details you provide to us in your order. By providing us with your contact details
							you give us your consent to contact you about the service we provide and Products you have
							bought or enquired about. If necessary we may leave you a message.
						</li>
						<li>
							We will send test results to you by email unless you expressly ask us to do so. It is your
							responsibility to ensure that your contact details are kept up-to-date. Ryminster Healthcare
							Limited is not liable if personal or medical data transmitted via an email is received by an
							unauthorised third party, as both parties (Ryminster Healthcare Limited & the customer)
							agree that internet is an environment that cannot guarantee confidentiality.
						</li>
					</ol>
					<h2>Our Service</h2>
					<ol type="1" start={11}>
						<li>You must attend a clinic to have a sample taken.</li>
						<li>
							All samples taken will be sent to one of our laboratories on the day it is taken to enable
							the sample to be analysed.
						</li>
						<li>
							The test results and any accompanying interpretation will be sent to you by email unless you
							expressly ask us to do so. A printed copy of the results can be ordered and posted for an
							additional charge during the process.
						</li>
						<li>We may contact you by telephone regarding your sample and test results if necessary.</li>
						<li>You must be at least 18 years old and are providing your own sample for testing.</li>
						<li>
							You must also provide us with certain personal information, including your name, address,
							gender and date of birth in order to purchase our service(s). Name and date of birth are
							standard means of identifying biological samples at our partner laboratories.
						</li>
						<li>
							The tests sold by Ryminster Healthcare Limited are specialist pathology tests. You agree
							that you are solely responsible for selecting tests and that any tests you do purchase from
							Ryminster Healthcare Limited are suitable for you and their intended purpose. If in doubt
							please talk to your doctor, a qualified medical professional, your occupational health
							advisor, employer or person advising you to have the test.
						</li>
					</ol>
					<h2>MEDICAL QUESTIONNAIRE AND HOW WE USE YOUR PERSONAL INFORMATION</h2>
					<ol type="1" start={18}>
						<li>
							To enable us to effectively provide the product you are requested to complete a short
							medical questionnaire prior to your appointment. The medical questionnaire asks for basic
							information in relation to your medical history. You are also given the option to provide
							details of symptoms you may be suffering or other medical or lifestyle information you feel
							may be relevant to the test at the time of your appointment.
						</li>
						<li>
							Except as set out below, any information that is collected from the medical questionnaire
							and/or the test results will be used solely in relation to the products you have purchased
							from us. By placing an order you give us your consent to pass any and all relevant
							information about you on to our partner clinics and laboratories and/or to our employees and
							agents for the purposes of preparing your sample and analysing and interpreting the results.
							We may use your information for our own business analysis, product development and marketing
							purposes. We will not sell or pass on your personal information to third parties to enable
							them to contact you directly or market their products or services to you.
						</li>
						<li>
							None of your personal information will be passed on to your doctor or any other third party.
						</li>
						<li>We only use your personal information in accordance with our Privacy Policy. </li>
					</ol>
					<h2>HOW THE CONTRACT IS FORMED BETWEEN YOU AND US</h2>
					<ol type="1" start={22}>
						<li>
							Payment must be received in full in cleared funds prior to us confirming your appointment.
							Once payment is received, we will confirm our acceptance to you by sending you a
							confirmation email ("Acceptance Confirmation"). The contract between us will only be formed
							on the date we send you the Acceptance Confirmation (the "Contract").
						</li>
						<li>
							If, for any reason, we are unable to perform a test you have ordered, we will inform you by
							email or by phone. If you have already paid for the test we will refund you the full amount
							within 3 working days. A working day is any day other than a Saturday, Sunday or public
							holiday in the UK ("Working Day").
						</li>
						<li>
							By purchasing a test and sending a sample to one of our laboratories you are giving
							Ryminster Healthcare Limited and its partner laboratories your informed consent to perform
							the tests you have ordered.
						</li>
					</ol>
					<h2>How to pay</h2>
					<ol type="1" start={25}>
						<li>
							You may pay for Products using a debit or credit card and this includes MasterCard, Visa,
							Visa Electron, Switch, Solo, Maestro or JCB.
						</li>
					</ol>
					<h2>PRICE OF PRODUCTS</h2>
					<ol type="1" start={26}>
						<li>
							The price of the Products will be as quoted on our website at the time you submit your
							order.
						</li>
						<li>
							You will pay an additional charge if you choose any extra tests other than those initially
							requested prior to completing your appointment.
						</li>
						<li>
							Prices for our Products may change from time to time, but changes will not affect any order
							you have already placed.
						</li>
						<li>After your payment has been processed and will receive an appointment confirmation.</li>
					</ol>
					<h2>Samples and test results</h2>
					<ol type="1" start={30}>
						<li>
							Any sample you provide (whether it is blood, tissue, bodily fluid, or other biological
							sample) to one of our laboratories for analysis will be analysed only in relation to the
							test or tests that you have ordered.
						</li>
						<li>
							Certain tests require you to fast for a minimum period prior to collecting a sample, refrain
							from consuming alcohol beforehand, take your sample at a certain time of day, or prepare in
							other specified ways. We will provide you with clear instructions if you order a test with
							special sample requirements and it is imperative that you follow these instructions
							carefully to ensure reliable test results.
						</li>
						<li>
							Tests require a venous blood sample to be taken by an appropriately qualified health
							professional.
						</li>
						<li>
							Please ensure that you arrive for your appointments in good time. If you do not attend
							prebooked appointments, are late or cancel without reasonable notice you will be liable for
							cancellation charges and/or further costs to rebook your appointment.
						</li>
						<li>
							Occasionally the laboratories are unable to test samples sent to them. This may be because
							the amount collected is insufficient for full analysis, the sample has deteriorated or
							clotted or because it has haemolysed (where red blood cells damaged during sample collection
							leak their contents into the surrounding plasma). If the laboratory cannot test your sample,
							we will request you attend for a second appointment for another sample to be collected.
						</li>
						<li>
							In some cases samples may be stored by our laboratories for up to 4 weeks after the initial
							analysis has been carried out to give you the opportunity to order further tests or to
							repeat the analysis at an additional cost ("Storage Period"). Samples will be destroyed once
							the Storage Period has expired.
						</li>
					</ol>
					<h2>CANCELLATIONS AND REFUNDS</h2>
					<ol type="1" start={36}>
						<li>
							Your legal right to cancel an appointment starts from the date you receive the Appointment
							Confirmation (the date on which we e-mail or text you to confirm our acceptance of your
							order).
						</li>
						<li>
							To cancel an appointment, you must call our customer service team to arrange the
							cancellation on 0333 9000 010. Your reason for cancellation will not affect your right to
							cancel the order.
						</li>
						<li>
							Ryminster Healthcare Limited cannot offer refunds for cancellations made less than 24 hours
							before the scheduled appointment time.
						</li>
						<li>We will refund you on the credit card or debit card used by you to pay</li>
					</ol>
					<h2>OUR LIABILITY</h2>
					<ol start={40} type="1">
						<li>
							The service(s) you purchase from us are for private use only and you agree not to use the
							Products for any commercial purpose. We are not liable to you for any loss of profit, loss
							of business or revenue, business interruption or loss of business opportunity.
						</li>
						<li>
							Our total liability to you resulting from a Contract is limited to the total value of that
							Contract - meaning the price you paid for the Products and any additional services. In
							particular we are not liable for any costs, loss, delay, inconvenience or damage you suffer
							as a result of:
							<ol start={1} type="1">
								<li>The sample being lost, delayed or damaged in transit;</li>
								<li>The laboratory being unable to test your sample for any reason;</li>
								<li>
									Test results not being made available to you within the turnaround time stated for
									any product;
								</li>
								<li>
									Your failure to follow instructions provided to you in relation to sample collection
									or presample preparation requirements, such as fasting;
								</li>
								<li>
									Your failure to act upon our advice if we recommend that you seek medical advice or
									attention having taken a test;
								</li>
								<li>Your failure to show out-of-range test results to your gp or doctor;</li>
								<li>Your failure to attend a pre-booked appointment;</li>
								<li>Loss or damage that is not foreseeable;</li>
								<li>
									Events outside our control as described below.
									<ol start={1} type="1">
										<li>
											If we fail to comply with the Terms, we are responsible for loss or damage
											you suffer that is a foreseeable result of our breach of the Terms or our
											negligence. Loss or damage is foreseeable if it is an obvious consequence of
											our breach or if it was contemplated by you and us at the time we entered
											into this Contract.
										</li>
									</ol>
									<ol start={1} type="i">
										<li>
											It is your responsibility to ensure that the relevant sample has been
											collected.
										</li>
										<li>We do not in any way exclude or limit our liability for:</li>
										<li>death or personal injury caused by our negligence;</li>
										<li>fraud or fraudulent misrepresentation;</li>
										<li>
											any breach of the terms implied by section 12 of the Sale of Goods Act 1979
											(title and quiet possession) as amended by the Consumer Rights Act 2015;
										</li>
										<li>
											any breach of the terms implied by section 9 to 11 of the Consumer Rights
											Act 2015 (description, satisfactory quality, fitness for purpose and
											samples); and
										</li>
										<li>defective products under the Consumer Protection Act 1987.</li>
									</ol>
								</li>
							</ol>
						</li>
					</ol>
					<h2>EVENTS OUTSIDE OUR CONTROL</h2>
					<ol type="1" start={42}>
						<li>
							We will not be liable or responsible for any failure to perform, or delay in performance of,
							any of our obligations under a Contract that is caused by any act or event beyond our
							reasonable control (an "Event outside Our Control").
						</li>
						<li>
							If an Event Outside Our Control takes place that affects the performance of our obligations
							under a Contract: we will contact you as soon as reasonably possible to notify you; and our
							obligations under a Contract will be suspended and the time for performance of our
							obligations will be extended for the duration of the Event outside Our Control.
						</li>
						<li>
							You may cancel a Contract affected by an Event Outside Our Control which has continued for
							more than 30 days. To cancel, please contact us. If you opt to cancel, we will refund in
							full the total price you have paid for the Contract.
						</li>
					</ol>
					<h2>OUR RIGHT TO VARY THESE TERMS</h2>
					<ol type="1" start={45}>
						<li>We may amend the Terms from time to time.</li>
						<li>
							Every time you order a service from us, the Terms in force at the time of placing your order
							will apply to the Contract between you and us.
						</li>
						<li>
							If we have to revise the Terms as they apply to your order, we will contact you to give you
							reasonable advance notice of the changes and what orders will be affected. We will let you
							know how to cancel the Contract if you are not happy with the changes.
						</li>
					</ol>
					<h2>OTHER IMPORTANT TERMS</h2>
					<ol type="1" start={48}>
						<li>
							We may transfer our rights and obligations under a Contract to another organisation, but
							this will not affect your rights or our obligations under the Terms.
						</li>
						<li>
							This Contract is between you and us. No other person shall have any rights to enforce any of
							its terms, whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise.
						</li>
						<li>
							Each of the paragraphs of the Terms operates separately. If any court or relevant authority
							decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain
							in full force and effect.
						</li>
						<li>
							If we fail to insist that you perform any of your obligations under the Terms, or if we do
							not enforce our rights against you, or if we delay in doing so, that will not mean that we
							have waived our rights against you and will not mean that you do not have to comply with
							those obligations. If we do waive a default by you, we will only do so in writing, and that
							will not mean that we will automatically waive any later default by you.
						</li>
						<li>
							Please note that the Terms are governed by English law. This means a Contract for the
							purchase of Products through our site and any dispute or claim arising out of or in
							connection with it will be governed by English law. You and we both agree to that the courts
							of England and Wales will have exclusive jurisdiction.
						</li>
					</ol>
				</>
			)}
		</>
	);

	const renderTermsType2 = () => (
		<>
			<h6>Our terms</h6>
			<ol type="1">
				<li>
					<b>These terms</b>
					<ol type="1">
						<li>
							<b>What these terms cover.</b> These are the terms and conditions on which we supply goods
							to you.
						</li>
						<li>
							<b>Why you should read them.</b> Please read these terms carefully before you submit your
							order to us. These terms tell you who we are, how we will provide goods to you, how you and
							we may change or end the contract, what to do if there is a problem and other important
							information. If you think that there is a mistake in these terms please contact us to
							discuss.{" "}
						</li>
					</ol>
				</li>
				<li>
					<b>Information about us and how to contact us</b>
					<ol type="1">
						<li>
							<b>Who we are.</b> We are Ryminster Healthcare Limited trading as Summerfield Healthcare
							(company number 07844746) (we and us). Our company is registered in England and Wales and
							our registered office is at Building 1, Charlesworth Court, Knights Way, Shrewsbury, SY1
							3AB.{" "}
						</li>
						<li>
							<b>How to contact us.</b> You can contact us by telephoning our customer service team at
							0333 9000 010 or by writing to us at{" "}
							<a target="_blank" href="mailto:enquiries@summerfieldhealthcare.co.uk">
								enquiries@summerfieldhealthcare.co.uk
							</a>{" "}
							or{" "}
							<a target="_blank" href="mailto:covistesting@summerfieldhealthcare.co.uk">
								covistesting@summerfieldhealthcare.co.uk
							</a>{" "}
							or Building 1, Charlesworth Court, Knights Way, Shrewsbury, SY1 3AB.
						</li>
						<li>
							<b>How we may contact you.</b> If we have to contact you we will do so by telephone or by
							writing to you at the email address or postal address you provided to us in your order.{" "}
						</li>
						<li>
							<b>"Writing" includes emails.</b> When we use the words "writing" or "written" in these
							terms, this includes emails.
						</li>
					</ol>
				</li>
				<li>
					<b>Our contract with you</b>
					<ol type="1">
						<li>
							<b>How we will accept your order.</b> Our acceptance of your order will take place when we
							email you to accept it, at which point a contract will come into existence between you and
							us.
						</li>
						<li>
							<b>If we cannot accept your order.</b> If we are unable to accept your order, we will inform
							you of this and will not charge you for the goods. This might be because the goods are out
							of stock, because of unexpected limits on our resources which we could not reasonably plan
							for, because we have identified an error in the price or description of the goods or because
							we are unable to meet a delivery deadline you have specified.{" "}
						</li>
						<li>
							<b>Your customer reference number.</b> We will assign a customer reference number to your
							order and tell you what it is when we accept your order. It will help us if you can tell us
							the customer reference number whenever you contact us about your order.
						</li>
						<li>
							<b>We only sell to the UK.</b> Our website is solely for the promotion of our goods in the
							UK. Unfortunately, we do not accept orders from or deliver to addresses outside the UK.
						</li>
						<li>
							<b>Correcting input errors.</b> Our order process allows you to check and amend any errors
							before submitting your order to us. Please check the order carefully before confirming it.
							You are responsible for ensuring that your order is complete and accurate.
						</li>
					</ol>
				</li>
				<li>
					<b>Our goods</b>
					<ol type="1">
						<li>
							<b>Goods may vary slightly from their pictures.</b> The images of the goods on our website
							are for illustrative purposes only. Although we have made every effort to display the
							colours accurately, we cannot guarantee that a device's display of the colours accurately
							reflects the colour of the goods. Your goods may vary slightly from those images.{" "}
						</li>
						<li>
							<b>Product packaging may vary.</b> The packaging of the goods may vary from that shown in
							images on our website.{" "}
						</li>
					</ol>
				</li>
				<li>
					<b>Your rights to make changes</b>
					<br />
					If you wish to make a change to the goods you have ordered please contact us. We will let you know
					if the change is possible. If it is possible we will let you know about any changes to the price of
					the goods, the timing of supply or anything else which would be necessary as a result of your
					requested change and ask you to confirm whether you wish to go ahead with the change. If we cannot
					make the change or the consequences of making the change are unacceptable to you, you may want to
					end the contract.
				</li>
				<li>
					<b>Our rights to make changes</b>
					<ol type="1">
						<li>
							<b>Minor changes to the goods.</b> We may change the goods:
							<ol type="a">
								<li>to reflect changes in relevant laws and regulatory requirements; and </li>
								<li>
									to implement minor technical adjustments and improvements. These changes will not
									affect your use of the goods.
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					<b>Providing the goods</b>
					<ol type="1">
						<li>
							<b>Delivery costs.</b> The costs of delivery (where such will be charged) will be as
							displayed to you on our website.
						</li>
						<li>
							<b>When we will provide the goods.</b> During the order process we will let you know when we
							will provide the goods to you.{" "}
						</li>
						<li>
							<b>We are not responsible for delays outside our control.</b> If our supply of the goods is
							delayed by an event outside our control then we will contact you as soon as possible to let
							you know and we will take steps to minimise the effect of the delay. Provided we do this we
							will not be liable for delays caused by the event, but if there is a risk of substantial
							delay you may contact us to end the contract and receive a refund for any products you have
							paid for but not received.{" "}
						</li>
						<li>
							<b>Collection by you.</b> If you have asked to collect the goods from our premises, you can
							collect them from us at any time during our working hours of 08.00-18.00 on weekdays
							(excluding public holidays) and 10.00-14.30 on Saturdays.
						</li>
						<li>
							<b>If you are not at home when the goods are delivered.</b> If no one is available at your
							address to take delivery and the goods cannot be posted through your letterbox, we will
							leave you a note informing you of how to rearrange delivery or collect the goods from a
							local depot.
						</li>
						<li>
							<b>If you do not re-arrange delivery.</b> If you do not collect the goods from us as
							arranged or if, after a failed delivery to you, you do not re-arrange delivery or collect
							them from a delivery depot we will contact you for further instructions and may charge you
							for storage costs and any further delivery costs. If, despite our reasonable efforts, we are
							unable to contact you or re-arrange delivery or collection we may end the contract and
							clause 10.2 will apply.
						</li>
						<li>
							<b>Your legal rights if we deliver goods late.</b> You have legal rights if we deliver any
							goods late. If we miss the delivery deadline for any goods then you may treat the contract
							as at an end straight away if any of the following apply:
							<ol type="a">
								<li>we have refused to deliver the goods;</li>
								<li>
									delivery within the delivery deadline was essential (taking into account all the
									relevant circumstances); or
								</li>
								<li>
									you told us before we accepted your order that delivery within the delivery deadline
									was essential.
								</li>
							</ol>
						</li>
						<li>
							<b>Setting a new deadline for delivery.</b> If you do not wish to treat the contract as at
							an end straight away, or do not have the right to do so under clause 7.7, you can give us a
							new deadline for delivery, which must be reasonable, and you can treat the contract as at an
							end if we do not meet the new deadline.
						</li>
						<li>
							<b>Ending the contract for late delivery.</b> If you do choose to treat the contract as at
							an end for late delivery under clause 7.7 or clause 7.8, you can cancel your order for any
							of the goods or reject goods that have been delivered. If you wish, you can reject or cancel
							the order for some of those goods (not all of them), unless splitting them up would
							significantly reduce their value. After that we will refund any sums you have paid to us for
							the cancelled goods and their delivery. If the goods have been delivered to you, you must
							either return them in person to where you bought them, post them back to us or (if they are
							not suitable for posting) allow us to collect them from you. Please call customer services
							on 0333 9000 010 or email us at{" "}
							<a target="_blank" href="mailto:enquiries@summerfieldhealthcare.co.uk">
								enquiries@summerfieldhealthcare.co.uk
							</a>{" "}
							for a return label or to arrange collection.
						</li>
						<li>
							<b>When you become responsible for the goods.</b> The goods will be your responsibility from
							the time we deliver them to the address you gave us or you collect it from us.
						</li>
						<li>
							<b>When you own goods.</b> You own the goods once we have received payment in full.
						</li>
						<li>
							<b>What will happen if you do not give required information to us.</b> We may need certain
							information from you so that we can supply the goods to you. If so, this will have been
							stated in the description of the goods on our website. We will contact you to ask for this
							information. If you do not give us this information within a reasonable time of us asking
							for it, or if you give us incomplete or incorrect information, we may either end the
							contract (and clause 10.2 will apply) or make an additional charge of a reasonable sum to
							compensate us for any extra work that is required as a result. We will not be responsible
							for supplying the goods late or not supplying any part of them if this is caused by you not
							giving us the information we need within a reasonable time of us asking for it.{" "}
						</li>
					</ol>
				</li>
				<li>
					<b>Your rights to end the contract</b>
					<ol type="1">
						<li>
							<b>You can always end your contract with us.</b> Your rights when you end the contract will
							depend on what you have bought, whether there is anything wrong with it, how we are
							performing and when you decide to end the contract:
							<ol type="a">
								<li>
									<b>
										If what you have bought is faulty or misdescribed you may have a legal right to
										end the contract
									</b>{" "}
									(or to get the goods repaired), see clause 11;
								</li>
								<li>
									<b>
										If you want to end the contract because of something we have done or have told
										you we are going to do, see
									</b>{" "}
									clause 8.2;
								</li>
								<li>
									<b>If you have just changed your mind about the goods, see</b> clause 8.3. You may
									be able to get a refund if you are within the cooling-off period, but this may be
									subject to deductions and you will have to pay the costs of return of any goods;{" "}
								</li>
								<li>
									<b>
										In all other cases (if we are not at fault and there is no right to change your
										mind)
									</b>
									, see clause 8.6.
								</li>
							</ol>
						</li>
						<li>
							<b>Ending the contract because of something we have done or are going to do.</b> If you are
							ending a contract for a reason set out at (a) to (d) below the contract will end immediately
							and we will refund you in full for any goods which have not been provided and you may also
							be entitled to compensation. The reasons are:
							<ol type="a">
								<li>
									we have told you about an upcoming change to the goods or these terms which you do
									not agree to;
								</li>
								<li>
									we have told you about an error in the price or description of the goods you have
									ordered and you do not wish to proceed;
								</li>
								<li>
									there is a risk that supply of the goods may be significantly delayed because of
									events outside our control;{" "}
								</li>
								<li>
									you have a legal right to end the contract because of something we have done wrong.{" "}
								</li>
							</ol>
						</li>
						<li>
							<b>Exercising your right to change your mind (Consumer Contracts Regulations 2013)</b>. For
							most goods bought online you have a legal right to change your mind within 14 days and
							receive a refund. These rights, under the Consumer Contracts Regulations 2013, are explained
							in more detail in these terms.
						</li>
						<li>
							<b>When you don't have the right to change your mind.</b> You do not have a right to change
							your mind in respect of goods sealed for healthy protection or hygiene purposes, once these
							have been unsealed after you receive them.
						</li>
						<li>
							<b>How long do I have to change my mind?</b> You have 14 days after the day or someone you
							nominate receives the goods unless your goods are split into several deliveries over
							different days. In this case you have until 14 days after the day you or someone you
							nominate receives the last delivery to change your mind about the goods.
						</li>
						<li>
							<b>
								Ending the contract where we are not at fault and there is no right to change your mind.
							</b>{" "}
							Even if we are not at fault and you do not have a right to change your mind (see clause
							8.1), you can still end the contract before it is completed, but you may have to pay us
							compensation. A contract for goods is completed when the goods are delivered and paid for.
							If you want to end a contract before it is completed where we are not at fault and you have
							not changed your mind, just contact us to let us know. The contract will end immediately and
							we will refund any sums paid by you for goods not provided but we may deduct from that
							refund (or, if you have not made an advance payment, charge you) reasonable compensation for
							the net costs we will incur as a result of your ending the contract.
						</li>
					</ol>
				</li>
				<li>
					<b>How to end the contract with us (including if you have changed your mind)</b>
					<ol type="1">
						<li>
							<b>Tell us you want to end the contract.</b> To end the contract with us, please let us know
							by doing one of the following:
							<ol type="a">
								<li>
									<b>Phone or email.</b> Call customer services on 0333 9000 010 or email us at
									<a target="_blank" href="mailto:enquiries@summerfieldhealthcare.co.uk">
										enquiries@summerfieldhealthcare.co.uk
									</a>
									. Please provide your name, home address, details of the order and, where available,
									your phone number and email address.{" "}
								</li>
								<li>
									<b>By post.</b> Write to us at Building 1, Charlesworth Court, Knights Way,
									Shrewsbury, SY1 3AB, including details of what you bought, when you ordered or
									received it and your name and address.{" "}
								</li>
							</ol>
						</li>
						<li>
							<b>Returning goods after ending the contract.</b> If you end the contract for any reason
							after goods have been dispatched to you or you have received them, you must return them to
							us. You must post them back to us at Building 1, Charlesworth Court, Knights Way,
							Shrewsbury, SY1 3AB or (if they are not suitable for posting) allow us to collect them from
							you. Please call customer services on 0333 9000 010 or email us at
							<a target="_blank" href="mailto:enquiries@summerfieldhealthcare.co.uk">
								enquiries@summerfieldhealthcare.co.uk
							</a>{" "}
							for a return label or to arrange collection. If you are exercising your right to change your
							mind you must send off the goods within 14 days of telling us you wish to end the contract.{" "}
						</li>
						<li>
							<b>When we will pay the costs of return.</b> We will pay the costs of return:
							<ol type="a">
								<li>if the goods are faulty or misdescribed;</li>
								<li>
									(b) if you are ending the contract because we have told you of an upcoming change to
									the goods or these terms, an error in pricing or description, a delay in delivery
									due to events outside our control or because you have a legal right to do so as a
									result of something we have done wrong; or
								</li>
							</ol>
							In all other circumstances (including where you are exercising your right to change your
							mind) you must pay the costs of return.
						</li>
						<li>
							<b>What we charge for collection.</b> If you are responsible for the costs of return and we
							are collecting the goods from you, we will charge you the direct cost to us of collection.{" "}
						</li>
						<li>
							<b>How we will refund you.</b> We will refund you the price you paid for the goods including
							delivery costs, by the method you used for payment. However, we may make deductions from the
							price, as described below.
						</li>
						<li>
							<b>Deductions from refunds if you are exercising your right to change your mind.</b> If you
							are exercising your right to change your mind:
							<ol type="a">
								<li>
									We may reduce your refund of the price (excluding delivery costs) to reflect any
									reduction in the value of the goods, if this has been caused by your handling them
									in a way which would not be permitted in a shop. If we refund you the price paid
									before we are able to inspect the goods and later discover you have handled them in
									an unacceptable way, you must pay us an appropriate amount.{" "}
								</li>
								<li>
									The maximum refund for delivery costs will be the costs of delivery by the least
									expensive delivery method we offer. For example, if we offer delivery of a product
									within 3-5 days at one cost but you choose to have the goods delivered within 24
									hours at a higher cost, then we will only refund what you would have paid for the
									cheaper delivery option.
								</li>
							</ol>
						</li>
						<li>
							<b>When your refund will be made.</b> We will make any refunds due to you as soon as
							possible. If you are exercising your right to change your mind:
							<ol type="a">
								<li>
									and we have not offered to collect the goods from you, your refund will be made
									within 14 days from the day on which we receive the goods back from you or, if
									earlier, the day on which you provide us with evidence that you have sent the goods
									back to us.{" "}
								</li>
								<li>
									In all other cases, your refund will be made within 14 days of your telling us you
									have changed your mind
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					<b>Our rights to end the contract</b>
					<ol type="1">
						<li>
							<b>We may end the contract if you break it.</b> We may end the contract at any time by
							writing to you if:
							<ol type="a">
								<li>
									you do not make any payment to us when it is due and you still do not make payment
									within 7 days of us reminding you that payment is due;
								</li>
								<li>
									you do not, within a reasonable time of us asking for it, provide us with
									information that is necessary for us to provide the goods; or
								</li>
								<li>
									you do not, within a reasonable time, allow us to deliver the goods to you or
									collect them from us.
								</li>
							</ol>
						</li>
						<li>
							<b>You must compensate us if you break the contract.</b> If we end the contract in the
							situations set out in clause 10.1 we will refund any money you have paid in advance for
							goods we have not provided but we may deduct or charge you reasonable compensation for the
							net costs we will incur as a result of your breaking the contract.
						</li>
					</ol>
				</li>
				<li>
					<b>If there is a problem with the product</b>
					<ol type="1">
						<li>
							<b>How to tell us about problems.</b> If you have any questions or complaints about the
							goods, please contact us.
						</li>
						<li>
							<b>Summary of your legal rights.</b> We are under a legal duty to supply goods that are in
							conformity with this contract. Nothing in these terms will affect your legal rights.
						</li>
						<li>
							<b>Your obligation to return rejected goods.</b> If you wish to exercise your legal rights
							to reject goods you must either post them back to us or (if they are not suitable for
							posting) allow us to collect them from you. We will pay the costs of postage or collection.{" "}
						</li>
					</ol>
				</li>
				<li>
					<b>Price and payment</b>
					<ol type="1">
						<li>
							<b>Where to find the price for the goods</b> The price of the goods (which includes VAT)
							will be the price indicated on the order pages when you placed your order. We take all
							reasonable care to ensure that the price of the product advised to you is correct. However
							please see clause 12.3 for what happens if we discover an error in the price of the goods
							you order.{" "}
						</li>
						<li>
							<b>We will pass on changes in the rate of VAT.</b> If the rate of VAT changes between your
							order date and the date we supply the goods, we will adjust the rate of VAT that you pay,
							unless you have already paid for the goods in full before the change in the rate of VAT
							takes effect.
						</li>
						<li>
							<b>What happens if we got the price wrong.</b> It is always possible that, despite our best
							efforts, some of the goods we sell may be incorrectly priced. We will normally check prices
							before accepting your order so that, where the product's correct price at your order date is
							less than our stated price at your order date, we will charge the lower amount. If the
							correct price at your order date is higher than the price stated to you, we will contact you
							for your instructions before we accept your order. If we accept and process your order where
							a pricing error is obvious and unmistakeable and could reasonably have been recognised by
							you as a mispricing, we may end the contract, refund you any sums you have paid and require
							the return of any goods provided to you.
						</li>
						<li>
							<b>When you must pay and how you must pay.</b> We accept payment with MasterCard, Visa, Visa
							Electron, Switch, Solo, Maestro or JCB. You must pay for the goods before we dispatch them.
							We will not charge your credit or debit card until we dispatch the goods to you.
						</li>
						<li>
							<b>We can charge interest if you pay late.</b> If you do not make any payment to us by the
							due date we may charge interest to you on the overdue amount at the rate of 2% a year above
							the base lending rate of HSBC bank from time to time. This interest shall accrue on a daily
							basis from the due date until the date of actual payment of the overdue amount, whether
							before or after judgment. You must pay us interest together with any overdue amount.{" "}
						</li>
						<li>
							<b>What to do if you think an invoice is wrong.</b> If you think an invoice is wrong please
							contact us promptly to let us know. You will not have to pay any interest until the dispute
							is resolved. Once the dispute is resolved we will charge you interest on correctly invoiced
							sums from the original due date. In the event that you claim a chargeback with your bank and
							one is granted, we reserve the right to dispute the chargeback and pursue a claim for the
							disputed sum.
						</li>
					</ol>
				</li>
				<li>
					<b>Our responsibility for loss or damage suffered by you</b>
					<ol type="1">
						<li>
							<b>We are responsible to you for foreseeable loss and damage caused by us.</b> If we fail to
							comply with these terms, we are responsible for loss or damage you suffer that is a
							foreseeable result of our breaking this contract or our failing to use reasonable care and
							skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or
							damage is foreseeable if either it is obvious that it will happen or if, at the time the
							contract was made, both we and you knew it might happen, for example, if you discussed it
							with us during the sales process.
						</li>
						<li>
							<b>
								We do not exclude or limit in any way our liability to you where it would be unlawful to
								do so.
							</b>{" "}
							This includes liability for death or personal injury caused by our negligence or the
							negligence of our employees, agents or subcontractors; for fraud or fraudulent
							misrepresentation; for breach of your legal rights in relation to the goods; and for
							defective products under the Consumer Protection Act 1987
						</li>
						<li>
							<b>We are not liable for business losses.</b> We only supply the goods for domestic and
							private use. If you use the goods for any commercial, business or re-sale purpose we will
							have no liability to you for any loss of profit, loss of business, business interruption, or
							loss of business opportunity.
						</li>
					</ol>
				</li>
				<li>
					<b>How we may use your personal information</b>
					<ol type="1">
						<li>
							<b>How we may use your personal information.</b> We will only use your personal information
							as set out in our privacy policy found at
							<a target="_blank" href="https://www.summerfieldhealthcare.co.uk/privacy-policy">
								https://www.summerfieldhealthcare.co.uk/privacy-policy
							</a>
							.
						</li>
					</ol>
				</li>
				<li>
					<b>Other important terms</b>
					<ol type="1">
						<li>
							<b>We may transfer this agreement to someone else.</b> We may transfer our rights and
							obligations under these terms to another organisation.{" "}
						</li>
						<li>
							<b>You need our consent to transfer your rights to someone else.</b> You may only transfer
							your rights or your obligations under these terms to another person if we agree to this in
							writing.{" "}
						</li>
						<li>
							<b>Nobody else has any rights under this contract.</b> This contract is between you and us.
							No other person shall have any rights to enforce any of its terms. Neither of us will need
							to get the agreement of any other person in order to end the contract or make any changes to
							these terms.
						</li>
						<li>
							<b>If a court finds part of this contract illegal, the rest will continue in force.</b> Each
							of the paragraphs of these terms operates separately. If any court or relevant authority
							decides that any of them are unlawful, the remaining paragraphs will remain in full force
							and effect.
						</li>
						<li>
							<b>Even if we delay in enforcing this contract, we can still enforce it later.</b> If we do
							not insist immediately that you do anything you are required to do under these terms, or if
							we delay in taking steps against you in respect of your breaking this contract, that will
							not mean that you do not have to do those things and it will not prevent us taking steps
							against you at a later date. For example, if you miss a payment and we do not chase you but
							we continue to provide the goods, we can still require you to make the payment at a later
							date.
						</li>
						<li>
							<b>Which laws apply to this contract and where you may bring legal proceedings.</b> These
							terms are governed by English law and you can bring legal proceedings in respect of the
							products in the English courts. If you live in Scotland you can bring legal proceedings in
							respect of the products in either the Scottish or the English courts. If you live in
							Northern Ireland you can bring legal proceedings in respect of the products in either the
							Northern Irish or the English courts.
						</li>
					</ol>
				</li>
			</ol>
		</>
	);

	return (
		<>
			<div className="terms">{props.type2 ? renderTermsType2() : renderTermsType1()}</div>
		</>
	);
};
