import React, { useContext, useState, useEffect, useRef } from "react";

import { StoresContext, Stores, StoresInstance } from "../../Stores";
/* import { ApiResult } from "../../Shoothill.Components/Models"; */
import { /* MenuItem, */ Snackbar, Fade, useMediaQuery, Button } from "@material-ui/core";

import { AppointmentUL, AppointmentLI /* , AmendBookingBTN */ } from "../StylesAppSpecific/AppointmentsStyling";
import { GridSpaced, PlaceHolderHR } from "../StylesAppSpecific/Customer";
import { TimeSlot } from "../StylesAppSpecific/ClinicStyling";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useObserver } from "mobx-react-lite";
/* import { useDataApi, dateTimeString } from "Shoothill.Core/Utils"; */
import { autorun, /* observe, */ runInAction } from "mobx";
/* import { PWEnquiry } from "Models/Domain"; */
import { FormatMomentString } from "../../Shoothill.Core/Utils/Format/Format";
/* import { nl } from "date-fns/esm/locale";
import { MapStore } from "Stores/Domain/MapStore"; */
import { DomainStores } from "../../Stores/DomainStores";
import EnquiryViewModel from "../../ViewModels/EnquiryViewModel";
import useGRecaptcha from "Shoothill.Core/Utils/Hooks/UseGRecaptcha";

interface IProps {
	on_got_time?: () => void;
}

interface IClinicProps extends IProps {
	show_back?: boolean;
	show_timeslots?: boolean;
	clinic_ref: string;
	class_name: string;
	on_appointment_click?: () => void;
	on_back?: () => void;
	time?: string | Date;
	on_time_hide?: () => void;
	errored_time?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	grecaptcha_executing?: () => void;
	multipleProduct?: boolean;
}

const enquiryViewModel = new EnquiryViewModel();

export const backArrow = (
	<svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" className={"backBtn"}>
		<path
			d="M7.293.707a1,1,0,0,1,1.414,0l5.586,5.586A1,1,0,0,1,13.586,8H2.414a1,1,0,0,1-.707-1.707Z"
			transform="translate(0 16) rotate(-90)"
			fill="#337ab7"
		/>
	</svg>
);

export const Clinic: React.FC<IClinicProps> = props => {
	const matches = useMediaQuery("(min-width:1024px)");
	const {
		show_timeslots: showTimeslots,
		clinic_ref: clinicRef,
		class_name: className,
		on_appointment_click: handleMyAppointmentClick,
		show_back: showBack,
		on_back: onBack,
		time,
		on_time_hide: onTimeHide,
		errored_time: erroredTimeObj,
		grecaptcha_executing: grecaptchaExecuting,
		multipleProduct,
	} = props;

	const domainStores: DomainStores = useContext<Stores>(StoresContext).domain;

	const [thisClinic, setThisClinic] = useState<Summerfield.Data.apmClinic>();
	const [erroredTime, setErroredTime] = erroredTimeObj || [];
	const lastSelected = useRef<number>(-1);

	useEffect(() => {
		const disposer = autorun(() => {
			setThisClinic(StoresInstance.domain.ClinicStore.availableClinic.find(cli => cli.reference == clinicRef));
		});

		return () => disposer();
	}, []);

	const onTimeClick = async (i: number) => {
		domainStores.EnquiryStore.enquiry.timeClinic.setStartTime(thisClinic!.timeSlots![i]!.startTime!);
		lastSelected.current = i;
		//grecaptcha.execute();
		//(window as any).reCaptchaV2Callback("ok");
		grecaptchaExecuting!();
	};

	return useObserver(() =>
		thisClinic || multipleProduct ? (
			<>
				<AppointmentLI
					margin={"0px 0px 20px"}
					padding={"0px"}
					additionalcss={"width: 100%;"}
					className={className}
					key={multipleProduct ? "item_prod" : "item_" + thisClinic!.clinicDate!.toString()}
					id={multipleProduct ? "item_prod" : "item_" + thisClinic!.clinicDate!.toString()}
				>
					<GridSpaced container margin={"0"} padding={"9px"} onClick={handleMyAppointmentClick}>
						<GridSpaced item xs={12} sm={12} md={8}>
							<div className={"appointment"}>
								{multipleProduct ? (
									"Quantity"
								) : (
									<>
										{thisClinic!.distanceMiles!.toString()} miles from{" "}
										{domainStores.MapStore.location !== undefined
											? domainStores.MapStore.location.text
											: ""}
									</>
								)}
							</div>
							<div className={"location"}>
								{multipleProduct ? (
									<>
										{StoresInstance.domain.EnquiryStore.boxSize! *
											StoresInstance.domain.EnquiryStore.boxes!}{" "}
										{
											StoresInstance.domain.EnquiryStore.enquiry.appointmentType!
												.appointmentTypeName
										}{" "}
										({StoresInstance.domain.EnquiryStore.boxes} box
										{StoresInstance.domain.EnquiryStore.boxes! > 1 ? "es" : ""})
									</>
								) : (
									!!thisClinic!.venue!.address && thisClinic!.venue!.address.addressLine1
								)}
							</div>
						</GridSpaced>
						<GridSpaced item xs={12} sm={12} md={4} additionalcss={"text-align: right;"}>
							{multipleProduct ? (
								<>
									<div>&nbsp;</div>
									<div className={"appointment"}>
										Total cost: £{StoresInstance.domain.EnquiryStore.multiplePrice.toFixed(2)} Inc.
										VAT
									</div>
								</>
							) : (
								<>
									<div className={"appointment"}>
										{FormatMomentString(thisClinic!.clinicDate! as string, "dddd Do MMM YYYY")}
										{time !== undefined && ` at ${FormatMomentString(time.toString(), "HH:mm")}`}
									</div>
									<div className={"location"}>
										{thisClinic!.practitioner!.title} {thisClinic!.practitioner!.firstName}{" "}
										{thisClinic!.practitioner!.lastName}
									</div>
								</>
							)}
						</GridSpaced>
					</GridSpaced>
					{(showBack || showTimeslots) && (
						<>
							<PlaceHolderHR additioncss={"border-top: #f4f6f9"} margin={"0px"} />
							<GridSpaced container margin={"0px"} padding={"25px 20px"} className={"times"}>
								{showBack && (
									<>
										<GridSpaced item key="back">
											<TimeSlot margin={"0px 25px 0px 0px"} fontsize={"17px"} onClick={onBack}>
												{backArrow}
												Back
											</TimeSlot>
										</GridSpaced>
									</>
								)}
								{showTimeslots && (
									<>
										{thisClinic!.timeSlots!.map((timeSlot, index1) => (
											<Fade
												in={index1 !== lastSelected.current || !erroredTime}
												unmountOnExit
												timeout={500}
												onExited={() => {
													onTimeHide!();
													setErroredTime!(false);
												}}
											>
												<GridSpaced
													item
													key={`timeslot_${index1}`}
													additionalcss={matches ? "width: 128px;" : "width: 50%;"}
													margin={"0px 0px 15px"}
												>
													<TimeSlot
														margin={matches ? "0px 25px 0px 0px" : "0px 12px"}
														fontsize={"17px"}
														onClick={() => onTimeClick!(index1)}
														key={
															"slot_" +
															FormatMomentString(timeSlot.startTime as string, "HHmm")
														}
														style={
															index1 === lastSelected.current && erroredTime
																? { backgroundColor: "#E64947" }
																: {}
														}
													>
														<AccessTimeIcon />
														{FormatMomentString(timeSlot.startTime as string, "HH:mm")}
													</TimeSlot>
												</GridSpaced>
											</Fade>
										))}
									</>
								)}
							</GridSpaced>
						</>
					)}
				</AppointmentLI>
			</>
		) : null,
	);
};

export const AvailableClinics: React.FC<IProps> = props => {
	const storesContext = useContext<Stores>(StoresContext);
	const [theseClinics, setTheseClinics] = useState<Summerfield.Data.apmClinic[]>();
	const errorMessage = useRef<string>("");
	const [errorOpen, setErrorOpen] = useState<boolean>(false);
	const snackbarTimer = useRef<number>(-1);
	const erroredTimeObj = useState<boolean>(true);
	const [, setErroredTime] = erroredTimeObj;
	const [isMore, setIsMore] = useState(false);
	const startIndex = useRef(0);
	const [isLoading, setLoading] = useState(false);

	const executeCaptcha = useGRecaptcha(async token => {
		let apiResult = await enquiryViewModel.reserveAppointment(token);
		if (!apiResult.wasSuccessful) {
			errorMessage.current = apiResult.errors[0].message;
			if (snackbarTimer.current >= 0) {
				window.clearTimeout(snackbarTimer.current);
			}
			setErrorOpen(true);
			setErroredTime(true);
			snackbarTimer.current = window.setTimeout(() => {
				setErrorOpen(false);
				snackbarTimer.current = -1;
			}, 6000);
		} else {
			onGotTime!();
		}
	});

	const { on_got_time: onGotTime } = props;

	useEffect(() => {
		return () => {
			if (snackbarTimer.current >= 0) {
				window.clearTimeout(snackbarTimer.current);
			}
		};
	}, []);

	const fetchAvailableClinics = (start?: number) => {
		if (storesContext.domain.EnquiryStore.enquiry.isReadyForClinics) {
			setLoading(true);
			let promise = storesContext.domain.ClinicStore.GetAvailableClinics(
				storesContext.domain.EnquiryStore.enquiry.toAvailableClinicsDto(),
				start,
			)
				.then((result?: { clinics: any[]; isMore: boolean }) => {
					setLoading(false);
					if (result) {
						if (result.clinics.length > 0 || !start) {
							if (start) {
								setTheseClinics([...(theseClinics || []), ...result.clinics]);
								runInAction(() => {
									storesContext.domain.ClinicStore.availableClinic.push(...result.clinics);
								});
							} else {
								setTheseClinics(result.clinics);
								runInAction(() => {
									storesContext.domain.ClinicStore.availableClinic.replace(result.clinics);
								});
							}
						} else {
							setTheseClinics(undefined);
							storesContext.domain.ClinicStore.ResetAvailableClinics();
						}
						setIsMore(result.isMore);
					}

					if (!start) {
						startIndex.current = 0;
					}
				})
				.catch(() => setLoading(false));
		}
	};

	useEffect(() => {
		const disposer = autorun(() => {
			fetchAvailableClinics();
		});

		return () => disposer();
	}, []);

	const handleMyAppointmentClick = (ref: string): void => {
		storesContext.domain.EnquiryStore.enquiry.timeClinic.setClinicReference(ref);
	};

	const handleFetchMoreClinics = () => {
		startIndex.current++;
		fetchAvailableClinics(startIndex.current * 10);
	};

	return useObserver(() => (
		<>
			<div className="customer-myappointments">
				<Snackbar
					open={errorOpen}
					message={errorMessage.current}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				/>
				<div className="myappointments-header"></div>
				{theseClinics !== undefined && (
					<>
						<AppointmentUL margin={"0px"} padding={"0px"}>
							{theseClinics.map(thisClinic => (
								<React.Fragment key={`c-${thisClinic.reference}`}>
									<Clinic
										show_timeslots={
											!!thisClinic.timeSlots &&
											storesContext.domain.EnquiryStore.enquiry.timeClinic.clinicReference ===
												thisClinic.reference
										}
										class_name={
											storesContext.domain.EnquiryStore.enquiry.timeClinic.clinicReference ===
											thisClinic.reference
												? "highlighted"
												: ""
										}
										clinic_ref={thisClinic.reference!}
										on_appointment_click={() => handleMyAppointmentClick(thisClinic.reference!)}
										on_time_hide={fetchAvailableClinics}
										grecaptcha_executing={executeCaptcha}
										errored_time={erroredTimeObj}
									/>
								</React.Fragment>
							))}
						</AppointmentUL>
						{isMore && (
							<Button
								variant="contained"
								color="primary"
								className="submit"
								onClick={handleFetchMoreClinics}
								fullWidth
								style={{ maxWidth: "initial" }}
								disabled={isLoading}
							>
								View more appointments
							</Button>
						)}
					</>
				)}

				{theseClinics === undefined && (
					<>
						<AppointmentUL margin={"0px"} padding={"0px"}>
							<AppointmentLI margin={"0px 0px 20px"} padding={"9px"} additionalcss={"width: 100%;"}>
								Currently no Clinics available ...
							</AppointmentLI>
						</AppointmentUL>
					</>
				)}
			</div>
		</>
	));
};
