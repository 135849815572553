/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Stores } from "../Stores";
// Libs

// App
import { observable, computed, action } from "mobx";

export class BaseStore {
	public stores: Stores | null | undefined;
	@observable protected isLoadingCount = 0;
	@computed public get isLoading() {
		return this.isLoadingCount > 0;
	}
	public constructor(stores?: Stores) {
		if (stores) this.stores = stores;
	}

	@action protected setIsLoading = () => this.isLoadingCount++;
	@action protected unsetIsLoading = () => this.isLoadingCount--;
}
