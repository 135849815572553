import { observable, computed, action } from "mobx";

export default class BaseViewModel {
	@observable public IsLoading = false;
	@observable public IsErrored = false;
	@observable public Errors: string[] = [];

	@action protected setIsLoading = (state: boolean) => (this.IsLoading = state);
	@action protected setIsErrored = (state: boolean) => (this.IsErrored = state);
	@action protected setErrors = (state: string[]) => (this.Errors = state);
	constructor() {}
}
