import * as MobX from "mobx";

export class CovidTestAndReleaseDatesModel {
	@MobX.observable
	public arrival: Date | null = null;

	@MobX.observable
	public departure: Date | null = null;

	@MobX.action
	public setArrival(e: Date | null) {
		this.arrival = e;
	}

	@MobX.action
	public setDeparture(e: Date | null) {
		this.departure = e;
	}

	@MobX.computed
	public get isDepartureValid() {
		if (this.departure !== null) {
			if (!isNaN(this.departure.getTime())) {
				return true;
			}
		}
		return false;
	}

	@MobX.computed
	public get isArrivalValid() {
		if (this.arrival !== null) {
			if (!isNaN(this.arrival.getTime())) {
				return true;
			}
		}
		return false;
	}
}

export class CovidTestAndReleaseModel extends CovidTestAndReleaseDatesModel {
	public forMe?: boolean = undefined;
	public sex?: "male" | "female" = undefined;
	public ethnicity = "";
	public nhsNumber = "";
	public coachNumber = "";
	public priorToArrival = "";
	public customEthnicity = "";
	public vaccinationStatus = "";

	public constructor(vaccinationStatus?: string) {
		super();

		if (vaccinationStatus) {
			this.vaccinationStatus = vaccinationStatus;
		}
	}
}
