import { AccountStore } from "./Domain/AccountStores";
import { AppointmentTypesStore } from "./Domain/AppointmentTypesStore";
import { ClinicStore } from "./Domain/ClinicStore";
import { CustomerStore } from "./Domain/CustomerStore";
import { EnquiryStore } from "./Domain/EnquiryStore";
import { LocationSearchStore } from "./Domain/LocationSearchStore";
import { UserStore } from "./Domain/Admin/UserStore";
import { SourceOfInterestStore } from "./Domain/SourceOfInterestStore";
import { Stores } from "./Stores";
import { MapStore } from "./Domain/MapStore";
// App
import * as Models from "../Models";

export class DomainStores {
	public AccountStore: AccountStore = new AccountStore();
	public AppointmentTypesStore: AppointmentTypesStore = new AppointmentTypesStore();
	public ClinicStore: ClinicStore = new ClinicStore();
	public CustomerStore: CustomerStore = new CustomerStore();
	public EnquiryStore: EnquiryStore = new EnquiryStore();
	//public LocationSearchStore: LocationSearchStore = new LocationSearchStore();
	public SourceOfInterestStore: SourceOfInterestStore = new SourceOfInterestStore();
	public UserStore = new UserStore();
	public MapStore = new MapStore();

	public init(stores: Stores, initialState: Models.InitialState) {
		this.AccountStore.init(stores, initialState);
		this.ClinicStore.init(stores, initialState);
		this.CustomerStore.init(stores, initialState);
		this.EnquiryStore.init(stores, initialState);
		//this.LocationSearchStore.init(stores, initialState);
		this.SourceOfInterestStore.init(stores, initialState);
		this.AppointmentTypesStore.init(stores, initialState);
		this.MapStore.init(stores);
	}
}
