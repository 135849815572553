// Libs
import * as MobX from "mobx";

// App

export class Customer {
	public id: number = -1;
	@MobX.observable public firstName: string = "";
	@MobX.observable public lastName: string = "";

	@MobX.observable public dateOfBirth: Date | null = null /* new Date() */;

	// Contact Details
	@MobX.observable public email: string = "";
	@MobX.observable public mobileNumber: string = "";
	@MobX.observable public number: string = "";

	@MobX.observable public companyName: string = "";

	// Address Object
	@MobX.observable public addressLine1: string = "";
	@MobX.observable public addressLine2: string = "";
	@MobX.observable public addressLine3: string = "";

	@MobX.observable public county: string = "";
	@MobX.observable public postcode: string = "";
	@MobX.observable public townOrCity: string = "";

	@MobX.observable public sourceOfInterest: string = "";
	@MobX.observable public emailConsent: boolean = false;

	@MobX.observable public shareResultsWithEmployer = false;
	@MobX.observable public isEmployee = false;

	private dto: Summerfield.Data.customer | undefined = undefined;

	constructor(id?: number) {
		if (id) {
			this.id = id;
		}
	}

	@MobX.action
	public fromDto = (model: Summerfield.Data.customer) => {
		this.dto = model;
		this.id = model.id!;
		this.firstName = model.firstName!;
		this.lastName = model.lastName!;

		let dob = model.dateOfBirth!;
		if (typeof dob === "string") dob += "Z"; // Dates from the server are in UTC, even though they're sent without the ISO 'Z' to indicate this.  Without adding this Z, you lose 1 hour every time the date is changed during BST
		this.dateOfBirth = new Date(dob);

		this.companyName = model.companyName!;

		// Sort out the contact details
		this.email = model.contactDetails !== undefined ? model.contactDetails!.emailAddress! : "";
		this.mobileNumber = model.contactDetails !== undefined ? model.contactDetails!.mobileNumber! : "";
		this.number = model.contactDetails !== undefined ? model.contactDetails!.number! : "";

		// Sort out the address
		this.addressLine1 = model.address !== undefined ? model.address!.addressLine1! : "";
		this.addressLine2 = model.address !== undefined ? model.address!.addressLine2! : "";
		this.addressLine2 = model.address !== undefined ? model.address!.addressLine2! : "";

		this.county = model.address !== undefined ? model.address!.county! : "";
		this.postcode = model.address !== undefined ? model.address!.postcode! : "";
		this.townOrCity = model.address !== undefined ? model.address!.townOrCity! : "";

		this.isEmployee =
			model.employeeSettings !== undefined && model.employeeSettings !== null
				? model.employeeSettings.isEmployee
				: false;
		this.shareResultsWithEmployer =
			model.employeeSettings !== undefined && model.employeeSettings !== null
				? model.employeeSettings.shareResultsWithEmployer
				: false;
	};

	@MobX.action
	public toDto = (): Summerfield.Data.customer => {
		return Customer.toDto(this);
	};

	public static toDto = (customer: Customer): Summerfield.Data.customer => {
		return {
			firstName: customer.firstName,
			lastName: customer.lastName,
			dateOfBirth: customer.dateOfBirth!,
			companyName: customer.companyName,
			contactDetails: {
				emailAddress: customer.email,
				mobileNumber: customer.mobileNumber,
				number: customer.number,
			},
			address: {
				addressLine1: customer.addressLine1,
				addressLine2: customer.addressLine2,
				addressLine3: customer.addressLine3,
				townOrCity: customer.townOrCity,
				county: customer.county,
				postcode: customer.postcode,
			},
			emailConsentAndSoI: {
				emailConsent: customer.emailConsent,
				sourceOfInterest: customer.sourceOfInterest,
			},
			employeeSettings: {
				isEmployee: customer.isEmployee,
				shareResultsWithEmployer: customer.shareResultsWithEmployer,
			},
		};
	};

	@MobX.computed
	public get isValid() {
		let retVal: boolean = true;

		// eslint-disable-next-line prettier/prettier
		if (
			this.id !== -1 &&
			// eslint-disable-next-line prettier/prettier
			this.firstName.length > 0 &&
			// eslint-disable-next-line prettier/prettier
			this.lastName.length > 0 &&
			// eslint-disable-next-line prettier/prettier
			this.email.length > 0 &&
			// eslint-disable-next-line prettier/prettier
			this.mobileNumber.length > 0 &&
			// eslint-disable-next-line prettier/prettier
			this.addressLine1.length > 0 &&
			// eslint-disable-next-line prettier/prettier
			this.townOrCity.length > 0 &&
			// eslint-disable-next-line prettier/prettier
			this.postcode.length > 0
		) {
			return true;
		}
		return retVal;
	}

	@MobX.action
	public setSoI(soi: string) {
		this.sourceOfInterest = soi;
	}

	@MobX.action
	public setEmailConsent(consent: boolean) {
		this.emailConsent = consent;
	}
}
