import React, { useState, useContext /* , useEffect, useRef */ } from "react";
/* import * as Autosuggest from "react-autosuggest"; */
import { useObserver } from "mobx-react-lite";

import {
	/* Select, MenuItem,  */ Grid,
	InputLabel /* , TextField */,
	Button,
	Typography,
	Box,
} from "@material-ui/core";
import { GridSpaced } from "../../Shoothill.Components/StylesAppSpecific/Customer";
import { AutoSuggestDiv, AltSelect } from "../../Shoothill.Components/StylesAppSpecific/LocationSearchStyling";

import { locationSearchFormModel } from "../../Models/FormModels/locationSearchFormModel";
import { Stores, StoresContext } from "Stores";
import { AddressSelectorGoogle } from "Shoothill.Components/Forms/AddressSelectorGoogle";
import { StoresInstance } from "../../Stores/Stores";
import { useMediaQuery } from "Shoothill.Core/Utils/Media/mediaQuery";
interface IProps {
	onSubmit: () => void;
}

export const LocationSearch: React.FC<IProps> = props => {
	const { onSubmit } = props;

	const domainStore = useContext<Stores>(StoresContext).domain;

	const [toggleView, setToggleView] = useState<boolean>(domainStore.MapStore.hasAddress);

	const [showToContinue, setShowToContinue] = useState(false);

	const isMobile = !!useMediaQuery("(max-width: 767px)");

	const doSubmit = () => {
		setToggleView(true);
		onChange();
	};

	const onChange = (dist?: number) => {
		let submitVal: locationSearchFormModel = {
			location: domainStore.MapStore.address,
			distance:
				dist !== undefined ? dist : domainStore.EnquiryStore.enquiry.enquiryLocation.travelDistanceMiles || 0,
			latitude: domainStore.MapStore.latitude,
			longitude: domainStore.MapStore.longitude,
		};
		domainStore.EnquiryStore.enquiry.enquiryLocation.setLocation(submitVal);
		onSubmit();
	};

	const handleDistanceChange = (e: any) => {
		let chosenType = e.target.value as string;
		onChange(parseInt(chosenType, 10));
	};

	const handleEditLocation = (e: any) => {
		setToggleView(false);
	};

	return useObserver(() => (
		<>
			<GridSpaced container spacing={2} additionalcss={"margin-bottom: 25px;"} className={"formRow"}>
				<Grid item xs={12} sm={12} md={12}>
					<InputLabel>How far are you willing to travel?</InputLabel>
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					<AltSelect
						native
						className={"distance border-correct"}
						name={"distance"}
						onChange={handleDistanceChange}
						fullWidth
						value={StoresInstance.domain.EnquiryStore.enquiry.enquiryLocation.travelDistanceMiles}
						fontsize={isMobile ? "14px" : "17px"}
					>
						<option>Please Choose...</option>
						<option value={50} key="50miles">
							Up to 50 Miles
						</option>
						<option value={250} key="250miles">
							Up to 250 Miles
						</option>
						<option value={500} key="500miles">
							Up to 500 Miles
						</option>
					</AltSelect>
				</Grid>
			</GridSpaced>
			<GridSpaced container spacing={2} className={"formRow"}>
				<Grid item xs={12} sm={12} md={6}>
					<InputLabel>and from what location?</InputLabel>
				</Grid>
			</GridSpaced>
			{!toggleView && (
				<Box marginBottom="25px">
					<GridSpaced container spacing={2} additionalcss={"margin-bottom: 5px;"} className={"formRow"}>
						<Grid item xs={12} sm={12} md={5}>
							<AutoSuggestDiv>
								<AddressSelectorGoogle
									callback={doSubmit}
									onBlur={e => {
										if (e.target.value) {
											setShowToContinue(true);
										}
									}}
								/>
							</AutoSuggestDiv>
						</Grid>
					</GridSpaced>

					<Typography variant="body2">
						Please click an option in the dropdown menu{showToContinue ? " to continue" : ""}
					</Typography>
				</Box>
			)}
			{toggleView && (
				<GridSpaced container spacing={2} additionalcss={"margin-bottom: 25px;"} className={"formRow"}>
					<GridSpaced item xs={12} sm={12} md={6} additionalcss={"margin-top: 20px;"}>
						{domainStore.MapStore.address}
					</GridSpaced>
					<GridSpaced item xs={12} sm={12} md={3}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className="submit"
							onClick={handleEditLocation}
						>
							Change Location
						</Button>
					</GridSpaced>
				</GridSpaced>
			)}
		</>
	));
};
