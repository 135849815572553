import { ApiResult } from "Shoothill.Components/Models";
import { Stores } from "../Stores";
import * as Models from "../../Models";
import * as MobX from "mobx";
import * as ServerApi from "../../ServerApi";

// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";
import { InitialState } from "../../Models";
import { MultipleProductSetting } from "../../Models/MultipleProductSetting";

export class AppointmentTypesStore extends BaseStore {
	@observable public appointmentTypes: Summerfield.Data.appointmentType[] = [];
	@observable public appointmentType: Summerfield.Data.appointmentType | undefined;

	@observable private isAPILoading: boolean = false;

	public constructor() {
		super();
	}

	public init(stores: Stores, initialState: InitialState) {
		this.stores = stores;
	}

	@action
	public GetAppointmentTypesForGroup(appointmentTypeGroupId: string): Promise<any> {
		if (this.isAPILoading === false) {
			this.isAPILoading = true;

			let task = ServerApi.AppointmentType.GetAllForGroup(appointmentTypeGroupId)
				.then((appointmentTypes: ApiResult<Summerfield.Data.appointmentType[]>) => {
					if (appointmentTypes.wasSuccessful === true) {
						MobX.runInAction(() => {
							this.appointmentTypes.length = 0;
							this.appointmentTypes = appointmentTypes.payload;
							this.appointmentType = undefined;
							this.isAPILoading = false;
						});
					} else {
						alert("Failed to get the Appointment types");
					}

					return appointmentTypes.payload;
				})
				.catch((error: any) => {
					alert("Excepted. Failed to get the Appointment types. " + error);
					this.isAPILoading = false;
				});

			return task;
		}
		return new Promise(resolve => {
			resolve(this.appointmentTypes);
		});
	}

	@action
	public GetAppointmentTypes(excludeExcludeFromMainGroup: boolean = false): Promise<any> {
		if (this.isAPILoading === false) {
			this.isAPILoading = true;

			let task = ServerApi.AppointmentType.GetAll(excludeExcludeFromMainGroup)
				.then((appointmentTypes: ApiResult<Summerfield.Data.appointmentType[]>) => {
					if (appointmentTypes.wasSuccessful === true) {
						MobX.runInAction(() => {
							this.appointmentTypes.length = 0;
							this.appointmentTypes = appointmentTypes.payload;
							this.appointmentType = undefined;
							this.isAPILoading = false;
						});
					} else {
						// TODO CMR
						alert("Failed to get the Appointment types");
					}

					return appointmentTypes.payload;
				})
				.catch((error: any) => {
					let jeff = error;
					// TODO CMR
					alert("Excepted. Failed to get the Appointment types. " + error);
					this.isAPILoading = false;
				});

			return task;
		}
		return new Promise(resolve => {
			resolve(this.appointmentTypes);
		});
	}

	@action
	public ResetAppointmentTypes(): void {
		this.appointmentTypes = [];
	}
}
