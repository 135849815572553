import { Grid, InputLabel, TextField } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { useValidation } from "../../Shoothill.Core/Utils/Hooks/UseValidation";
import { useObserver } from "mobx-react-lite";
import { GridSpaced } from "Shoothill.Components/StylesAppSpecific/Customer";
import * as Validations from "../../Shoothill.Core/Utils/Validations";
import { useMediaQuery } from "Shoothill.Core/Utils/Media/mediaQuery";

export interface IDeliveryAddressHandles {
	validate(): Summerfield.Data.address | undefined;
}

interface IProps {
	addressLine1Prefix: string;
}

const DeliveryAddressContainer = styled.div``;

const _DeliveryAddress: React.RefForwardingComponent<IDeliveryAddressHandles, IProps> = (
	{ addressLine1Prefix },
	ref,
) => {
	const [formState, { text }] = useValidation<Summerfield.Data.address>({
		addressLine1: "",
		addressLine2: "",
		addressLine3: "",
		county: "",
		postcode: "",
		townOrCity: "",
	});
	const useMobile = !!useMediaQuery("screen and (max-width: 1023px)");
	React.useImperativeHandle(ref, () => ({
		validate: () => {
			if (formState.isFormValid()) {
				return formState.formData;
			}
		},
	}));

	return useObserver(() => (
		<>
			<DeliveryAddressContainer>
				<GridSpaced
					container
					spacing={2}
					additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
					className={"formRow"}
				>
					<Grid item xs={12} sm={12} md={6}>
						<InputLabel>{addressLine1Prefix} Address line 1*</InputLabel>
						<TextField
							placeholder={""}
							fullWidth
							autoComplete="addressLine1"
							{...text("addressLine1", {
								validationRules: Validations.baseValidation,
							})}
						/>
						{formState.showErrors("addressLine1")}
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<InputLabel>Address line 2</InputLabel>
						<TextField
							placeholder={""}
							fullWidth
							autoComplete="addressLine2"
							{...text("addressLine2", {})}
						/>
						{formState.showErrors("addressLine2")}
					</Grid>
				</GridSpaced>

				<GridSpaced
					container
					spacing={2}
					additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
					className={"formRow"}
				>
					<Grid item xs={12} sm={12} md={6}>
						<InputLabel>Address line 3</InputLabel>
						<TextField
							placeholder={""}
							fullWidth
							autoComplete="addressLine3"
							{...text("addressLine3", {})}
						/>
						{formState.showErrors("addressLine3")}
					</Grid>
					<Grid item xs={12} sm={12} md={3}>
						<InputLabel>Town/City*</InputLabel>
						<TextField
							placeholder={""}
							fullWidth
							autoComplete="townOrCity"
							{...text("townOrCity", {
								validationRules: Validations.baseValidation,
							})}
						/>
						{formState.showErrors("townOrCity")}
					</Grid>
					<Grid item xs={12} sm={12} md={3}>
						<InputLabel>County</InputLabel>
						<TextField placeholder={""} fullWidth autoComplete="county" />
					</Grid>
				</GridSpaced>

				<GridSpaced
					container
					spacing={2}
					additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
					className={"formRow"}
				>
					<Grid item xs={12} sm={12} md={3}>
						<InputLabel>Postcode*</InputLabel>
						<TextField
							placeholder={""}
							fullWidth
							autoComplete="postcode"
							{...text("postcode", {
								validationRules: Validations.baseValidation,
							})}
						/>
						{formState.showErrors("postcode")}
					</Grid>
					<Grid item xs={12} sm={12} md={3}></Grid>
				</GridSpaced>
			</DeliveryAddressContainer>
		</>
	));
};

export const DeliveryAddress = React.forwardRef(_DeliveryAddress);
