export class KeyMap {
	private counter = 1;
	private map = new WeakMap<object, string>();

	public GetKey = (object: object, prefix?: string) => {
		let key = this.map.get(object);

		if (key === undefined) {
			key = this.counter.toString();
			this.map.set(object, key);
			this.counter++;
		}

		key = (prefix != null ? prefix : "") + key;

		return key;
	};
}
