import styled, { StyledComponent } from "styled-components";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
}

export const TimeSlot: StyledComponent<"div", any, IComponentProps, never> = styled.div<IComponentProps>`
	cursor: pointer;
	display: inline-flex;
	background-color: #ffffff;
	max-width: 106px;
	width: 100%;
	height: 40px;
	box-shadow: 0px 3px 6px #00000029;
	margin: ${props => props.margin};
	padding: 9px 16px;
	font-size: ${props => props.fontsize};
	align-items: center;
	${props => props.additionalcss}

	:hover {
		color: #ffffff;
		background-color: #337ab7;

		svg.backBtn {
			path {
				fill: #ffffff;
			}
		}
	}

	svg {
		width: 20px;
		padding: 0px;
		margin-right: 5px;
	}
`;
