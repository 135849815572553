import { HttpUnauthorizedError } from "./../../Errors/HttpUnauthorizedError";
import { createContext } from "react";
import * as Utils from "../../Shoothill.Core/Utils/Utils";
import { InitialState } from "./../../Models/InitialState";
import { Stores } from "../Stores";
import * as Models from "../../Models";
import * as MobX from "mobx";
import * as ServerApi from "../../ServerApi";

// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";

export class CustomerStore extends BaseStore {
	private otherStores: Stores | undefined = undefined;

	@observable public customer: Models.Domain.Customer | undefined = undefined;

	public constructor() {
		super();
	}

	public init(stores: Stores, initialState: InitialState) {
		this.otherStores = stores;
	}

	@computed
	public get hasValidCustomer(): boolean {
		let retVal: boolean = false;

		// eslint-disable-next-line prettier/prettier
		if (
			this.otherStores !== undefined &&
			// eslint-disable-next-line prettier/prettier
			this.otherStores.domain.AccountStore.IsLoggedIn === true &&
			// eslint-disable-next-line prettier/prettier
			this.customer != undefined &&
			// eslint-disable-next-line prettier/prettier
			this.customer!.isValid === true
		) {
			retVal = true;
		}

		return retVal;
	}
}
