/* eslint-disable @typescript-eslint/no-explicit-any */
import { InitialState } from "../../../Models/InitialState";
import { useState, useEffect, useReducer } from "react";
import axios, { AxiosResponse } from "axios";
// import * as Models from "../../Models";

function dataFetchReducer(state: any, action: Actions) {
	switch (action.type) {
		case "FETCH_INIT":
			return { ...state, isLoading: true, hasLoaded: false, isError: false };
		case "FETCH_SUCCESS":
			return {
				...state,
				isLoading: false,
				isError: false,
				hasLoaded: true,
				data: action.payload,
			};
		case "FETCH_FAILURE":
			return {
				...state,
				isLoading: false,
				hasLoaded: false,
				isError: true,
				data: {},
			};
		default:
			throw new Error();
	}
}

interface Actions {
	type: string;
	payload?: InitialState;
}

interface DataApi<T> {
	isLoading: boolean;
	hasLoaded: boolean;
	isError: boolean;
	data: T;
	doRequest(url: string, postData?: any, event?: any): void;
}

enum RequestType {
	GET,
	POST,
}

// export const useDataApi: (initialUrl: string, initialData: any) => <string> = (initialUrl: string) {
// export const useDataApi: (initialUrl: string, initialData: any) => {} = (initialUrl: string, initialData: string) => {
/**
 * Hook to fetch data from the url specified.
 * @param {string} initialUrl Url to send data to. Required.
 * @param {RequestType} requestType Defaults to GET. Ignored if postData is not null
 * @param {any} initialPostData Data to be posted to the Url specified. If blank the call will be a get request. Defaults to null
 */

export const useDataApi = <T>(
	initialUrl: string = "",
	requestType: RequestType = RequestType.GET,
	initialPostData: any = null,
): DataApi<T> => {
	const initialData = null;
	const [url, setUrl] = useState(initialUrl);
	const [postData, setPostData] = useState(initialPostData);

	const [state, dispatch] = useReducer(dataFetchReducer, {
		isLoading: false,
		hasLoaded: false,
		isError: false,
		data: initialData,
	});

	useEffect(() => {
		let didCancel = false;

		if (url === "") {
			return;
		}

		const fetchData = async () => {
			dispatch({ type: "FETCH_INIT" });
			let headers = {};
			var jwt = sessionStorage.getItem(".auth");
			if (jwt !== null || jwt !== "") {
				headers = { Authorization: `Bearer ${jwt}` };
			}

			try {
				let result: AxiosResponse<any> = {} as any;
				if (postData || requestType === RequestType.POST) {
					result = await axios.post(url, postData, { headers: headers });
				} else {
					result = await axios(url, { headers: headers });
				}

				if (!didCancel) {
					dispatch({ type: "FETCH_SUCCESS", payload: result.data });
					setUrl(""); // Reset so that subsequent calls will show a state change
				}
			} catch (error) {
				if (!didCancel) {
					dispatch({ type: "FETCH_FAILURE" });
					setUrl("");
				}
			}
		};

		fetchData();

		return () => {
			didCancel = true;
		};
	}, [url]);

	const doRequest = (url: string, initialPostData: any = null, event: any = null): void => {
		setUrl(url);
		setPostData(initialPostData);

		if (event) {
			event.preventDefault();
		}
	};

	return { ...state, doRequest };
};
