import React from "react";
import { Helmet } from "react-helmet";
import "./Home.css";
import { AppointmentSetup } from "../Shoothill.Components/App/AppointmentSetup";
import { Centered } from "Shoothill.Components/General";
import { CustomerWrapper, GridSpaced } from "Shoothill.Components/StylesAppSpecific/Customer";
import { makeStyles } from "@material-ui/styles";
import { Box, Theme, useTheme } from "@material-ui/core";
import { useMediaQuery } from "Shoothill.Core/Utils/Media/mediaQuery";

import BackgroundImage from "../Content/images/summercarebackground.png";
import { TimeSlot } from "../Shoothill.Components/StylesAppSpecific/ClinicStyling";
import Logo from "../Content/images/summerfieldLogo@2x.png";
import { backArrow } from "Shoothill.Components/App/AvailableClinics";
import { UseRouter } from "../Shoothill.Core/Utils/Hooks/UseReactRouter";

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		backgroundImage: `url(${BackgroundImage})`,
		backgroundRepeat: "none",
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		height: "100%",
	},
	main: {
		width: "100%",
		display: "block", // Fix IE 11 issue.
		height: "100%",
	},
}));

export const Booking: React.FC = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const matches = useMediaQuery("screen and (max-width: 1023px)");
	const { history } = UseRouter();

	return (
		<>
			<Helmet>
				<meta http-equiv="X-UA-Compatible" content="IE=Edge, IE=11" />
			</Helmet>
			<div className={classes.wrapper}>
				<main className={classes.main}>
					<Centered>
						<CustomerWrapper
							backgroundcolor="#ffffff"
							fontcolor="#263959"
							additionalcss={"margin-bottom: 10px; padding-top: 46px !important;"}
						>
							<Box marginBottom="20px">
								<GridSpaced item key="back" additionalcss="display: flex;">
									<Box flexGrow={1}>
										<TimeSlot
											margin={"0px 25px 0px 0px"}
											fontsize={"17px"}
											onClick={() =>
												(window as any).overrideBackBtnBehavior
													? (window as any).overrideBackBtnBehavior()
													: (window as any).backBehavior
													? (window as any).backBehavior()
													: history.push("/")
											}
										>
											{backArrow}
											Back
										</TimeSlot>
									</Box>
									<a
										href="https://www.summerfieldhealthcare.co.uk"
										target="_self"
										style={{ textDecoration: "none", color: "#263959" }}
									>
										<TimeSlot
											margin={"0px 25px 0px 0px"}
											fontsize={"17px"}
											style={{ maxWidth: "initial", margin: 0 }}
										>
											Back to main website
										</TimeSlot>
									</a>
								</GridSpaced>
							</Box>
							<div className="customer-header">
								<div className="customer-header-img">
									<img className="summerfieldLogo" src={Logo}></img>
								</div>
								<AppointmentSetup />
							</div>
						</CustomerWrapper>
					</Centered>
				</main>
			</div>
		</>
	);
};
