import { useEffect, useState } from "react";
// usage useMediaQuery( e.g.`screen and (max-width: 30em)` ) returns true/false if device supports it
export const useMediaQuery = (useQuery: string) => {
	const query = window.matchMedia(useQuery);
	const [match, setMatch] = useState(query.matches);
	useEffect(() => {
		const handleMatch = (mediaQuery: any) => setMatch(mediaQuery.matches);
		query.addListener(handleMatch);
		return () => query.removeListener(handleMatch);
	});
	return match ? true : false;
};
