// Libs
import * as Axios from "axios";

// App
import { HttpStatusToError } from "./HttpStatusToError";

export const CheckHttpStatus: (response: Axios.AxiosResponse) => void = response => {
	if (response.status !== 200) {
		throw HttpStatusToError(response.status, response.statusText);
	}
};
