// Libs
//import _get from "lodash/get";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";

export const MatchValue = (targetValue: any, searchText: string) => {
	if (targetValue == null || searchText == null) {
		return false;
	}

	let targetText = "";

	if (_isString(targetValue)) {
		targetText = targetValue;
	} else if (_isNumber(targetValue)) {
		targetText = targetValue.toString();
	} else {
		throw new Error("Unsupported target type.");
	}

	const isMatch = targetText.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;

	return isMatch;
};
