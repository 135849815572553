import { createMuiTheme, Theme } from "@material-ui/core/styles";

const breakpointTheme = createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 0,
			md: 0,
			lg: 0,
			xl: 768,
		},
	},
});

export const defaultTheme = createMuiTheme({
	typography: {
		fontFamily: `"Merriweather Sans", "Roboto Condensed", "Helvetica", "Arial", sans-serif`,
		h1: {
			fontSize: "2rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("sm")]: {
				textAlign: "center",
			},
		},
		h2: {
			fontSize: "1.25rem!important",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		h3: {
			fontSize: "1.17rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		h4: {
			fontSize: "1rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		h5: {
			fontSize: "0.83rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		h6: {
			fontSize: "0.67rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		subtitle1: {
			fontSize: "1rem",
			fontWeight: 300,
			letterSpacing: "-0.4px",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		subtitle2: {
			fontSize: "0.875rem",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		body1: {
			fontSize: "1rem",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		body2: {
			fontSize: "0.875rem",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		button: {
			fontSize: "0.875rem",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		caption: {
			fontSize: "0.75rem",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
		overline: {
			fontSize: "0.75rem",
			[breakpointTheme.breakpoints.down("lg")]: {},
		},
	},
	palette: {
		primary: {
			main: "#337AB7",
			contrastText: "#FFFFFF",
		},
		secondary: {
			main: "#8BA9C4",
			contrastText: "#FFFFFF",
		},
		error: {
			main: "#E64947",
			contrastText: "#FFFFFF",
		},
	},
	overrides: {
		MuiLink: {
			root: {
				textDecoration: "none",
				color: "#000",
			},
		},
		MuiTabs: {
			indicator: {
				display: "none",
			},
		},
		MuiTab: {
			root: {
				backgroundColor: "#F1F1F1",
				border: "1px solid rgba(0, 0, 0, 0.08)",
				marginLeft: "1px",
				textTransform: "capitalize",
				fontWeight: "bold",
				borderRadius: "2px 2px 0 0",
				"&:hover": {
					backgroundColor: "rgba(0, 0, 0, .1)",
					color: "black",
				},
				"@media (min-width: 960px)": {
					width: "57px",
					minWidth: "57px",
				},
			},
		},
		MuiButton: {
			root: {
				margin: "15px 0 15px 0",
				borderRadius: "0",
				textTransform: "capitalize",
				fontSize: "13px",
				"@media (min-width: 1024px)": {
					fontSize: "16px",
				},
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: "36px",
			},
		},
		MuiOutlinedInput: {
			input: {
				padding: "10px 6px 10px 6px",
				height: "30px",
			},
			notchedOutline: {
				borderRadius: "0",
			},
		},
		MuiInputLabel: {
			outlined: {
				fontWeight: 500,
				color: "#191919",
			},
		},
		MuiInput: {
			root: {
				input: {
					backgroundColor: "#FFFFFF",
					color: "'FAFAFA",
				},
			},
		},
		MuiSnackbarContent: {
			root: {
				backgroundColor: "#E64947",
				color: "#FFFFFF",
			},
		},
	},
});
