/* eslint-disable @typescript-eslint/no-non-null-assertion */
//Based on https://github.com/wsmd/react-use-form-state
import { useEffect, useState, useReducer, useRef, useCallback, useMemo } from "react";
import React from "react";

import { generateID } from "../Utils";

export const CHECKBOX = "checkbox";
export const COLOR = "color";
export const DATE = "date";
export const EMAIL = "email";
export const MONTH = "month";
export const NUMBER = "number";
export const PASSWORD = "password";
export const RADIO = "radio";
export const RANGE = "range";
export const SEARCH = "search";
export const SELECT = "select";
export const TEL = "tel";
export const TEXT = "text";
export const TIME = "time";
export const URL = "url";
export const WEEK = "week";
/**
 * @todo add support for datetime-local
 */
export const DATETIME_LOCAL = "datetime-local";
/**
 * @todo add support for a multiple select
 */
export const MULTIPLE = "multiple";

export const TYPES = [
	CHECKBOX,
	COLOR,
	DATE,
	EMAIL,
	MONTH,
	NUMBER,
	PASSWORD,
	RADIO,
	RANGE,
	SEARCH,
	SELECT,
	TEL,
	TEXT,
	TIME,
	URL,
	WEEK,
];

type Maybe<T> = T;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type InputValues<T> = { readonly [A in keyof T]: T[A] } & {
	readonly [key: string]: Maybe<string | string[]>;
};
type InputValuesErrors<T> = { readonly [A in keyof T]: Maybe<string[]> } & {
	readonly [key: string]: Maybe<string[]>;
};

type InputValuesValidity<T> = { readonly [A in keyof T]: Maybe<boolean> } & {
	readonly [key: string]: Maybe<boolean>;
};

interface InputOptions {
	value?: any;
	validationRules?: any[];
}

interface Inputs {
	select(name: string, inputOptions?: InputOptions): Omit<InputProps, "type">;
	email(name: string, inputOptions?: InputOptions): InputProps;
	color(name: string, inputOptions?: InputOptions): InputProps;
	password(name: string, inputOptions?: InputOptions): InputProps;
	text(name: string, inputOptions?: InputOptions): InputProps;
	url(name: string, inputOptions?: InputOptions): InputProps;
	search(name: string, inputOptions?: InputOptions): InputProps;
	number(name: string, inputOptions?: InputOptions): InputProps;
	range(name: string, inputOptions?: InputOptions): InputProps;
	tel(name: string, inputOptions?: InputOptions): InputProps;
	radio(name: string, inputOptions: InputOptions | null, ownValue: string): Omit<InputProps & CheckedProp, "type">;
	checkbox(name: string, inputOptions?: InputOptions): Omit<InputProps & CheckedProp, "type">;
	date(name: string, inputOptions?: InputOptions): InputProps;
	month(name: string, inputOptions?: InputOptions): InputProps;
	week(name: string, inputOptions?: InputOptions): InputProps;
	time(name: string, inputOptions?: InputOptions): InputProps;
}

interface CheckedProp {
	checked: boolean;
}

export interface FormState<T> {
	formData: InputValues<T>;
	errors: InputValuesErrors<T>;
	validity: InputValuesValidity<T>;
	touched: InputValuesValidity<T>;
	focused: InputValuesValidity<T>;
	blurred: InputValuesValidity<T>;
	isFormValid(): boolean;
	validate(toValidate?: string): void;
	handleSubmit(e: any): any;
	setValue(name: string, value: string | number): void;
	showErrors(name: string, className?: string, amountToShow?: number): any;
	wipeFormData(): void;
	remount(): void;
	initFormData(newValues: T): void;
	setErrorsState: React.Dispatch<any>;
	setValidityState: React.Dispatch<any>;
}

interface InputProps {
	onChange(e: any): void;
	onBlur(e: any): void;
	onFocus(e: any): void;
	checked: boolean;
	value: string | number | string[] | undefined;
	name: string;
	id: string;
	type?: string;
	error: boolean;
}

export default function stateReducer(state: any, newState: any) {
	return { ...state, ...newState };
}

interface Options {
	validateOnBlur?: boolean;
	validateOnChange?: boolean;
	validateOnFocus?: boolean;
	handleSubmitCallback?: any;
	invalidAttr?: any;
	submitCallback?: any;
}

const defaultOptions: Options = {
	validateOnBlur: true,
	validateOnChange: false,
	validateOnFocus: false,
	handleSubmitCallback: null,
	invalidAttr: { error: false },
	submitCallback: null,
};

// Must be global so as not to reset state. Hacky workaround!
//let validationCallbacks: {} = {} as any;

/**
 *
 * @param defaultValues
 * @param options
 *
 * Usage: const [formState, { text, checkbox }] = useValidation({email: "info@shoothill.com"});
 *
 */
export function useValidation<T>(defaultValues: T, options: Options = defaultOptions): [FormState<T>, Inputs] {
	options = { ...defaultOptions, ...options };

	const initErrors = (defaultValues: T) => {
		let obj = {};
		if (defaultValues) {
			Object.keys(defaultValues).forEach(keyName => {
				obj[keyName] = [];
			});
		}
		return obj;
	};

	const initFormData = (newValues: T) => {
		setFormData(newValues);
	};

	const wipeFormData = () => {
		setFormData(defaultValues);
	};

	// const formHandler = useState(defaultValues);
	const [mounted, setMounted] = useState(false);

	const [formData, setFormData] = useReducer(stateReducer, defaultValues || {});
	const [touched, setTouchedState] = useReducer(stateReducer, {});
	const [blurred, setBlurredState] = useReducer(stateReducer, {});
	const [validity, setValidityState] = useReducer(stateReducer, {});
	const [errors, setErrorsState] = useReducer(stateReducer, initErrors(defaultValues) || {});
	const [focused, setFocusedState] = useReducer(stateReducer, {});
	const [initialValidation, setInitialValidation] = useState(true);
	const [validationCallBacks, setValidationCallBacks] = useReducer(stateReducer, {} as any);
	const uniqueId = useRef(generateID());

	const [__VALUES__, setValues] = useReducer(stateReducer, defaultValues || {});
	const [__TOUCHED__, setTouched] = useReducer(stateReducer, {});
	const [__BLURRED__, setBlurred] = useReducer(stateReducer, {});
	const [__VALIDITY__, setValidity] = useReducer(stateReducer, {});
	const state = useRef({});
	state.current = {
		values: __VALUES__,
		touched: __TOUCHED__,
		validity: __VALIDITY__,
		blurred: __BLURRED__,
	};

	const handleSubmit = (event: any) => {
		if (event) {
			event.preventDefault();
		}
		if (options.submitCallback) {
			options.submitCallback();
		}
	};

	const setValue = (name: string, value: string | number) => {
		setFormData({ [name]: value });
	};

	const showErrors = (name: string, className: string = "", amountToShow: number = 1) => {
		let retval: any[] = [];
		if (!validity[name]) {
			if (errors[name] && errors[name].length > 0) {
				errors[name].forEach((error: string, index: number) => {
					if (index === amountToShow) return;
					retval.push(
						//React.createElement("div", null, { error }),
						<div key={`error-${name}-${index}`} className={`${className} error`}>
							{error}
						</div>,
						//error,
					);
				});
			}
		}
		return retval;
	};

	const validate = (toValidate?: string): void => {
		(window as any)[uniqueId.current] = formData;
		(window as any)[uniqueId.current].validity = {} as any;
		// Toggle state to force validation
		if (!!!toValidate) {
			Object.keys(validationCallBacks).forEach((name: string) => {
				validationCallBacks[name](formData[name], initialValidation);
			});
		} else {
			validationCallBacks[toValidate](formData[toValidate], initialValidation);
		}
	};

	const scopedValidity = (): void => {};

	const isFormValid = (): boolean => {
		validate();
		let localValidity = validity;
		let isValid = true;
		if ((window as any)[uniqueId.current].validity) {
			setValidityState((window as any)[uniqueId.current].validity);
			localValidity = (window as any)[uniqueId.current].validity;
		}
		Object.keys(defaultValues).forEach(keyName => {
			if (localValidity[keyName] === false) {
				isValid = false;
				return false;
			}
		});
		// setDoValidation(false);
		return isValid;
	};

	// initial mounted flag
	useEffect(() => {
		setMounted(true);
		// validate();
		setInitialValidation(false);
	}, []);

	const remount = () => {
		setMounted(false);
	};

	const createPropsGetter = (type: any) => (
		name: string,
		inputOptions: InputOptions | null,
		ownValue: string = "",
	) => {
		const hasValue = formData[name] !== undefined;
		if (!inputOptions) {
			inputOptions = {};
		}
		let { value, validationRules } = inputOptions;

		const handleValidation: any = (value: any, initialValidation: boolean = false): boolean => {
			var isValid = true;
			// const value = formData[name];
			setErrorsState({ [name]: [] });

			// if (validationRules!.length === 0) { return e.target.validity.valid; }
			if (validationRules) {
				let newErrors: string[] = [];

				validationRules!.forEach((rule, index) => {
					//EN: Arrggghh god damn closures. Dirty hack until I figure out how to bypass block level scope on a closure
					let currentState = (window as any)[uniqueId.current];
					if (!currentState) currentState = formData;
					var valid = rule(value, currentState);
					// Assume valid unless at least one rule fails
					if (valid !== true) {
						isValid = false;
						// Don't show error text on initial validation
						//if (!initialValidation)
						{
							newErrors.push(valid);
							setErrorsState({ [name]: newErrors });
						}
					}
				});
			}
			setValidityState({ [name]: isValid });
			//EN: Hack to get around closures :-(
			if ((window as any)[uniqueId.current] && (window as any)[uniqueId.current].validity) {
				(window as any)[uniqueId.current].validity[name] = isValid;
			}
			return isValid;
		};

		if (!mounted) {
			// validationCallbacks[name] = (value: any, initialRun: boolean) => {
			// 	handleValidation(value, initialRun);
			// };
			if (validationCallBacks[name] === undefined) {
				setValidationCallBacks({
					[name]: (value: any, initialRun: boolean) => {
						handleValidation(value, validationCallBacks, initialRun);
					},
				});
			}
		}

		const inputProps = {
			name,
			get id() {
				if (type !== SELECT && type !== RADIO) {
					return name;
				}
				return "";
			},
			//...{ error: validity[name] === false },
			get error() {
				if (type !== SELECT && type !== RADIO) {
					if (validity[name] === false) {
						return true;
					}
					return undefined;
				}
			},
			get type() {
				if (type !== SELECT && type !== RADIO) {
					return type;
				}
				if (type === RADIO) {
					return "radio";
				}
				return "";
			},
			get checked() {
				// if (type === CHECKBOX) {
				// 	return hasValue ? formData[name].includes(value) : false;
				// }
				if (type === CHECKBOX) {
					return hasValue ? formData[name] : false;
				}
				if (type === RADIO) {
					return formData[name] === ownValue;
				}

				return "";
			},
			get value() {
				// populating values of the form state on first render
				if (!hasValue) {
					setFormData({ [name]: type === CHECKBOX ? [] : "" });
				}
				if (type === CHECKBOX || type === RADIO) {
					return ownValue;
				}
				if (hasValue && formData[name] !== null) {
					return formData[name];
				}
				return "";
			},
			onClick(e: any) {
				const { value: targetValue, checked } = e.target;
				if (type === RADIO) {
					setFormData({ [name]: targetValue });
				}
			},
			onChange(e: any) {
				if (e instanceof Date) {
					setFormData({ [name]: e });
					if (options.validateOnChange) {
						handleValidation(e, false);
					}
				} else {
					const targetValue = type === CHECKBOX ? e.target.checked : e.target.value;
					//let inputValue: string = validator.stripLow(targetValue, true);
					let inputValue = targetValue;
					setFormData({ [name]: inputValue });
					if (options.validateOnChange || type === CHECKBOX) {
						handleValidation(inputValue, false);
					}
				}
			},
			onFocus(e: any) {
				const { value: targetValue } = e.target;
				let inputValue = targetValue;
				setFocusedState({ [name]: true });
				setBlurredState({ [name]: false });
				if (options.validateOnFocus) {
					handleValidation(inputValue, false);
				}
			},
			onBlur(e: any) {
				const targetValue = type === CHECKBOX ? e.target.checked : e.target.value;
				let inputValue = targetValue;
				setTouchedState({ [name]: true });
				setBlurredState({ [name]: true });
				if (options.validateOnBlur) {
					handleValidation(inputValue, false);
				}
			},
		};
		return inputProps;
	};

	const typeMethods = TYPES.reduce(
		(methods: any, type: string) => ({
			...methods,
			[type]: createPropsGetter(type),
		}),
		{},
	);

	return [
		{
			formData,
			errors,
			focused,
			blurred,
			touched,
			validity,
			isFormValid,
			validate,
			handleSubmit,
			setValue,
			showErrors,
			wipeFormData,
			remount,
			initFormData,
			setErrorsState,
			setValidityState,
		},
		typeMethods,
	];
}
