import styled from "styled-components";
import { Grid, Button, Select } from "@material-ui/core";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
}

export const AltSelect = styled(Select)<IComponentProps>`
	background-color: #fff;
	border: solid 1px rgb(206, 212, 218);
	padding-left: 10px;
	:hover {
		border: solid 1px rgb(206, 212, 218);
	}
	font-size: ${props => props.fontsize};
	${props => props.additionalcss}
`;

export const AutoSuggestDiv: any = styled.div<IComponentProps>`
	.react-autosuggest__container {
		padding: 0;
		margin: 0;
	}
	input {
		width: 100%;
		padding: 10px !important;
	}
	ul {
		padding: 0px;
		margin: 0;
		list-style: none;
		background-color: #fff;
		border: solid 1px rgb(206, 212, 218);
	}
	li {
		padding: 5px;
	}
	li:hover {
		background-color: #337ab7;
		color: #ffffff;
	}
`;
