// Libs
import _get from "lodash/get";

// App
import { MatchValue } from "./MatchValue";

export const MatchPaths = (target: any, paths: string[], searchText: string) => {
	for (const path of paths) {
		const targetValue = _get(target, path);
		const isMatch = MatchValue(targetValue, searchText);

		if (isMatch) {
			return true;
		}
	}

	return false;
};
