function _isNil(value: any) {
	return value == null;
}

export const generateID = function() {
	// Math.random should be unique because of its seeding algorithm.
	// Convert it to base 36 (numbers + letters), and grab the first 9 characters
	// after the decimal.
	return (
		"_" +
		Math.random()
			.toString(36)
			.substr(2, 9)
	);
};

export const sortByString = (a: string | undefined, b: string | undefined, options?: Intl.CollatorOptions) => {
	if (a === undefined && b === undefined) {
		return 0;
	}
	if (a === undefined) {
		return -1;
	}
	if (b === undefined) {
		return 1;
	}

	return a.localeCompare(b, undefined, options);
};

export const coalesce = <TArg>(...args: (TArg | undefined)[]) => {
	for (const arg of args) {
		if (_isNil(arg) === false) {
			return arg;
		}
	}

	return null;
};
export const getApiUrl = (): string => (window as any).apiurl;

export const getImageUrl = (imageUrl: string): string => {
	return getApiUrl() + imageUrl;
};

export const getBaseUrl = (): string | undefined => {
	const baseElements = document.getElementsByTagName("base");

	if (baseElements.length === 0) {
		throw new Error("Base element not found");
	}

	if (baseElements.length > 1) {
		throw new Error("Multiple base elements found");
	}

	const baseElement = baseElements[0];
	const baseUrl = baseElement.getAttribute("href");

	if (baseUrl === undefined) {
		throw new Error("Base element 'href' attribute not found.");
	}

	let retVal: string | undefined;
	if (baseUrl !== null) {
		retVal = baseUrl;
	}
	return retVal;
};

export const parseJwt = (token: string) => {
	const base64Url = token.split(".")[1];
	const base64 = decodeURIComponent(
		atob(base64Url)
			.split("")
			.map(c => {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);

	return JSON.parse(base64);
};
