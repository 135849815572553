import React from "react";
import { Theme, Grid, FormControl, TextField, Button, InputAdornment } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { RegisterUserModel } from "./RegisterUserModel";
import { useValidation } from "Shoothill.Core/Utils";
import * as Validations from "Shoothill.Core/Utils/Validations";
import validator from "validator";
import { LoginPaper } from "../../Shoothill.Components/StylesAppSpecific/LoginReg";
import { LoginEmbed } from "./LoginEmbed";

const useStyles = makeStyles((theme: Theme) => ({
	label: {
		fontSize: "0.8em",
	},
	formControl: {
		margin: theme.spacing(1),
		width: "100%",
	},
}));

interface Props {
	onSubmit: (registerUserModel: RegisterUserModel) => void;
}

export const Register = ({ onSubmit }: Props) => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const initialValues = new RegisterUserModel();

	const [formState, { text, checkbox, select, password, email }] = useValidation(initialValues);

	const doSubmit = (e: any) => {
		e.preventDefault();
		if (formState.isFormValid()) onSubmit(formState.formData);
	};

	return (
		<LoginPaper>
			<form onSubmit={doSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								className="name-textfield"
								placeholder="Your first name"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<PersonOutlineIcon />
										</InputAdornment>
									),
								}}
								fullWidth
								autoComplete="fname"
								{...text("firstName", {
									validationRules: Validations.baseValidation,
								})}
							/>
							{formState.showErrors("firstName", "error")}
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								className="name-textfield"
								placeholder="Your surname"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<PersonOutlineIcon />
										</InputAdornment>
									),
								}}
								fullWidth
								autoComplete="lname"
								{...text("lastName", {
									validationRules: Validations.baseValidation,
								})}
							/>
							{formState.showErrors("lastName", "error")}
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								placeholder="Your e-mail address"
								fullWidth
								//autoComplete="email"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<MailOutlinedIcon />
										</InputAdornment>
									),
								}}
								{...email("emailAddress", {
									validationRules: [
										(v: string) => validator.isEmail(v) || "Email address is invalid",
										(v: string) => !validator.isEmpty(v) || "Email address is required",
									],
								})}
							/>
							{formState.showErrors("emailAddress", "error", 2)}
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								placeholder="Password"
								fullWidth
								id="input-password"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<LockOutlinedIcon />
										</InputAdornment>
									),
								}}
								{...password("password", {
									validationRules: [
										...Validations.baseValidation,
										...[
											(v: string) =>
												Validations.isMediumPasswordStrength.test(v) || "Password is too weak",
										],
									],
								})}
							/>
							{formState.showErrors("password", "error")}
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								placeholder="Confirm Password"
								fullWidth
								id="input-password"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<LockOutlinedIcon />
										</InputAdornment>
									),
								}}
								{...password("confirmPassword", {
									validationRules: [
										...Validations.baseValidation,
										...[
											(v: string, values: RegisterUserModel) =>
												(v && v.length > 0 && v === values.password) || "Passwords must match",
										],
									],
								})}
							/>
							{formState.showErrors("confirmPassword", "error")}
						</FormControl>
					</Grid>
					<Button type="submit" fullWidth variant="contained" color="primary" className="submit">
						Sign Up
					</Button>
				</Grid>
			</form>
		</LoginPaper>
	);
};
