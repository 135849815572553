import axios, * as Axios from "axios";

// App
import { CheckHttpStatus } from "./CheckHttpStatus";
import { MergeDefaultConfig } from "./MergeDefaultConfig";
import * as Models from "Shoothill.Components/Models";

export const Post = <TPayload = undefined>(
	url: string,
	model?: any,
	config?: Axios.AxiosRequestConfig,
): Promise<Models.ApiResult<TPayload>> => {
	const requestConfig = MergeDefaultConfig(config);
	requestConfig.headers = {
		Authorization: "Bearer " + (window as any).jwt,
	};
	// axios.defaults.headers.common[Authorization] = "Bearer " + (window as any).jwt;

	const postPromise = axios.post<Models.ApiResult<TPayload>>(url, model, requestConfig).then(response => {
		CheckHttpStatus(response);
		const apiResult = response.data;
		return apiResult;
	});

	return postPromise;
};
