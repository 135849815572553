import React, { useState } from "react";

import { Button, TextField, Grid, InputLabel, Box, FormControlLabel, Checkbox } from "@material-ui/core";

import { GridSpaced } from "../../Shoothill.Components/StylesAppSpecific/Customer";
import { useValidation } from "Shoothill.Core/Utils";
import { clientpaymentModel } from "../../Models/FormModels/clientpaymentModel";
import * as Validations from "../../Shoothill.Core/Utils/Validations";
import { isNumericLiteral } from "@babel/types";
import { isNumber } from "util";
import EnquiryViewModel from "../../ViewModels/EnquiryViewModel";
import { StoresInstance } from "Stores";

interface Props {
	onComplete: () => void;
	productCustomerDetails: any;
	errors: string[];
	onErrors(errors: string[]): void;
}

const LogoSSL = (
	<svg xmlns="http://www.w3.org/2000/svg" width="87" height="27" viewBox="0 0 87 27">
		<g transform="translate(-21352 -2671)">
			<g transform="translate(593.83 360.432)">
				<path
					d="M6.46,4.08V2.55A2.553,2.553,0,0,0,3.91,0H3.57A2.553,2.553,0,0,0,1.02,2.55V4.08A1.02,1.02,0,0,0,0,5.1V8.5a1.02,1.02,0,0,0,1.02,1.02H6.46A1.02,1.02,0,0,0,7.48,8.5V5.1A1.02,1.02,0,0,0,6.46,4.08ZM2.04,2.55A1.532,1.532,0,0,1,3.57,1.02h.34A1.532,1.532,0,0,1,5.44,2.55V4.08H2.04Zm2.04,4.5v.775a.34.34,0,1,1-.68,0V7.046a.68.68,0,1,1,.68,0Z"
					transform="matrix(0.966, -0.259, 0.259, 0.966, 20767.98, 2320.372)"
					fill="#6da453"
				/>
				<g transform="matrix(0.966, 0.259, -0.259, 0.966, 20766.596, 2313.77)">
					<path
						d="M0,8.3A8.3,8.3,0,0,1,14.957,3.349L17.1,2.114Q16.591,4,16.084,5.892L12.307,4.879l2.078-1.2A7.641,7.641,0,0,0,.657,8.3H0Z"
						transform="translate(0.502 0)"
						fill="#9f9f9f"
					/>
					<path
						d="M17.1,0A8.3,8.3,0,0,1,8.8,8.3a8.291,8.291,0,0,1-6.66-3.349L0,6.183Q.505,4.294,1.012,2.406L4.789,3.418l-2.078,1.2A7.63,7.63,0,0,0,8.8,7.64,7.637,7.637,0,0,0,16.439,0H17.1Z"
						transform="translate(0 8.297)"
						fill="#9f9f9f"
					/>
				</g>
			</g>
			<g transform="translate(21352 2671)" fill="none" stroke="#ccc" stroke-width="1">
				<rect width="87" height="27" rx="3" stroke="none" />
				<rect x="0.5" y="0.5" width="86" height="26" rx="2.5" fill="none" />
			</g>
			<g transform="translate(21380 2674)" fill="none" stroke="#ccc" stroke-width="1">
				<rect width="1" height="20" stroke="none" />
				<rect x="0.5" y="0.5" height="19" fill="none" />
			</g>
			<text
				transform="translate(21387 2684)"
				fill="#6da453"
				font-size="8"
				font-family="Arial-BoldMT, Arial"
				font-weight="700"
				letter-spacing="-0.028em"
			>
				<tspan x="0" y="0">
					256-bit SSL
				</tspan>
				<tspan x="0" y="7">
					encrypted
				</tspan>
			</text>
		</g>
	</svg>
);

const enquiryViewModel = new EnquiryViewModel();

export const Payment: React.FC<Props> = props => {
	const [formState, { text }] = useValidation(new clientpaymentModel());
	const [doingPayment, setDoingPayment] = useState<boolean>(false);

	const onSubmit = async (e: any) => {
		e.preventDefault();
		if (formState.isFormValid()) {
			if (doingPayment) return;
			const useExpress = StoresInstance.domain.EnquiryStore.useExpress;
			const addCert = StoresInstance.domain.EnquiryStore.addCert;
			const sameDay = StoresInstance.domain.EnquiryStore.sameDay;
			const price = StoresInstance.domain.EnquiryStore.getPrice(useExpress, addCert, sameDay);
			setDoingPayment(true);
			let response: any;
			if (StoresInstance.domain.EnquiryStore.isProduct) {
				response = await enquiryViewModel.purchaseProduct(
					{ ...formState.formData, amount: price },
					props.productCustomerDetails,
				);
			} else {
				response = await enquiryViewModel.upsertPaymentDetails({
					...props.productCustomerDetails,
					...formState.formData,
					amount: price,
				});
			}
			console.log(response);
			if (response === true) {
				enquiryViewModel.appointmentComplete();
				props.onComplete();
			} else if (typeof response === "string") {
				props.onErrors([response]);
			} else {
				props.onErrors([response.message]);
			}
			setDoingPayment(false);
		}
	};

	return (
		<>
			<form onSubmit={onSubmit} id="paymentForm" className={"paymentForm"} style={{ padding: "16px" }}>
				<GridSpaced container>
					<GridSpaced item xs={12} spacing={2} margin={"25px 0px 0px"}>
						<InputLabel>Card Number</InputLabel>
						<TextField
							placeholder={"Card Number"}
							fullWidth
							autoComplete="cc-number"
							{...text("cardNumber", {
								validationRules: [
									(v: string) => v.length > 0 || "This field is required",
									(v: string) => !isNaN(v as any) || "Please enter a valid card number",
								],
							})}
						/>
						{formState.showErrors("cardNumber")}
					</GridSpaced>
					<GridSpaced container xs={12} spacing={2} margin={"10px 0px 0px"} padding={"0px"}>
						<GridSpaced item xs={12} spacing={2} margin={"0px"} padding={"0px!important"}>
							<InputLabel>Expiry</InputLabel>
						</GridSpaced>
						<GridSpaced item xs={6} spacing={2} margin={"0px"} padding={"0px!important"}>
							<TextField
								placeholder={"MM"}
								fullWidth
								autoComplete="cc-exp-month"
								{...text("cardExpMonth", {
									validationRules: [
										(v: string) => v.length > 0 || "This field is required",
										(v: string) =>
											(!isNaN(v as any) && v.length <= 2 && (v as any) <= 12 && (v as any) > 0) ||
											"Please enter month in 'MM' format",
									],
								})}
							/>
							{formState.showErrors("cardExpMonth")}
						</GridSpaced>
						<GridSpaced item xs={6} spacing={2} margin={"0px"} padding={"0px!important"}>
							<TextField
								placeholder="YYYY"
								fullWidth
								autoComplete="cc-exp-year"
								{...text("cardExpYear", {
									validationRules: [
										(v: string) => v.length > 0 || "This field is required",
										(v: string) =>
											(!isNaN(v as any) &&
												((v.length === 2 &&
													(v as any) >=
														parseInt(
															new Date()
																.getFullYear()
																.toString()
																.substr(2, 2),
															10,
														)) ||
													(v.length === 4 && (v as any) >= new Date().getFullYear()))) ||
											"Please enter year in 'YYYY' format",
									],
								})}
							/>
							{formState.showErrors("cardExpYear")}
						</GridSpaced>
					</GridSpaced>
					<GridSpaced item xs={12} spacing={2} margin={"10px 0px 0px"}>
						<InputLabel>Security Code</InputLabel>
						<TextField
							placeholder={"Security Code"}
							fullWidth
							autoComplete="cc-csc"
							{...text("cardCVN", {
								validationRules: [
									(v: string) => v.length > 0 || "This field is required",
									(v: string) => !isNaN(v as any) || "Please enter a valid CVV",
								],
							})}
						/>
						{formState.showErrors("cardCVN")}
					</GridSpaced>
					<GridSpaced item xs={12} spacing={2} margin={"10px 0px 50px"}>
						<InputLabel>Cardholder Name</InputLabel>
						<TextField
							placeholder={"Cardholder Name"}
							fullWidth
							autoComplete="cc-name"
							{...text("cardHoldername", {
								validationRules: Validations.baseValidation,
							})}
						/>
						{formState.showErrors("cardHoldername")}
					</GridSpaced>
					{/* <GridSpaced item xs={12}>
						<Button type="submit">Book & Pay</Button>
					</GridSpaced> */}
					<GridSpaced item xs={12} spacing={2} margin={"10px 0px"}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className="submit"
							disabled={doingPayment}
						>
							{doingPayment ? "Loading..." : "Pay Now"}
						</Button>
					</GridSpaced>
					<GridSpaced item xs={12}>
						{props.errors.map(err => (
							<p key={err.trim().replace(" ", "_")} style={{ color: "red", margin: "0" }}>
								{err}
							</p>
						))}
					</GridSpaced>
					<div className="hr" style={{ margin: "16px 0" }} />
					{LogoSSL}
				</GridSpaced>
			</form>
		</>
	);
};
