import React from "react";
import { Header } from "../Shoothill.Components/General";
import { Container } from "@material-ui/core";

import { makeStyles, DefaultTheme } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core";
import BackgroundImage from "../Content/images/summercarebackground.png";

const useStyles = makeStyles((theme: Theme) => ({
	wrapped: {
		margin: "0",
		minHeight: "calc(100% - 70px)",
		backgroundImage: `url(${BackgroundImage})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		display: "flex",
	},
}));

//EN: use empty generic type to get default react props
export const Layout: React.FC<{}> = props => {
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<React.Fragment>
			<Header />
			<div className={classes.wrapped}>
				<Container maxWidth={false}>{props.children}</Container>
			</div>
		</React.Fragment>
	);
};
