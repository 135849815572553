import React, { useState, useContext, useEffect } from "react";
import { Theme, Fade, Button } from "@material-ui/core";
import { Centered } from "../../Shoothill.Components/General";
import { ForgotPassword /* , LoginModel, Login */ } from "../../Shoothill.Components/Account";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useDataApi, UseRouter } from "../../Shoothill.Core/Utils";
/* import { Redirect } from "react-router"; */
import { StoresContext, Stores } from "../../Stores";
import { ApiResult } from "../../Shoothill.Components/Models";
import { accountNameModel } from "../../Models/FormModels/accountNameModel";

import Logo from "../../Content/images/summerfieldLogo@2x.png";
import BackgroundImage from "../../Content/images/summercarebackground@2x.png";

// As the following is used in several places look to move to its own file
const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		backgroundRepeat: "none",
		backgroundSize: "cover",
		height: "100%",
	},
	main: {
		width: "100%",
		display: "block", // Fix IE 11 issue.
		height: "100%",
	},
	loginRegisterBox: {
		maxWidth: "300px",
		"@media (min-width: 1024px)": {
			maxWidth: "600px",
		},
		borderRadius: "9px",
		minHeight: "350px",
		/* display: "flex", */
		flexDirection: "column",
		backgroundColor: "#FFFFFF",
		flexGrow: 1,
		justifyContent: "space-between",
		/* flexBasis: 0, */
		padding: "0",
		flexWrap: "wrap",
		overflow: "hidden", // needed to get the rounded corners on the
	},
	registerBox: {
		backgroundColor: "#FFFFFF",
		display: "flex",
		flexGrow: 1,
		flexShrink: 1,
		flexDirection: "column",
		flexBasis: 0,
		minWidth: "300px",
		padding: "0px 20px 10px",
		"@media (min-width: 1024px)": {
			padding: "0px 60px 25px",
		},
	},
	summerfieldLogo: {
		width: "203px",
	},
	loginBox: {
		backgroundColor: "#263959",
		color: "#FFFFFF",
		display: "flex",
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
		flexDirection: "column",
		minWidth: "300px",
		padding: "0px 15px 15px",
		"@media (min-width: 1024px)": {
			padding: "0px 60px 25px",
		},
	},
	registerSpiel: {
		color: "#263959",
		fontSize: "1.2rem",
		fontWeight: "bold",
	},
	loginSpiel: {
		color: "#FFFFFF",
		fontSize: "2rem",
		fontWeight: "bold",
		lineHeight: "1.9rem",
	},
	orSpiel: {
		textAlign: "center",
		padding: "15px 0px 0px",
	},
	req: {
		fontSize: "16px",
	},
}));

export const ForgotPasswordPage: React.FC = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	/* const [errorMessage, setErrorMessage] = useState(""); */
	const { history /* , location, match */ } = UseRouter();
	const { data, hasLoaded /* , isLoading, isError */, doRequest } = useDataApi<ApiResult<string>>();
	const accountStore = useContext<Stores>(StoresContext).domain.AccountStore;
	const [viewStep, setViewStep] = useState(0);

	// Shouldn't be already logged in but lets check
	if (accountStore.IsLoggedIn) {
		history.push("/");
	}

	// Start Forgot Password
	const doSubmit: (resetModel: accountNameModel) => void = (resetModel: accountNameModel): void => {
		doRequest("/api/account/forgotpasswordemail", resetModel);
	};

	useEffect(() => {
		if (data !== null) {
			setViewStep(1);
		}
	}, [data, viewStep]);

	const clickBack = () => {
		setViewStep(0);
		history.push("/");
	};

	useEffect(() => {}, [hasLoaded]);

	return (
		<div className={classes.wrapper}>
			<main className={classes.main}>
				<Centered>
					<Fade in timeout={1000}>
						<div className={classes.loginRegisterBox}>
							<div className={classes.registerBox}>
								{/* 								<div>
									<img className={classes.summerfieldLogo} src={Logo}></img>
								</div> */}
								<div className={classes.registerSpiel}>
									{viewStep === 0 && (
										<>
											<ForgotPassword onSubmit={doSubmit} />
										</>
									)}

									{viewStep === 1 && (
										<>
											<h2>Email sent succesfully!</h2>
											<p className={classes.req}>
												<span>
													An email has been sent to you containing a link to reset your
													password.
													<br />
													Remember to check your junk/spam folder if nothing has appeared in
													your inbox.
												</span>
											</p>
											<div className={"PlaceHolderPart"}>
												<Button
													className={"submit"}
													onClick={clickBack}
													fullWidth
													variant="contained"
													color="primary"
												>
													Return to Login
												</Button>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</Fade>
				</Centered>
			</main>
		</div>
	);
};
