import { Grid, MenuItem, Select } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Stores, StoresContext } from "../../Stores";

import { AltSelect } from "Shoothill.Components/StylesAppSpecific/LocationSearchStyling";
import { GridSpaced } from "../../Shoothill.Components/StylesAppSpecific/Customer";
import { UseRouter } from "Shoothill.Core/Utils";
import { useObserver } from "mobx-react-lite";
import { useMediaQuery } from "../../Shoothill.Core/Utils/Media/mediaQuery";

interface IProps {
	onSubmit?: () => void;
	editable?: boolean;
}

export const AppointmentType: React.FC<IProps> = props => {
	const { onSubmit, editable: _editable } = props;

	const editable = _editable === undefined ? true : _editable;

	const { location } = UseRouter();

	const storeContext = useContext<Stores>(StoresContext);

	const isMobile = !!useMediaQuery("(max-width: 767px)");

	const [appointmentItems, setAppointmentItems] = useState<JSX.Element[]>([]);
	const [dropDownLabel, setDropDownLabel] = useState<string>(
		(() => {
			const defaultLabelAppType = storeContext.domain.AppointmentTypesStore.appointmentTypes.find(
				app => app.reference === storeContext.domain.EnquiryStore.enquiry.timeClinic.appointmentTypeReference,
			);
			if (defaultLabelAppType !== undefined) {
				return defaultLabelAppType.id!.toString();
			}
			return "";
		})(),
	);

	const handleAppointmentTypeChange = (e: any) => {
		let chosenType: string = e.target.value as string;
		setDropDownLabel(chosenType);
		let duration: number = 0;
		let cost: number = 0;
		let isProduct: boolean = false;
		let requiresId: boolean = false;
		let hasExpress: boolean = false;
		let hasCert: boolean = false;
		let allowVoucher: boolean = false;
		let showDuration: boolean = true;

		if (storeContext.domain.AppointmentTypesStore.appointmentTypes.length > 0) {
			const found = storeContext.domain.AppointmentTypesStore.appointmentTypes.find(
				a => a.id === parseInt(chosenType),
			);

			if (found) {
				storeContext.domain.EnquiryStore.enquiry.setAppointmentType(found!);
				if (found!.practitionerDetails) {
					for (let i: number = 0; i < found.practitionerDetails!.length; i++) {
						duration += found!.practitionerDetails![i]!.timeMins!;
					}
				}
				cost = found!.cost!;
				isProduct = found!.isProduct;
				requiresId = found!.requiresId;
				hasExpress = found!.isProduct && found!.expressType !== undefined && found!.expressType !== null;
				hasCert = found!.hasCertificate;
				allowVoucher = found!.canUseVoucher;

				switch (found!.id) {
					case 31:
						showDuration = false;
						break;
					default:
						showDuration = true;
						break;
				}
			} else {
				storeContext.domain.EnquiryStore.enquiry.unsetAppointmentType();
			}
		}
		if (!requiresId && storeContext.domain.EnquiryStore.requiresId) {
			storeContext.domain.EnquiryStore.setIdUpload(null);
		}
		storeContext.domain.EnquiryStore.setShowDuration(showDuration);
		storeContext.domain.EnquiryStore.setAppointmentDuration(duration);
		storeContext.domain.EnquiryStore.setAppointmentCost(cost);
		storeContext.domain.EnquiryStore.setIsProduct(isProduct);
		storeContext.domain.EnquiryStore.setRequiresId(requiresId);
		storeContext.domain.EnquiryStore.setHasExpress(hasExpress);
		storeContext.domain.EnquiryStore.setExpressCost(undefined);
		storeContext.domain.EnquiryStore.setHasCert(hasCert);
		storeContext.domain.EnquiryStore.setAllowVoucher(allowVoucher);
		onSubmit!();
		storeContext.domain.EnquiryStore.clampQuantity("");
	};

	useEffect(() => {
		async function effect() {
			let intIdFromQuery = -1;

			const setAppointmentTypeFromURL = async (appTypes: Summerfield.Data.appointmentType[]) => {
				const query = new URLSearchParams(location.search);
				if (query.has("apt") && !storeContext.domain.EnquiryStore.appointmentTypeFromURL) {
					storeContext.domain.EnquiryStore.setAppointmentTypeFromURL(true);
					let idFromQuery: number | string | undefined = undefined;
					const fromQuery = appTypes.find(a => a.bookingLink === query.get("apt"));
					if (fromQuery) {
						idFromQuery = fromQuery.id;
					}
					if (typeof idFromQuery === "number") {
						intIdFromQuery = idFromQuery;

						const groupLookup = {
							"174": "D3B453BE-B376-4460-8DA5-3BBFECFB9B6F",
							"173": "D3B453BE-B376-4460-8DA5-3BBFECFB9B6F",
						};

						const groupId = groupLookup[idFromQuery.toString()];

						if (groupId) {
							const result = await storeContext.domain.AppointmentTypesStore.GetAppointmentTypesForGroup(
								groupId,
							);
							if (result !== undefined && result !== null && result.length > 0) {
								window.requestAnimationFrame(() => {
									setAppointmentItems(
										result.map((item: Summerfield.Data.appointmentType, index: number) => {
											return (
												<>
													<option value={item.id} key={"atype_" + index}>
														{item.appointmentTypeName}
													</option>
												</>
											);
										}),
									);

									const queryLabelAppType = storeContext.domain.AppointmentTypesStore.appointmentTypes.find(
										app => app.id === idFromQuery,
									);
									if (queryLabelAppType !== undefined) {
										handleAppointmentTypeChange({
											target: { value: queryLabelAppType.id!.toString() },
										});
									}
								});

								return;
							} else {
								setAppointmentItems([]);
							}
						}
					}
				}
			};

			const result = await storeContext.domain.AppointmentTypesStore.GetAppointmentTypes(true);
			// Load the result into the state object
			if (result !== undefined && result !== null && result.length > 0) {
				await setAppointmentTypeFromURL(result);

				setAppointmentItems(
					result.map((item: Summerfield.Data.appointmentType, index: number) => {
						return (
							<>
								<option value={item.id} key={"atype_" + index}>
									{item.appointmentTypeName}
								</option>
							</>
						);
					}),
				);
			} else {
				setAppointmentItems([]);
			}

			const queryLabelAppType = storeContext.domain.AppointmentTypesStore.appointmentTypes.find(
				app => app.id === intIdFromQuery,
			);
			if (queryLabelAppType !== undefined) {
				handleAppointmentTypeChange({ target: { value: queryLabelAppType.id!.toString() } });
			}
		}

		effect();
	}, []);

	return useObserver(() => (
		<>
			<GridSpaced container spacing={2} additionalcss={"margin-bottom: 25px;"} className={"formRow"}>
				<Grid item xs={12} sm={12} md={6}>
					{editable ? (
						<>
							<AltSelect
								className={"appointmentType border-correct"}
								name={"appointmentType"}
								onChange={handleAppointmentTypeChange}
								fullWidth
								value={dropDownLabel}
								native
								fontsize={isMobile ? "14px" : "17px"}
							>
								<option>Please Choose...</option>
								{appointmentItems}
							</AltSelect>
						</>
					) : (
						<>
							{
								storeContext.domain.AppointmentTypesStore.appointmentTypes.find(
									apt => apt.id === +dropDownLabel,
								)!.appointmentTypeName
							}
						</>
					)}
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					{storeContext.domain.EnquiryStore.isProduct || !storeContext.domain.EnquiryStore.showDuration || (
						<span className={"durationcost"}>
							Duration:
							<span className={"values"}>
								{" "}
								{storeContext.domain.EnquiryStore.appointmentDuration} mins
							</span>
						</span>
					)}
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					{storeContext.domain.EnquiryStore.canBuyMultipleOfProduct || (
						<span className={"durationcost"}>
							Cost:<span className={"values"}> £{storeContext.domain.EnquiryStore.appointmentCost}</span>
						</span>
					)}
				</Grid>
			</GridSpaced>
		</>
	));
};
