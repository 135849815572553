import { AppointmentLI, AppointmentUL } from "Shoothill.Components/StylesAppSpecific/AppointmentsStyling";
import { AvailableClinics, Clinic } from "../../Shoothill.Components/App/AvailableClinics";
import { Button, Fade, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SHCModal, SHCPaper } from "../../Shoothill.Components/StylesAppSpecific/ModalStyling";
import { Stores, StoresContext } from "../../Stores";

import { AppointmentType } from "../../Shoothill.Components/App/AppointmentType";
import Backdrop from "@material-ui/core/Backdrop";
import EnquiryViewModel from "../../ViewModels/EnquiryViewModel";
import { FormatMomentString } from "../../Shoothill.Core/Utils/Format/Format";
import { GridSpaced } from "../../Shoothill.Components/StylesAppSpecific/Customer";
import { LocationSearch } from "./LocationSearch";
import { LoginEmbed } from "Shoothill.Components/Account";
import { isCovidPCRFullyVac, isCovidPCRNotFullyVac, isTestToRelease, MyDetailsForm } from "../Forms/MyDetailsForm";
import { Payment } from "../Forms/Payment";
import ProductCopy from "../../Content/ProductCopy.json";
import { StoresInstance } from "../../Stores/Stores";
import { UseRouter } from "Shoothill.Core/Utils";
import { bookingConfirmationModel } from "../../Models/FormModels";
import { useObserver } from "mobx-react-lite";

const badgeIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23">
		<defs>
			<style>
				{
					".a,.c{fill:#fff;}.a{stroke:#888;}.b{fill:#337ab7;}.b,.c{stroke:#337ab7;}.d{stroke:none;}.e{fill:none;}"
				}
			</style>
		</defs>
		<g transform="translate(-168 -474)">
			<g transform="translate(-62.5 1066.5) rotate(-90)">
				<g transform="translate(569 230)">
					<g transform="translate(0)">
						<g className="a" transform="translate(0.5 0.5)">
							<rect className="d" width="20" height="30" rx="2" />
							<rect className="e" x="0.5" y="0.5" width="19" height="29" rx="1.5" />
						</g>
					</g>
				</g>
				<g className="a" transform="translate(592.5 242.5) rotate(90)">
					<path className="d" d="M3,0H3A3,3,0,0,1,6,3V7A0,0,0,0,1,6,7H0A0,0,0,0,1,0,7V3A3,3,0,0,1,3,0Z" />
					<path
						className="e"
						d="M3,.5H3A2.5,2.5,0,0,1,5.5,3V6.143a.357.357,0,0,1-.357.357H.857A.357.357,0,0,1,.5,6.143V3A2.5,2.5,0,0,1,3,.5Z"
					/>
				</g>
				<g className="a" transform="translate(585.5 240.5)">
					<rect className="d" width="1" height="10" rx="0.5" />
					<rect className="e" x="0.5" y="0.5" height="9" />
				</g>
				<g className="a" transform="translate(588.5 244.5)">
					<circle className="d" cx="1" cy="1" r="1" />
					<circle className="e" cx="1" cy="1" r="0.5" />
				</g>
			</g>
			<path
				className="b"
				d="M182.95,494.394s-.387-4.775-2.775-4.775-2.775,4.775-2.775,4.775Z"
				transform="translate(-4.901 -2)"
			/>
			<g className="b" transform="translate(172.75 483)">
				<circle className="d" cx="2.5" cy="2.5" r="2.5" />
				<circle className="e" cx="2.5" cy="2.5" r="2" />
			</g>
			<g className="c" transform="translate(181 484)">
				<rect className="d" width="12" height="2" rx="1" />
				<rect className="e" x="0.5" y="0.5" width="11" height="1" rx="0.5" />
			</g>
			<g className="c" transform="translate(181 487)">
				<rect className="d" width="12" height="2" rx="1" />
				<rect className="e" x="0.5" y="0.5" width="11" height="1" rx="0.5" />
			</g>
			<g className="c" transform="translate(181 490)">
				<rect className="d" width="12" height="2" rx="1" />
				<rect className="e" x="0.5" y="0.5" width="11" height="1" rx="0.5" />
			</g>
		</g>
	</svg>
);

const enquiryViewModel = new EnquiryViewModel();

export const AppointmentSetup: React.FC = () => {
	const run = useRef<boolean>(false);

	if (!run.current) {
		run.current = true;

		enquiryViewModel.reset();
		StoresInstance.domain.MapStore.ResetLocation();
	}

	const [bookingStep, setBookingStep] = useState<number>(0);
	const [showModal, setShowModal] = useState<boolean>(false);

	const { history } = UseRouter();

	const storesContext = useContext<Stores>(StoresContext);

	const [registerChoicesBtnRow, setRegisterChoicesBtnRow] = useState<boolean>(true);
	const [registerComp, setRegisterComp] = useState<boolean>(false);
	const [signinComp, setSigninComp] = useState<boolean>(false);
	const [loggedIn, setLoggedIn] = useState<boolean>(storesContext.domain.AccountStore.IsLoggedIn);
	const [isAppointmentTypeEditable, setIsAppointmentTypeEditable] = useState<boolean>(true);
	const [hasLoggedIn, setHasLoggedIn] = useState<boolean>(false);
	const [productCustomerDetails, setProductCustomerDetails] = useState<any>({});
	const [isBooking, setIsBooking] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [isEmployee, setIsEmployee] = useState(false);
	const [paymentErrors, setPaymentErrors] = useState<string[]>([]);

	const onGotTime = () => {
		if (storesContext.domain.EnquiryStore.enquiry.timeClinic.startTime) {
			(async () => {
				if (storesContext.domain.AccountStore.IsLoggedIn) {
					await enquiryViewModel.fetchCustomerDetails();
				}
			})().then(() => {
				setBookingStep(2);
			});
		} else if (bookingStep === 2) {
			setBookingStep(1);
		}
	};

	const handleLogin = (loginState: boolean) => {
		storesContext.domain.EnquiryStore.doProductSingleCheck();
		enquiryViewModel.fetchCustomerDetails().then(() => {
			setRegisterChoicesBtnRow(false);
			setRegisterComp(false);
			setSigninComp(false);
			setLoggedIn(loginState);
			setHasLoggedIn(loginState);
		});
	};

	const handleCancel = (cancelVal: boolean) => {
		setIsEmployee(false);
		setRegisterChoicesBtnRow(true);
		setRegisterComp(false);
		setSigninComp(false);
		setHasLoggedIn(false);
		setIsAppointmentTypeEditable(true);
		if (loggedIn && !storesContext.domain.EnquiryStore.canBuyMultipleOfProduct) {
			onBack();
		}

		(window as any).overrideBackBtnBehavior = undefined;
	};

	const onRegBtnClick = (): void => {
		if (storesContext.domain.EnquiryStore.isProduct) {
			storesContext.domain.AccountStore.Logout(true);
			setIsAppointmentTypeEditable(false);
			storesContext.domain.EnquiryStore.setIsAllowed(true);
		}
		setRegisterChoicesBtnRow(false);
		setRegisterComp(true);
		setSigninComp(false);

		(window as any).overrideBackBtnBehavior = () => handleCancel(false);
	};

	const onEmployeeRegBtnClick = (): void => {
		setIsEmployee(true);
		onRegBtnClick();
	};

	const onSignInBtnClick = (): void => {
		if (storesContext.domain.EnquiryStore.isProduct) {
			setIsAppointmentTypeEditable(false);
		}

		if (storesContext.domain.AccountStore.IsLoggedIn) {
			storesContext.domain.EnquiryStore.doProductSingleCheck();
			enquiryViewModel.fetchCustomerDetails().then(() => {
				setHasLoggedIn(true);
			});
		} else {
			setRegisterChoicesBtnRow(false);
			setRegisterComp(false);
			setSigninComp(true);
		}

		(window as any).overrideBackBtnBehavior = () => handleCancel(false);
	};

	const resetBtns = (): void => {
		setRegisterChoicesBtnRow(true);
		setRegisterComp(false);
		setSigninComp(false);
	};

	const onBack = (): void => {
		enquiryViewModel.deleteReservedAppointment().then(() => {
			resetBtns();
			storesContext.domain.EnquiryStore.enquiry.timeClinic.resetStartTime();
			if (bookingStep > 0) {
				setBookingStep(storesContext.domain.EnquiryStore.canBuyMultipleOfProduct ? 0 : bookingStep - 1);
				if (storesContext.domain.EnquiryStore.canBuyMultipleOfProduct) {
					setIsAppointmentTypeEditable(true);
				}
			}
		});
	};

	const onClinicOptionsChange = (): void => {
		if (
			storesContext.domain.MapStore.hasAddress &&
			storesContext.domain.EnquiryStore.enquiry.isReadyForClinics &&
			!storesContext.domain.EnquiryStore.isProduct
		) {
			setBookingStep(1);
		} else {
			setBookingStep(0);
		}
	};

	const doPaymentResult = async (subVal: any) => {
		setProductCustomerDetails(subVal);
		if (StoresInstance.domain.EnquiryStore.price === 0) {
			if (isBooking) return;
			setIsBooking(true);
			let response: any;
			if (StoresInstance.domain.EnquiryStore.isProduct) {
				response = await enquiryViewModel.purchaseProduct({} as any, subVal);
			} else {
				response = await enquiryViewModel.upsertPaymentDetails({} as any);
			}
			if (response === true) {
				enquiryViewModel.appointmentComplete();
				paymentComplete();
			} else if (typeof response === "string") {
				setErrors([response]);
			} else {
				setErrors([response.message]);
			}
			setIsBooking(false);
		} else {
			openModal();
		}
		// do other stuff!
	};

	const paymentComplete = (): void => {
		history.push(`/`);
	};

	const openModal = (): void => {
		setShowModal(true);
	};

	const handleClose = () => {
		if (paymentErrors.length > 0) {
			setPaymentErrors([]);
			setShowModal(false);
		}
	};

	const paymentModal = (
		<>
			<SHCModal
				onClose={handleClose}
				open={showModal}
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<SHCPaper>
					<div className="customer-mydetails">
						<div className="customer-paymentheader">Payment Details</div>
						<Payment
							onComplete={paymentComplete}
							productCustomerDetails={productCustomerDetails}
							onErrors={errors => setPaymentErrors(errors)}
							errors={paymentErrors}
						/>
					</div>
				</SHCPaper>
			</SHCModal>
		</>
	);

	const toBook = useObserver(() => (
		<div style={{ marginTop: "25px", width: "100%" }}>
			{loggedIn && (!storesContext.domain.EnquiryStore.isProduct || hasLoggedIn) ? (
				<GridSpaced item md={12}>
					<div className="customer-mydetails">
						<MyDetailsForm
							showExtra={true}
							showPWD={false}
							saveMsg={"Confirm and Pay"}
							cancelMsg={"Cancel booking"}
							onCancel={handleCancel}
							onSubmit={doPaymentResult}
							showFurtherInfo={!storesContext.domain.EnquiryStore.isProduct}
							isProduct={storesContext.domain.EnquiryStore.isProduct}
							// disableNameAndAddress={storesContext.domain.EnquiryStore.isProduct}
							doExpressCheck={storesContext.domain.EnquiryStore.hasExpress}
							showPrice={true}
							employerReg={isEmployee}
						/>
					</div>
				</GridSpaced>
			) : (
				<GridSpaced item md={12}>
					{registerChoicesBtnRow && (
						<>
							<GridSpaced
								container
								className={
									storesContext.domain.EnquiryStore.requiresId &&
									storesContext.domain.EnquiryStore.idUpload === null
										? "to-book-disabled"
										: ""
								}
							>
								<GridSpaced item md={3} additionalcss={"padding-top: 20px; font-size: 21px;"}>
									To book this item please:
								</GridSpaced>
								<GridSpaced item md={3} additionalcss="display: flex; justify-content: flex-end;">
									<Button
										variant="contained"
										color="primary"
										className="submit"
										onClick={onEmployeeRegBtnClick}
									>
										Employee registration
									</Button>
								</GridSpaced>
								<GridSpaced item md={3} additionalcss="display: flex; justify-content: flex-end;">
									<Button
										variant="contained"
										color="primary"
										className="submit"
										onClick={onRegBtnClick}
									>
										I am a new customer
									</Button>
								</GridSpaced>
								<GridSpaced item md={3} additionalcss="display: flex; justify-content: flex-end;">
									<Button
										variant="contained"
										color="primary"
										className="submit"
										onClick={onSignInBtnClick}
									>
										Log into my account
									</Button>
								</GridSpaced>
							</GridSpaced>
						</>
					)}
					{!registerChoicesBtnRow && !signinComp && registerComp && (
						<>
							<div className="customer-mydetails">
								<MyDetailsForm
									showExtra={true}
									showPWD={true}
									saveMsg={"Register and pay"}
									cancelMsg={"Cancel Registration"}
									onCancel={handleCancel}
									onSubmit={doPaymentResult}
									showFurtherInfo={!storesContext.domain.EnquiryStore.isProduct}
									isProduct={storesContext.domain.EnquiryStore.isProduct}
									doExpressCheck={storesContext.domain.EnquiryStore.hasExpress}
									showPrice={true}
									employerReg={isEmployee}
								/>
							</div>
						</>
					)}
					{!registerChoicesBtnRow && !registerComp && signinComp && (
						<>
							<h2>Log into my account</h2>
							<div className="booking-mydetails">
								<LoginEmbed
									onSubmit={handleLogin}
									onCancel={onRegBtnClick}
									hideForgot={false}
									loginBtnText={"Log into my account"}
									cancelBtnText={"I am a new customer"}
								/>
							</div>
						</>
					)}
				</GridSpaced>
			)}
		</div>
	));

	const idUpload = () => (
		<div className={`id-upload ${storesContext.domain.EnquiryStore.idUpload === null ? "" : "uploaded"}`}>
			{storesContext.domain.EnquiryStore.idUpload === null ? (
				<>
					<p>To qualify for this kit please upload a photograph or scan of your NHS ID card</p>
					<div>
						<input
							type="file"
							accept="image/*"
							onChange={e => {
								if (e.target!.files && e.target!.files![0]) {
									storesContext.domain.EnquiryStore.setIdUpload(e.target!.files![0]);
								}
							}}
						/>
						<div className="upload-button">
							<Button variant="contained" color="primary">
								Upload photo or scan
							</Button>
						</div>
					</div>
				</>
			) : (
				<>
					<p>Thank you for uploading your photo/scan:</p>
					<div>
						{badgeIcon}
						<p className="upload-filename">{storesContext.domain.EnquiryStore.idUpload.name}</p>
					</div>
				</>
			)}
		</div>
	);

	const typeAddress = useObserver(() => (
		<>
			<GridSpaced container>
				<Grid item md={12}>
					<h2>What appointment do you want to book?</h2>
					<AppointmentType editable={isAppointmentTypeEditable} onSubmit={onClinicOptionsChange} />
					{storesContext.domain.EnquiryStore.enquiry.is18Plus && (
						<p style={{ color: "red", fontSize: "18px" }}>Patient must be 18+</p>
					)}
				</Grid>
			</GridSpaced>

			{storesContext.domain.EnquiryStore.isProduct ? (
				<>
					{storesContext.domain.EnquiryStore.canBuyMultipleOfProduct ? (
						<>
							<div className="multiple-product">
								<h3>Quantity</h3>
								<div>
									<div>
										<button onClick={() => storesContext.domain.EnquiryStore.decreaseQuantity()}>
											-
										</button>
										<span
											contentEditable
											onBlur={e =>
												storesContext.domain.EnquiryStore.clampQuantity(e.target.textContent!)
											}
										>
											{storesContext.domain.EnquiryStore.quantityString}
										</span>
										<button onClick={() => storesContext.domain.EnquiryStore.increaseQuantity()}>
											+
										</button>
									</div>
									<p>
										({storesContext.domain.EnquiryStore.boxes} box
										{storesContext.domain.EnquiryStore.boxes! > 1 ? "es" : ""})
									</p>
									<p className="price">
										Total cost: £{storesContext.domain.EnquiryStore.price.toFixed(2)} Inc. VAT
									</p>
								</div>
								<Button
									variant="contained"
									color="primary"
									className="submit"
									onClick={() => setBookingStep(2)}
									// onClick={onRegBtnClick}
								>
									Next
								</Button>
							</div>
						</>
					) : (
						<>
							{/* {hasLoggedIn || registerComp ? (
						<div className="coronavirus-note">
							<h4>Please Note:</h4>
							<p>
								The following details must be for the person taking the test to comply with CQC
								guidelines.
							</p>
						</div>
					) : ( */}
							<>
								<p
									style={{
										margin: 0,
										fontSize: "16px",
										marginBottom: storesContext.domain.EnquiryStore.requiresId ? "10px" : "0",
									}}
								>
									{ProductCopy.antibody.includes(
										storesContext.domain.EnquiryStore.enquiry.appointmentType!.id!,
									) && (
										<>
											<ul>
												<li>Must be used at least 21 days after symptoms develop.</li>
												<li>Tests for IgG Coronavirus antibodies.</li>
												<li>Antibodies suggest that you have had Coronavirus.</li>
												<li>Antibodies do not guarantee a level or duration of immunity.</li>
											</ul>
										</>
									)}
									{ProductCopy.coronavirus.includes(
										storesContext.domain.EnquiryStore.enquiry.appointmentType!.id!,
									) && (
										<>
											You are ordering a highly accurate PCR test to detect if you have COVID-19
											and are infectious to other people.
										</>
									)}
									{storesContext.domain.EnquiryStore.hasExpress && (
										<>
											<br />
											The express courier service can be added at the end of the booking process
											if your address & time is eligible.
										</>
									)}
									{isCovidPCRFullyVac(storesContext.domain.EnquiryStore.enquiry.appointmentType) && (
										<>
											Please note this service is for fully vaccinated arrivals from non-red list
											countries.
										</>
									)}
									{isCovidPCRNotFullyVac(
										storesContext.domain.EnquiryStore.enquiry.appointmentType,
									) && (
										<>
											Please note this service is for non-fully vaccinated arrivals from non-red
											list countries.
										</>
									)}
									{isTestToRelease(storesContext.domain.EnquiryStore.enquiry.appointmentType) && (
										<>
											Please note this appointment type is only available for customers returning
											to England.
										</>
									)}
								</p>
								{storesContext.domain.EnquiryStore.requiresId && idUpload()}
							</>
							{/* )} */}
							{toBook}
							{paymentModal}
						</>
					)}
				</>
			) : (
				<>
					{isTestToRelease(storesContext.domain.EnquiryStore.enquiry.appointmentType) && (
						<p
							style={{
								margin: "0 0 25px",
								fontSize: "16px",
							}}
						>
							Please note this appointment type is only available for customers returning to England.
						</p>
					)}

					{storesContext.domain.EnquiryStore.requiresId && (
						<>
							{idUpload()}
							<div className="hr product-hr" />
						</>
					)}
					<div
						className={`customer-mydetails ${
							storesContext.domain.EnquiryStore.requiresId &&
							storesContext.domain.EnquiryStore.idUpload === null
								? "to-book-disabled"
								: ""
						}`}
					>
						<div className="mydetails-form">
							<LocationSearch onSubmit={onClinicOptionsChange} />
						</div>
					</div>
				</>
			)}
		</>
	));

	const clinics = (
		<>
			<h2>Please select one from the available appointments below:</h2>
			<AvailableClinics on_got_time={onGotTime} />
		</>
	);

	if (bookingStep === 2) {
		(window as any).backBehavior = () => onBack();
	} else {
		(window as any).backBehavior = undefined;
	}

	switch (bookingStep) {
		case 0:
			return useObserver(() => <>{typeAddress}</>);
		case 1:
			return useObserver(() => (
				<>
					{typeAddress}
					{clinics}
				</>
			));
		case 2:
			return useObserver(() => (
				<>
					<GridSpaced container>
						<GridSpaced item md={12}>
							<h2>
								{storesContext.domain.EnquiryStore.canBuyMultipleOfProduct
									? "Summary"
									: "Appointment summary"}
							</h2>
							<AppointmentType editable={false} />

							<div className="customer-myappointments">
								<AppointmentUL margin={"0px"} padding={"0px"}>
									<Clinic
										show_back
										clinic_ref={
											storesContext.domain.EnquiryStore.enquiry.timeClinic.clinicReference
										}
										class_name="highlighted"
										on_back={onBack}
										time={storesContext.domain.EnquiryStore.enquiry.timeClinic.startTime}
										multipleProduct={storesContext.domain.EnquiryStore.canBuyMultipleOfProduct}
									/>
								</AppointmentUL>
							</div>
						</GridSpaced>
						{toBook}
						{errors.map(e => (
							<p style={{ color: "red" }}>{e}</p>
						))}
					</GridSpaced>
					{paymentModal}
				</>
			));
		default:
			return null;
	}
};
