import React, { useState, useContext, useEffect } from "react";
import * as ReactRouterDom from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { UseRouter, useDataApi } from "../../Shoothill.Core/Utils";
import { TextField, useMediaQuery, Link, makeStyles, Theme, useTheme, Fade } from "@material-ui/core";
import styled from "styled-components";

import { useValidation, usePercentageHeightOfWidth } from "../../Shoothill.Core/Utils";
import validator from "validator";
import { accountNameModel } from "../../Models/FormModels/accountNameModel";
import { observable } from "mobx";
import { StoresContext, Stores } from "../../Stores";
import { ApiResult } from "../../Models";
import { LoginModel, ForgotPasswordResetForm, Login } from "../../Shoothill.Components/Account";
import { AccountStore } from "../../Stores/Domain/AccountStores";
import { forgotPasswordModel } from "../../Models/FormModels/forgotPasswordModel";
import { Centered } from "../../Shoothill.Components/General";
import { useObserver, useComputed } from "mobx-react-lite";

import Logo from "../../Content/images/summerfieldLogo@2x.png";
import BackgroundImage from "../../Content/images/summercarebackground@2x.png";

// As the following is used in several places look to move to its own file
const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		backgroundRepeat: "none",
		backgroundSize: "cover",
		height: "100%",
	},
	main: {
		width: "100%",
		display: "block", // Fix IE 11 issue.
		height: "100%",
	},
	loginRegisterBox: {
		maxWidth: "300px",
		"@media (min-width: 1024px)": {
			maxWidth: "600px",
		},
		borderRadius: "9px",
		minHeight: "350px",
		/* display: "flex", */
		flexDirection: "column",
		backgroundColor: "#FFFFFF",
		flexGrow: 1,
		justifyContent: "space-between",
		/* flexBasis: 0, */
		padding: "0",
		flexWrap: "wrap",
		overflow: "hidden", // needed to get the rounded corners on the
	},
	registerBox: {
		backgroundColor: "#FFFFFF",
		display: "flex",
		flexGrow: 1,
		flexShrink: 1,
		flexDirection: "column",
		flexBasis: 0,
		minWidth: "300px",
		padding: "0px 20px 10px",
		"@media (min-width: 1024px)": {
			padding: "0px 60px 25px",
		},
	},
	summerfieldLogo: {
		width: "203px",
	},
	loginBox: {
		backgroundColor: "#263959",
		color: "#FFFFFF",
		display: "flex",
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
		flexDirection: "column",
		minWidth: "300px",
		padding: "0px 15px 15px",
		"@media (min-width: 1024px)": {
			padding: "0px 60px 25px",
		},
	},
	registerSpiel: {
		color: "#263959",
		fontSize: "1.2rem",
		fontWeight: "bold",
	},
	loginSpiel: {
		color: "#FFFFFF",
		fontSize: "2rem",
		fontWeight: "bold",
		lineHeight: "1.9rem",
	},
	orSpiel: {
		textAlign: "center",
		padding: "15px 0px 0px",
	},
}));

export interface IMatchParams {
	email?: string;
	token1?: string;
	token2?: string;
}

export const ForgotPasswordComplete: React.FC<ReactRouterDom.RouteComponentProps<IMatchParams>> = props => {
	const theme = useTheme();
	const classes = useStyles(theme);

	const [tokensPresent, setTokensPresent] = useState(false);
	const [outcomeDone, setOutcomeDone] = useState(false);
	const { history } = UseRouter();

	// Tokens present?
	useEffect(() => {
		if (props.match.params.token1 !== undefined && props.match.params.token2 !== undefined) {
			setTokensPresent(true);
		} else {
			history.push("/pro");
		}
	});

	let token1: string = props.match.params.token1 !== undefined ? props.match.params.token1 : "";
	let token2: string = props.match.params.token2 !== undefined ? props.match.params.token2 : "";
	let email: string = props.match.params.email !== undefined ? props.match.params.email : "";

	const { data, hasLoaded, isLoading, isError, doRequest } = useDataApi<ApiResult<string>>();
	const [errorMessage, setErrorMessage] = useState("");

	// Shouldn't be already logged in but lets check
	const accountStore: AccountStore = useContext<Stores>(StoresContext).domain.AccountStore;
	let isLoggedIn: boolean = accountStore.IsLoggedIn;

	// Submit updated stuff to API
	const { data: userSet, doRequest: userRequest } = useDataApi();
	const handleResetPassword: (forgotpasswordModel: forgotPasswordModel) => void = (
		forgotpasswordModel: forgotPasswordModel,
	): void => {
		userRequest("/api/account/forgotpasswordreset", forgotpasswordModel);
	};
	useEffect(() => {
		if (userSet !== null) {
			setOutcomeDone(true);
		}
	}, [userSet, outcomeDone]);

	// Login Stuff
	const { data: loggedin, doRequest: doLoginRequest, isLoading: loginLoader } = useDataApi<ApiResult>();
	const handleLogin: (loginModel: LoginModel) => void = (loginModel: LoginModel): void => {
		doLoginRequest("/api/account/login", loginModel);
	};
	useEffect(() => {
		if (isLoggedIn) {
			history.push("../");
		}
	}, [isLoggedIn, loggedin]);

	useEffect(() => {
		const checkLoginReturn: () => any = (): any => {
			if (loggedin !== null) {
				if (loggedin.wasSuccessful) {
					accountStore.getLoginState(loggedin.payload);
				} else {
					if (loggedin.errors.length > 0) {
						setErrorMessage("* " + loggedin.errors[0].message);
					} else {
						setErrorMessage("* Unknown error has occurred.");
					}
				}
			}
		};
		checkLoginReturn();
	}, [loggedin]);
	// Login end

	return useObserver(() => (
		<>
			<div className={classes.wrapper}>
				<main className={classes.main}>
					<Centered>
						<Fade in timeout={1000}>
							<div className={classes.loginRegisterBox}>
								<div className={classes.registerBox}>
									<div className={classes.registerSpiel}>
										{tokensPresent && !outcomeDone && (
											<>
												<ForgotPasswordResetForm
													onSubmit={handleResetPassword}
													token1={token1}
													token2={token2}
													UserEmail={email}
												/>
											</>
										)}

										{tokensPresent && outcomeDone && (
											<>
												{userSet && userSet.wasSuccessful ? (
													<>
														Your password has been reset
														<br />
														<br />
														Please login
													</>
												) : (
													<>{userSet && userSet.errors[0].message}</>
												)}
											</>
										)}
									</div>
								</div>
							</div>
						</Fade>
					</Centered>
				</main>
			</div>
		</>
	));
};
