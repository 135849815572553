import { AmendBookingBTN, AppointmentLI, AppointmentUL } from "../StylesAppSpecific/AppointmentsStyling";
import FileIcon, { defaultStyles } from "react-file-icon";
import { GridSpaced, PlaceHolderHR } from "../StylesAppSpecific/Customer";
import React, { useContext, useEffect, useState } from "react";
import { Stores, StoresContext, StoresInstance } from "../../Stores";

import { ApiResult } from "../../Shoothill.Components/Models";
import { AppUrls } from "../../Globals";
import EnquiryViewModel from "../../ViewModels/EnquiryViewModel";
import { Redirect } from "react-router";
import { useDataApi } from "../../Shoothill.Core/Utils";
import { TextField, Button } from "@material-ui/core";
import { AppointmentVouchers } from "Models/Domain/AppointmentVouchers";
import { runInAction } from "mobx";

export const MyAppointments: React.FunctionComponent = () => {
	const [appointments, setAppointments] = useState<any[]>([]);
	const [resulsts, setResults] = useState<any>({});
	const { data, hasLoaded, isLoading, isError, doRequest } = useDataApi<ApiResult<any>>();
	const {
		data: resultsData,
		hasLoaded: hasResultsLoaded,
		isLoading: isResultsLoading,
		isError: isResultsError,
		doRequest: doResultsRequest,
	} = useDataApi<ApiResult<any>>();
	const storesContext = useContext<Stores>(StoresContext);
	const accountStore = storesContext.domain.AccountStore;
	const enquiryStore = storesContext.domain.EnquiryStore;
	const [voucherCode, setVoucherCode] = useState("");
	const [vouchers] = useState(() => new AppointmentVouchers(true));
	const [voucherError, setVoucherError] = useState("");

	useEffect(() => {
		if (hasLoaded) {
			if (data.wasSuccessful) {
				setAppointments(data.payload.reverse());
			}
		}
	}, [hasLoaded]);

	useEffect(() => {
		if (hasResultsLoaded) {
			if (resultsData && resultsData.wasSuccessful) {
				setResults(resultsData.payload);
			}
		}
	});

	useEffect(() => {
		doRequest(AppUrls.Server.Api.Account.Activity);
		doResultsRequest(AppUrls.Server.Api.Account.TestResults);
	}, []);

	const redeemVoucherCode = async () => {
		runInAction(() => {
			vouchers.voucherCode = voucherCode;
		});
		if (!(await vouchers.checkCode())) {
			setVoucherError("Please use this code when booking an appointment.");
		} else {
			if (!vouchers.isValid) {
				setVoucherError("Invalid voucher.");
			} else {
				setVoucherError("");
				const response = await new EnquiryViewModel().redeemVoucher(voucherCode);
				if (response && response.wasSuccessful) {
					history.go(0);
				} else if (response && response.errors && response.errors[0] && response.errors[0].message) {
					setVoucherError(response.errors[0].message);
				} else {
					setVoucherError("An unexpected error occured.");
				}
			}
		}
	};

	if (!accountStore.IsLoggedIn) {
		return <Redirect to={"/"} />;
	}

	const downloadFile = (docRef: string) => {
		document.cookie = `.tempAuth=${(window as any).jwt};max-age=30;secure;samesite=strict`;
		window.location.href = `./api/customer/downloadtestresult?documentId=${docRef}`;
	};

	const Item = ({ index }: { index: number }) => (
		<>
			<AppointmentLI
				margin={"0px 0px 20px"}
				padding={"6px"}
				additionalcss={"width: 100%;"}
				className={
					appointments[index].reference === enquiryStore.enquiry.enquiryDetails.appointmentReference &&
					enquiryStore.enquiry.enquiryDetails.appointmentStatus === 2
						? "highlighted"
						: ""
				}
			>
				<GridSpaced container margin={"0"}>
					<GridSpaced xs={12} padding={"9px"}>
						<div className={"appointment"}>{appointments[index].text}</div>
						{appointments[index].address !== undefined && (
							<div className={"location"}>
								{`${appointments[index].address.addressLine1}${
									appointments[index].address.addressLine2
										? `, ${appointments[index].address.addressLine2}`
										: ""
								}${
									appointments[index].address.addressLine3
										? `, ${appointments[index].address.addressLine3}`
										: ""
								}${
									appointments[index].address.townOrCity
										? `, ${appointments[index].address.townOrCity}`
										: ""
								}${
									appointments[index].address.county ? `, ${appointments[index].address.county}` : ""
								}${
									appointments[index].address.postcode
										? `, ${appointments[index].address.postcode}`
										: ""
								}`}
							</div>
						)}
						<PlaceHolderHR margin={"5px 0px"} />
						{appointments[index].duration !== undefined && (
							<div className={"duration"} style={{ fontWeight: "bold" }}>
								Duration: <span style={{ color: "black" }}>{appointments[index].duration}</span>
							</div>
						)}
						<div className={"cost"} style={{ fontWeight: "bold" }}>
							Cost: <span style={{ color: "black" }}>{`£${appointments[index].cost.toFixed(2)}`}</span>
						</div>
					</GridSpaced>
					{appointments[index].hasTestResults && (
						<>
							<GridSpaced xs={12} padding="0 9px 9px" additionalcss="height: 100%;">
								{isResultsLoading ? (
									<>
										<div className="result-await">Loading Result Status...</div>
									</>
								) : resulsts[appointments[index].reference] ? (
									resulsts[appointments[index].reference].map((res: any) => (
										<>
											<div className="result" onClick={() => downloadFile(res.documentId)}>
												<FileIcon
													extension={extension(res.filename)}
													{...(defaultStyles[extension(res.filename)] || [])}
													size={25}
												/>
												<div className="link" role="button" tabIndex={0}>
													{res.filename}
												</div>
											</div>
										</>
									))
								) : (
									<>
										<div className="result-await">Awaiting Results</div>
									</>
								)}
							</GridSpaced>
						</>
					)}
				</GridSpaced>
			</AppointmentLI>
		</>
	);

	return (
		<>
			<div className="hr" />
			<div className="customer-myappointments">
				<div className="myappointments-header">
					<h2>My activity</h2>
					<p>
						Should you wish to change or cancel an appointment please call us on <span>0333 9000 010</span>
					</p>
					<div className="voucher-code-div">
						<p>I have been given a voucher code:</p>
						<TextField
							value={voucherCode}
							onChange={e => setVoucherCode(e.target.value)}
							placeholder="Voucher code"
							className="voucher-code-input"
							variant="outlined"
						/>
						<Button
							variant="contained"
							color="primary"
							onClick={redeemVoucherCode}
							className="voucher-code-btn"
						>
							Redeem
						</Button>
					</div>
					<p style={{ color: "red" }}>{voucherError}</p>
				</div>
				<AppointmentUL margin={"0px"} padding={"0px"}>
					{appointments.length === 0 ? (
						<>
							<p>{isLoading ? "Loading Activity..." : "No Activity"}</p>
						</>
					) : (
						appointments.map((app, index) => (
							<div key={(app.text as string).replace(" ", "_").trim()}>
								<Item index={index} />
							</div>
						))
					)}
				</AppointmentUL>
			</div>
		</>
	);
};

function extension(filename: string) {
	const arr = filename.split(".");
	return arr[arr.length - 1];
}
