import React, { useState, useContext /* , useEffect */ } from "react";
import { useObserver /* , useComputed */ } from "mobx-react-lite";
import { Theme, /* Fade,  */ Button } from "@material-ui/core";
import { Centered } from "../../Shoothill.Components/General/";
import { makeStyles, useTheme } from "@material-ui/styles";
import { /* useDataApi, */ UseRouter } from "../../Shoothill.Core/Utils";
import { Redirect } from "react-router";
import { StoresContext, Stores, StoresInstance } from "../../Stores";
/* import { ApiResult } from "../../Shoothill.Components/Models"; */

import BackgroundImage from "../../Content/images/summercarebackground.png";
import Logo from "../../Content/images/summerfieldLogo@2x.png";
import { CustomerWrapper, PlaceHolderHR } from "../../Shoothill.Components/StylesAppSpecific/Customer";
import { MyDetails } from "./MyDetails";
import { MyAppointments } from "./MyAppointments";
/* import { SimpleModal } from "../../Shoothill.Components/General/SimpleModal";

import { AvailableSlots } from "../../Shoothill.Components/App/AvailableSlots";
import { WhatAppointment } from "../../Shoothill.Components/App/WhatAppointment"; */

import { newAppointmentModel } from "../../Models/FormModels/newAppointmentModel";

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		backgroundImage: `url(${BackgroundImage})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
	},
	main: {
		width: "100%",
		display: "block", // Fix IE 11 issue.
	},
}));

export const CustomerSummary: React.FunctionComponent = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	/* const [errorMessage, setErrorMessage] = useState<string>(""); */
	const { history, location } = UseRouter();
	/* const { data, hasLoaded, isLoading, isError, doRequest } = useDataApi<ApiResult<string>>(); */
	const accountStore = useContext<Stores>(StoresContext).domain.AccountStore;
	/* const customerStore = useContext<Stores>(StoresContext).domain.CustomerStore; */

	/* const [openModal, setOpenModal] = useState<boolean>(false); */

	/* 	useEffect(() => {
		// TODO CMR
	}, [hasLoaded]);

	useEffect(() => {}, [hasLoaded]);


	}; */
	if (!accountStore.IsLoggedIn) {
		return <Redirect to={"/"} />;
	}

	const navigateToBooking = () => {
		history.push("/booking");
	};

	/* const [appointmentStep, setAppointmentStep] = useState<number>(0); */
	const [showSlots, setShowSlots] = useState<boolean>(false);
	const [locale, SetLocale] = useState<string>("");

	// Start Appointment process
	const doSubmit: (newAppointment: newAppointmentModel) => void = (newAppointment: newAppointmentModel): void => {
		SetLocale(newAppointment.location);
		setShowSlots(true);
	};

	return useObserver(() => (
		<>
			<div className={classes.wrapper}>
				<main className={classes.main}>
					<Centered>
						<CustomerWrapper backgroundcolor="#ffffff" padding="55px 46px" fontcolor="#263959">
							<div className="customer-header">
								<div className="customer-header-img">
									<img className="summerfieldLogo" src={Logo}></img>
								</div>
								<div className="customer-header-title">
									<h1>My Account</h1>
								</div>
								<div className="customer-header-btn">
									{StoresInstance.domain.AccountStore.ChangeDetailsOnly || (
										<Button
											type="submit"
											variant="contained"
											color="primary"
											className="submit"
											onClick={navigateToBooking}
										>
											Make a new booking
										</Button>
									)}
								</div>
								<MyDetails />
								{StoresInstance.domain.AccountStore.ChangeDetailsOnly || (
									<>
										<MyAppointments />
										<PlaceHolderHR additionalcss="text-align: center;">
											<Button
												type="submit"
												variant="contained"
												color="primary"
												className="submit"
												onClick={navigateToBooking}
											>
												Make a new booking
											</Button>
										</PlaceHolderHR>
									</>
								)}
							</div>
						</CustomerWrapper>
					</Centered>
				</main>
			</div>
		</>
	));
};
