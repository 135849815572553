// Libs
import axios, * as Axios from "axios";

// App
import { MergeDefaultConfig } from "./MergeDefaultConfig";
import { CheckHttpStatus } from "./CheckHttpStatus";
import * as Models from "Shoothill.Components/Models";

export const Get = <TPayload = undefined>(
	url: string,
	model?: any,
	config?: Axios.AxiosRequestConfig,
): Promise<Models.ApiResult<TPayload>> => {
	const requestConfig = MergeDefaultConfig(config);
	requestConfig.headers = {
		Authorization: "Bearer " + (window as any).jwt,
	};

	const postPromise = axios
		.get<Models.ApiResult<TPayload>>(url, { params: model })
		.then(response => {
			CheckHttpStatus(response);
			return response.data;
		});

	return postPromise;
};

export const GetRaw = <TPayload = undefined>(
	url: string,
	model?: any,
	config?: Axios.AxiosRequestConfig,
): Promise<TPayload> => {
	const requestConfig = MergeDefaultConfig(config);
	requestConfig.headers = {
		Authorization: "Bearer " + (window as any).jwt,
	};

	const postPromise = axios
		.get<TPayload>(url, { params: model })
		.then(response => {
			CheckHttpStatus(response);
			return response.data;
		});

	return postPromise;
};
