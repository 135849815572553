import { useRef, useEffect } from "react";

declare var grecaptcha: any;

function useGRecaptcha(callback: (token: string) => void) {
	const mousePos = useRef<[number, number]>([-1, -1]);

	useEffect(() => {
		(window as any).reCaptchaV2Callback = reCaptchaV2Callback;
		addEventListener("mousemove", onMouseMove);

		return () => {
			removeEventListener("mousemove", onMouseMove);
		};
	}, []);

	async function reCaptchaV2Callback(token: string) {
		if (token) {
			grecaptcha.reset();
			callback(token);
		}
	}

	function onMouseMove(e: MouseEvent) {
		e = e || (window.event as any);

		var pageX = e.pageX;
		var pageY = e.pageY;

		// IE 8
		if (pageX === undefined) {
			pageX =
				e.clientX + window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;
			pageY =
				e.clientY + window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		}

		mousePos.current = [pageX, pageY];
	}

	async function onGRecaptcha() {
		const iid = setInterval(() => {
			const els = Array.prototype.slice.call(document.getElementsByTagName("iframe"));
			const grecaptcha: HTMLIFrameElement = els.find(
				(el: HTMLIFrameElement) => el.title === "recaptcha challenge",
			);
			if (grecaptcha) {
				clearInterval(iid);
				const grecaptchaStyleDiv = grecaptcha.parentElement!.parentElement!;
				let setCount = 0;
				const iid1 = setInterval(() => {
					if (getComputedStyle(grecaptchaStyleDiv).top === "10px") {
						const toTop =
							mousePos.current[1] - window.pageYOffset ||
							document.documentElement.scrollTop ||
							document.body.scrollTop ||
							0;
						grecaptchaStyleDiv.style.width = "initial";
						grecaptchaStyleDiv.style.right = "initial";
						grecaptchaStyleDiv.style.left =
							mousePos.current[0] + grecaptcha.offsetLeft > window.innerWidth
								? `${(grecaptcha.offsetLeft + window.innerWidth) / 2}px`
								: `${mousePos.current[0]}px`;
						if (toTop > grecaptcha.offsetHeight) {
							grecaptchaStyleDiv.style.top = `${mousePos.current[1] - grecaptcha.offsetHeight}px`;
						} else {
							grecaptchaStyleDiv.style.top = `${mousePos.current[1]}px`;
						}
						if (++setCount === 2) clearInterval(iid1);
					}
				}, 1);
				/* const iid1 = setInterval(() => {
					if (getComputedStyle(grecaptchaStyleDiv)!.top === "10px") {
						clearInterval(iid1);
						grecaptchaStyleDiv.style.top = "60px";
					}
				}, 1) */
			}
		}, 1);
	}

	return () => {
		grecaptcha.execute();
		onGRecaptcha();
	};
}

export default useGRecaptcha;
