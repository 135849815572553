export const Removespaces = (contentString: string, replacewith: string) => {
	let defaultreplacewith = "";

	if (replacewith != null) {
		defaultreplacewith = replacewith;
	}

	const occurencies = contentString.indexOf(defaultreplacewith);

	if (occurencies < 0) {
		return contentString;
	}

	const returnStr = contentString.replace(" ", defaultreplacewith);

	return returnStr;
};
