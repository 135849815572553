import { observable, action } from "mobx";

export class TimeClinic {
	@observable public appointmentTypeReference: string = "";
	@observable public clinicReference: string = "";
	@observable public startTime?: string | Date;

	constructor() {
		//console.log(this);
	}

	@action
	public setClinicReference(ref: string) {
		this.clinicReference = ref;
	}

	@action
	public setStartTime(time: string | Date) {
		this.startTime = time;
	}

	@action
	public resetStartTime() {
		this.startTime = undefined;
	}
}
