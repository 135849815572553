import React, { useEffect } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import {
	LoginPaper,
	LoginTitle,
	ForgotPwd,
	LoginTextfield,
} from "../../Shoothill.Components/StylesAppSpecific/LoginReg";
import { Typography, FormControl, InputLabel, Button, TextField, Box, InputAdornment, Link } from "@material-ui/core";

import { LoginModel } from "./LoginModel";
import { useValidation } from "../../Shoothill.Core/Utils";
import validator from "validator";

interface Props {
	onSubmit: (loginModel: LoginModel) => void;
	isLoading: boolean;
	errorMessage?: string;
}
export const Login: React.FC<Props> = props => {
	const { onSubmit, isLoading, errorMessage = "", ...rest } = props;

	const [formState, { text, checkbox, select, password, email }] = useValidation<LoginModel>({
		emailAddress: "",
		password: "",
	} as any);

	const doSubmit = (e: any) => {
		e.preventDefault();
		if (formState.isFormValid()) onSubmit(formState.formData);
	};

	useEffect(() => {
		if (formState.blurred.emailAddress) {
			//console.log("Blurred");
		}
	}, [formState.blurred.emailAddress]);

	return (
		<LoginPaper className="login">
			<LoginTitle>Log in</LoginTitle>
			<form onSubmit={doSubmit}>
				{errorMessage.length > 0 && (
					<Box pb={1} pt={1}>
						<Typography component="h6" color="error" variant="caption">
							{errorMessage}
						</Typography>
					</Box>
				)}
				<LoginTextfield
					id={"loginTextbox"}
					className="loginTextbox"
					placeholder="Your e-mail address"
					fullWidth
					autoComplete="newemail"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<MailOutlinedIcon />
							</InputAdornment>
						),
					}}
					{...email("emailAddress", {
						validationRules: [
							(v: string) => validator.isEmail(v) || "Email address is invalid",
							(v: string) => (v && v.length > 0) || "Email address is required",
						],
					})}
				/>
				{formState.showErrors("emailAddress", "error", 2)}

				{/* EN: Example of a contrik without Textfield */}
				<FormControl margin="normal" fullWidth>
					<InputLabel htmlFor="password">Password</InputLabel>
					<LoginTextfield
						fullWidth
						id={"loginPassword"}
						className="loginTextbox"
						placeholder="Password"
						autoComplete="newpassword"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<LockOutlinedIcon />
								</InputAdornment>
							),
						}}
						{...password("password", {
							validationRules: [(v: string) => (v && v.length > 0) || "Password is required"],
						})}
					/>
					{formState.showErrors("password", "error")}
				</FormControl>
				<Button type="submit" fullWidth variant="contained" color="primary" className="submit">
					{isLoading ? "Please Wait..." : "Log into my account"}
				</Button>

				<ForgotPwd>
					<Link href="/account/forgotpassword">Forgot password?</Link>
				</ForgotPwd>
			</form>
		</LoginPaper>
	);
};
