// Libs
import { action, computed, observable } from "mobx";
import { locationSearchFormModel } from "Models/FormModels";

// App
export class PWEnquiry {
	private enquiryId: number = -1;
	@observable public locationText: string = "";
	@observable public latitiude?: number;
	@observable public longitude?: number;
	@observable public travelDistanceMiles?: number;

	public toDto(): Object {
		return {
			locationText: this.locationText,
			location: {
				latitude: this.latitiude,
				longitude: this.longitude,
			},
			travelDistanceMiles: this.travelDistanceMiles,
		};
	}

	@action
	public setLocation(location: locationSearchFormModel) {
		this.latitiude = location.latitude;
		this.longitude = location.longitude;
		this.locationText = location.location;
		this.travelDistanceMiles = location.distance;
	}

	@computed
	public get getDistance() {
		return this.travelDistanceMiles;
	}
}
