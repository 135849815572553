import { ApiResult } from "../../Shoothill.Components/Models";
import { Stores } from "../Stores";
import * as Models from "../../Models";
import * as MobX from "mobx";
import * as ServerApi from "../../ServerApi";

// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";
import { InitialState, Domain } from "../../Models";
import { EnquiryStore } from "./EnquiryStore";
import { domainToASCII } from "url";

export class ClinicStore extends BaseStore {
	@observable public availableClinic = observable<Summerfield.Data.apmClinic>([]);

	public enquiryModel = observable<Models.Domain.Enquiry>([]);

	@observable private isAPILoading: boolean = false;

	public constructor() {
		super();
	}

	public init(stores: Stores, initialState: InitialState) {
		this.stores = stores;
	}

	@action
	public GetAvailableClinics(enquiry: Summerfield.Data.pwenquiry, start?: number): Promise<any> {
		/* let enquiry: Summerfield.Data.pwenquiry; //Summerfield.Data.enquiry;
		enquiry = this.stores!.domain.EnquiryStore.enquiry.toDTO(); */
		if (this.isAPILoading === false) {
			this.isAPILoading = true;
			let task1 = ServerApi.Clinic.Test2(enquiry, start)
				.then((apmClinics: ApiResult) => {
					if (apmClinics.wasSuccessful === true) {
						this.isAPILoading = false;
					} else {
						// TODO CMR
						alert("Failed to get the Available clinics");
					}

					return apmClinics.payload;
				})
				.catch((error: any) => {
					let jeff = error;
					// TODO CMR
					alert("Excepted. Failed to get the Available clinics. " + error + " \n" + enquiry);
					this.isAPILoading = false;
				});

			/*
			let task = ServerApi.Clinic.GetAvailableClinicsByComponents(_enquiry) // .GetAvailableClinics(enquiry)
				.then((apmClinics: ApiResult<Summerfield.Data.apmClinic[]>) => {
					if (apmClinics.wasSuccessful === true) {
						this.availableClinic.length = 0;
						this.availableClinic = apmClinics.payload;
						this.isAPILoading = false;
					} else {
						// TODO CMR
						alert("Failed to get the Available clinics");
					}

					return apmClinics.payload;
				})
				.catch((error: any) => {
					let jeff = error;
					// TODO CMR
					alert("Excepted. Failed to get the Available clinics. " + error + " \n" + enquiry);
					this.isAPILoading = false;
				});
			*/
			return task1;
		}
		return new Promise(resolve => {
			resolve(this.availableClinic);
		});
	}

	@action
	public ResetAvailableClinics(): void {
		this.availableClinic.replace([]);
	}
}
