import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import "./Home.css";
import { Stores, StoresContext } from "../Stores";
import { LoginRegister } from "../Shoothill.Components/App/LoginRegister";
import { CustomerSummary } from "../Shoothill.Components/App/CustomerSummary";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		margin: "0px",
		height: "100%",
	},
	main: {
		width: "100%",
		display: "block", // Fix IE 11 issue.
		height: "100%",
	},
}));

export const Home: React.FC = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const storesContext = useContext<Stores>(StoresContext);
	const IsLoggedIn: boolean = storesContext.domain.AccountStore.IsLoggedIn;

	return (
		<>
			<Helmet>
				<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
				<meta http-equiv="X-UA-Compatible" content="IE=11" />
			</Helmet>
			<div className={classes.wrapper}>
				<main className={classes.main}>{IsLoggedIn ? <CustomerSummary /> : <LoginRegister />}</main>
			</div>
		</>
	);
};
