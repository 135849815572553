import React from "react";
import styled from "styled-components";

interface Props {
	children: any;
}

export const CenteredBase: any = styled.div`
	height: 100%;
	display: flex;
	flex: 1 1 auto;
	vertical-align: middle;

	align-items: center;
	justify-content: center;

	.centered-item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;

		@media (min-width: 1025px) {
			padding: 30px;
		}
	}
`;

export const Centered = ({ children }: Props) => {
	return (
		<CenteredBase>
			<div className="centered-item">{children}</div>
		</CenteredBase>
	);
};
