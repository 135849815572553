import { TimeClinic } from "./TimeClinic";
import { observable, action, autorun } from "mobx";

export class EnquiryDetails extends TimeClinic {
	@observable public appointmentReference: string = "";
	@observable public appointmentStatus: number = -1;
	@observable public enquiryReference: string = "";
	@observable public noteContent: string = "";

	public toDto() {
		return {
			appointmentReference: this.appointmentReference,
			appointmentStatus: this.appointmentStatus,
			reference: this.enquiryReference,
			note: {
				noteContent: this.noteContent || "",
			},
		};
	}

	@action
	public fromDto(model: EnquiryDetails) {
		this.appointmentReference = model.appointmentReference;
		this.appointmentStatus = model.appointmentStatus;
		this.appointmentTypeReference = model.appointmentTypeReference;
		this.clinicReference = model.clinicReference;
		this.enquiryReference = model.enquiryReference;
		this.startTime = model.startTime;
		this.noteContent = model.noteContent;
	}

	@action setNoteContent(note: string) {
		this.noteContent = note;
	}
}
