import React, { useEffect, useState, useContext } from "react";
import { Typography, FormControl, TextField, Button, Box, Link, InputLabel } from "@material-ui/core";
import { GridSpaced } from "../../Shoothill.Components/StylesAppSpecific/Customer";

import { LoginModel } from "./LoginModel";
import { useValidation, UseRouter, useDataApi } from "../../Shoothill.Core/Utils";
import validator from "validator";
import { ApiResult } from "Models";
import { StoresContext, Stores, StoresInstance } from "../../Stores";
import { AppUrls } from "../../Globals";
import { runInAction } from "mobx";

interface IProps {
	onSubmit: (isLoggedin: boolean) => void;
	onCancel: (onCancel: boolean) => void;
	loginBtnText?: string;
	cancelBtnText?: string;
	hideForgot: boolean;
}

export const LoginEmbed: React.FC<IProps> = props => {
	const { onSubmit, onCancel, loginBtnText = "Login", cancelBtnText = "Cancel", hideForgot = true } = props;
	const [errorMessage, setErrorMessage] = useState("");
	const { history } = UseRouter();
	const { data, hasLoaded, isLoading, isError, doRequest } = useDataApi<
		ApiResult<
			| string
			| {
					changeDetails: boolean;
					detailsToken: string;
					customerDetails: Summerfield.Data.customer;
			  }
		>
	>();
	const accountStore = useContext<Stores>(StoresContext).domain.AccountStore;

	const [formState, { password, email }] = useValidation<LoginModel>({
		emailAddress: "",
		password: "",
	} as any);

	const doSubmit: (e: any) => void = (e: any): void => {
		e.preventDefault();
		if (formState.isFormValid()) {
			doRequest(AppUrls.Server.Api.Account.Login, formState.formData);
		}
	};

	useEffect(() => {
		checkLoginReturn();
	}, [hasLoaded]);

	const checkLoginReturn = () => {
		if (data !== null) {
			if (data.wasSuccessful) {
				if (typeof data.payload === "string") {
					accountStore.getLoginState(data.payload);
					onSubmit(accountStore.IsLoggedIn);
				} else if (data.payload.detailsToken) {
					runInAction(() => {
						if (typeof data.payload !== "string") {
							StoresInstance.domain.EnquiryStore.enquiry.customerDetails.firstName =
								data.payload.customerDetails.firstName || "";
							StoresInstance.domain.EnquiryStore.enquiry.customerDetails.lastName =
								data.payload.customerDetails.lastName || "";
							StoresInstance.domain.EnquiryStore.enquiry.customerDetails.email =
								formState.formData.emailAddress;
							StoresInstance.domain.EnquiryStore.isAllowed = true;
							StoresInstance.domain.EnquiryStore.hasCert = false;
						}
					});
					accountStore.ChangeDetailsLogin(data.payload.detailsToken);
					history.push("/");
				}
			} else {
				if (data.errors.length > 0) {
					setErrorMessage("* " + data.errors[0].message);
				} else {
					setErrorMessage("* Unknown error has occurred.");
				}
			}
		}
	};

	const doCancel = (): void => {
		onCancel(false);
	};

	return (
		<>
			<div className="mydetails-form">
				<form onSubmit={doSubmit}>
					{errorMessage.length > 0 && (
						<Box pb={1} pt={1}>
							<Typography component="h6" color="error" variant="caption">
								{errorMessage}
							</Typography>
						</Box>
					)}

					<GridSpaced container additionalcss={"margin-bottom: 0;"}>
						<GridSpaced item xs={12} sm={12} md={3}>
							{/* <FormControl margin="normal" fullWidth className=" inputField"> */}
							<InputLabel>Email address</InputLabel>
							<TextField
								margin="normal"
								placeholder="Email address"
								fullWidth
								autoComplete="email"
								{...email("emailAddress", {
									validationRules: [
										(v: string) => validator.isEmail(v) || "Email address is invalid",
										(v: string) => (v && v.length > 0) || "Email address is required",
									],
								})}
								className=" inputField"
							/>
							{/* </FormControl> */}
							{/* 	{formState.showErrors("emailAddress", "login_error", 2)}  */}
							{hideForgot === false && (
								<>
									{/* 		<GridSpaced container>
										<GridSpaced item xs={12} sm={12} md={3}> */}
									<Link href="/account/forgotpassword" className="forgotpwd">
										Forgot password?
									</Link>
									{/* </GridSpaced>
									</GridSpaced> */}
								</>
							)}
						</GridSpaced>

						<GridSpaced item xs={12} sm={12} md={3}>
							{/* EN: Example of a contrik without Textfield */}
							{/* <FormControl margin="normal" fullWidth className=" inputField"> */}
							<InputLabel>Password</InputLabel>
							<TextField
								margin="normal"
								fullWidth
								placeholder="Password"
								autoComplete="current-password"
								{...password("password", {
									validationRules: [(v: string) => (v && v.length > 0) || "Password is required"],
								})}
							/>
							{/* 	{formState.showErrors("password", "login_error")} */}
							{/* </FormControl> */}
						</GridSpaced>

						<GridSpaced item xs={12} sm={12} md={3}>
							<Button type="submit" color="primary" className="submit login" variant="contained">
								{isLoading ? "Please Wait..." : loginBtnText}
							</Button>
						</GridSpaced>

						<GridSpaced item xs={12} sm={12} md={3}>
							<Button variant="contained" className="cancel submit login" onClick={doCancel}>
								{cancelBtnText}
							</Button>
						</GridSpaced>
					</GridSpaced>
				</form>
			</div>
		</>
	);
};
