// Libs
import axios, * as Axios from "axios";

// App
import { CheckHttpStatus } from "./CheckHttpStatus";
import { MergeDefaultConfig } from "./MergeDefaultConfig";
import * as Models from "Shoothill.Components/Models";

export const PostFormWithFile = <TPayload = undefined>(
	url: string,
	file: File,
	model?: any,
	config?: Axios.AxiosRequestConfig,
): Promise<Models.ApiResult<TPayload>> => {
	const formConfig: Axios.AxiosRequestConfig = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	};

	const requestConfig = MergeDefaultConfig(formConfig, config);
	const formData = new FormData();

	// Add model
	if (model) {
		for (const key of Object.keys(model)) {
			const value = model[key];

			formData.append(key, value);
		}
	}

	formData.append("file", file);

	const postFormPromise = axios.post<Models.ApiResult<TPayload>>(url, formData, requestConfig).then(response => {
		CheckHttpStatus(response);

		const apiResult = response.data;

		return apiResult;
	});

	return postFormPromise;
};
