import { EnquiryStore } from "../Domain/EnquiryStore";
import { HubConnection, HubConnectionBuilder, HubConnectionState, RetryContext } from "@microsoft/signalr";
import { observe, autorun } from "mobx";
import { StoresInstance } from "../Stores";

export class PresentHubSignalrClient {
	private signalRPresentHubConnection: HubConnection;

	constructor() {
		this.signalRPresentHubConnection = new HubConnectionBuilder()
			.withUrl(`/hubs/present`)
			.withAutomaticReconnect()
			.build();

		this.signalRPresentHubConnection.start();
	}

	public updateEnquiryRef(ref: string) {
		if (this.signalRPresentHubConnection.state === HubConnectionState.Connected) {
			this.signalRPresentHubConnection.invoke(`ChangeEnquiryRef`, ref);
		}
	}
}
