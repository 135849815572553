// Libs
import * as Axios from "axios";
import _merge from "lodash/merge";

// App
import { GetDefaultConfig } from "./GetDefaultConfig";

export const MergeDefaultConfig: (...configs: (Axios.AxiosRequestConfig | undefined)[]) => Axios.AxiosRequestConfig = (
	...configs
) => _merge(GetDefaultConfig(), ...configs);
