// Libs
import * as MobX from "mobx";

// App
import { User as UserDTO } from "../Dto/User";

export class User {
	public id: string = "";
	@MobX.observable public firstName: string = "";
	@MobX.observable public lastName: string = "";
	@MobX.observable public email: string = "";
	@MobX.observable public concurrencyToken: string = "";
	@MobX.observable public isDeleted: boolean = false;

	constructor(id: string) {
		this.id = id;
	}

	@MobX.action
	public fromDto = (model: UserDTO) => {
		this.id = model.id;
		this.firstName = model.firstName;
		this.lastName = model.lastName;
		this.email = model.email;
		this.concurrencyToken = model.concurrencyToken;
		this.isDeleted = model.isDeleted;
	};
}
