import { Grid, Modal } from "@material-ui/core";

import styled from "styled-components";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
}

export const CustomerWrapper: any = styled.div<IComponentProps>`
	background-color: ${props => (!props.backgroundcolor ? "#ffffff" : props.backgroundcolor)};
	color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#191919")};
	font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : "normal")};
	font-family: "Merriweather Sans", "Roboto Condensed", "Helvetica", "Arial", sans-serif;
	margin: ${props => props.margin};
	box-shadow: none;
	${props => props.additionalcss}
	border-radius: 9px;
	display: flex;
	flex-direction: column;
	flex: 1 0 /* auto */ 100%;
	max-width: 1166px;
	padding: 55px 46px;

	@media (max-width: 1024px/* 375px */) {
		/* max-width: 300px; */
		padding: 20px 16px;
	}

	h2 {
		font-size: 1.26rem;
	}

	.customer-header {
		display: flex;
		flex-direction: row;
		flex: 1 0 auto;
		justify-content: space-between;
		height: auto;
		flex-wrap: wrap;

		.customer-header-img {
			display: none;
		}

		@media (min-width: 1024px) {
			.customer-header-img {
				display: flex;
				flex: 0 0 206px;
				vertical-align: middle;
				align-items: center;

				.summerfieldLogo {
					max-height: 83px;
					padding-right: 61px;
				}
			}
		}

		.customer-header-title {
			display: flex;
			flex: 1 0 100%;
			text-align: center;
			@media (min-width: 1024px) {
				flex: 1 0 500px;
				text-align: left;
			}
			flex-wrap: nowrap;
		}

		.customer-header-btn {
			display: flex;
			flex: 0 0 100%;
			@media (min-width: 1024px) {
				flex: 0 0 230px;
			}
			vertical-align: middle;
			align-items: center;

			.submit {
				width: 100%;
			}
		}

		.multiple-product {
			background-color: #f4f6f9;
			width: 100%;
			padding: 15px 20px 25px;
			display: flex;
			flex-direction: column;
			align-items: center;

			button.submit {
				margin: 0;
			}

			h3 {
				margin: 0 0 15px;
				font-size: 0.8655rem;
				width: 100%;
			}

			> div {
				width: 100%;
				display: flex;
				padding-bottom: 20px;
				border-bottom: solid 1px #337ab7;
				margin-bottom: 30px;

				p {
					margin: auto 0 auto 20px;
					font-size: 0.8rem;

					&.price {
						margin-left: auto;
						font-weight: bold;
					}
				}

				> div {
					display: flex;

					button {
						width: 40px;
						height: 40px;
						background-color: white;
						border: 1px solid #ced4da;
						color: #337ab7;
						outline: none;
						font-weight: bold;
						font-size: 17px;
						cursor: pointer;

						:hover {
							background-color: rgba(0, 0, 0, 0.05);
						}

						:active {
							background-color: rgba(0, 0, 0, 0.2);
						}
					}

					span {
						height: 40px;
						padding: 3px 20px;
						border-left: none;
						border-right: none;
						border-top: 1px solid #ced4da;
						border-bottom: 1px solid #ced4da;
						background-color: white;
						outline: none;
						border-radius: 0;
					}
				}
			}
		}
	}

	.customer-mydetails,
	.booking-mydetails {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		width: 100%;
		padding: 0;

		.mydetails-header {
			flex: 1 0 0;
			h2 {
				font-size: 21px;
				color: #344f8e;
			}
		}

		.mydetails-form {
			input,
			textarea {
				border: 1px solid #ced4da;
				background-color: #fff;
				padding: 6px 5px;
				margin-top: 7px;
				font-weight: normal;
				font-size: 17px;
				@media (min-width: 1024px) {
					margin-right: 20px;
					margin-top: 14px;
				}
			}

			textarea {
				padding: 9px 16px;
				width: 100%;
			}

			.patient-info {
				border: 1px solid #337ab7;
				padding: 15px 20px;
				margin-bottom: 25px;

				> div:last-of-type {
					margin-bottom: 0;
				}
			}

			.datePicker {
				input {
					margin-top: -2px;
					margin-right: 0px;
				}

				button {
					margin: 0;
					padding: 6px 5px 6px 5px;
				}
			}

			label {
				font-weight: Bold;
				font-size: 11px;
				color: #263959;
				@media (min-width: 1024px) {
					font-size: 21px;
				}
			}

			.MuiFormControlLabel-label {
				font-size: 0.75rem;
			}

			.formRow {
				@media (min-width: 1024px) {
					> div:last-child input {
						margin-right: 0px;
					}
				}
			}

			.error {
				color: #e64947;
			}

			.PwdInfo {
				p {
					padding: 0;
					margin: 0;
					padding-top: 35px;
				}
			}

			a.forgotpwd {
				font-size: 14px;
				cursor: pointer;
				@media (min-width: 1024px) {
					margin-top: -10px;
				}
			}

			.marginT-1 input {
				margin-top: 1px;
			}
		}

		.mydetails-buttons {
			text-align: center;
			button {
				width: 100%;
				@media (min-width: 1024px) {
					width: 217px;
				}
			}
			@media (min-width: 1024px) {
				button:first-child {
					margin-right: 25px;
				}
			}
		}
	}

	.booking-mydetails {
		padding: 16px 0px;
		background-color: #fff;
		.mydetails-form {
			input {
				margin-top: 0px;
			}

			.submit.login {
				margin-top: 33px;
			}
		}
	}

	.customer-myappointments {
		width: 100%;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
		display: flex;
		flex-direction: column;
		/* font-size: 17px; */
		font-size: 11px;
		@media (min-width: 1024px) {
			font-size: 17px;
		}
		.myappointments-header {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;

			h2 {
				font-size: 21px;
				color: #344f8e;
				margin-bottom: 10px;
				@media (min-width: 1024px) {
					margin-bottom: 21px;
				}
			}

			p {
				margin-top: 0px;
				letter-spacing: 0;
				color: #191919;
				font-weight: normal;
				span {
					color: #263959;
					font-weight: bold;
				}
			}

			.voucher-code-div {
				display: flex;
				align-items: center;

				> p {
					display: inline;
					margin: 0;
				}
			}

			.voucher-code-input {
				display: inline;
				padding: 0 20px;
			}

			.voucher-code-btn {
				display: inline;
			}
		}

		.times {
			background-color: #f4f6f9;
		}
	}

	.submit,
	.cancel {
		border-radius: 3px;
		opacity: 1;
		width: 100%;
		@media (min-width: 1024px) {
			max-width: 247px;
		}
	}

	.cancel {
		background: #141414 0% 0% no-repeat padding-box;
		color: #ffffff;
	}

	.toggleButton {
		width: 32px;
		height: 32px;
	}

	.doPointer {
		cursor: pointer;
	}

	a.tncLink,
	a.tncLink:active,
	:active.tncLink:hover {
		font-size: 0.75rem;
		color: #263959;
		text-decoration: underline;
		margin-left: -10px;
		cursor: pointer;
	}

	div.hr.product-hr {
		margin: 25px 0;
	}

	div.coronavirus-note {
		border: 5px solid red;
		padding: 35px;
		width: 100%;

		h4 {
			text-transform: uppercase;
			color: red;
			margin: 0;
		}

		p {
			margin-bottom: 0;
			font-size: 21px;
		}
	}

	div.to-book-disabled {
		opacity: 0;
		pointer-events: none;
	}

	div.id-upload {
		border: 5px solid red;
		padding: 22px 20px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		> div {
			display: flex;
			position: relative;
			height: 100%;

			> div {
				height: 100%;
				width: auto;

				button {
					border-radius: 3px;
					padding-left: 36px;
					padding-right: 36px;
					margin: 0;

					span {
						text-transform: none;
					}
				}
			}

			input {
				display: inline-block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				z-index: 10;
				opacity: 0;
				cursor: pointer;
			}
		}

		p {
			font-size: calc(16px + (18 - 16) * ((100vw - 1366px) / (1900 - 1366)));
			margin: 0;
			font-weight: bold;
			display: inline-block;
			padding: 8px 0;
		}
	}

	div.id-upload.uploaded {
		border-color: #337ab7;
		justify-content: flex-start;

		> div {
			align-items: center;
		}

		p {
			margin-right: 30px;
		}

		p.upload-filename {
			margin-right: 0;
			margin-left: 10px;
		}
	}
`;

export const PlaceHolderHR: any = styled.div<IComponentProps>`
	background: transparent 0% 0% no-repeat padding-box;
	border-top: 1px solid #337ab7;
	margin: ${props => props.margin};
	opacity: 1;
	width: 100%;
	${props => props.additionalcss};
`;

export const GridSpaced: any = styled(Grid)<IComponentProps>`
	padding: ${props => props.padding};
	margin: ${props => props.margin};
	${props => props.additionalcss};

	.durationcost {
		font-weight: Bold;
		font-size: 11px;
		color: #263959;
		@media (min-width: 1024px) {
			font-size: 21px;
		}
		.values {
			color: #191919;
		}
	}

	.MuiInput-underline:before,
	.MuiInput-underline:after,
	.MuiInputBase-input:before,
	.MuiInputBase-input:after {
		border-bottom: none !important;
	}

	select.MuiSelect-select:focus {
		border: none !important;
		background-color: #fff;
	}

	.MuiInput-underline:focus,
	.MuiInputBase-input:focus {
		border: solid 1px rgb(206, 212, 218) !important;
	}

	.MuiSelect-icon {
		height: 100%;
		top: 0;
	}

	&.voucher-input {
		text-align: center;
	}
`;
