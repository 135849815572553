import React, { useRef } from "react";
import { UseRouter } from "../../Shoothill.Core/Utils";
import { TextField, Button, InputAdornment, FormControl } from "@material-ui/core";
import styled from "styled-components";

import { useValidation, usePercentageHeightOfWidth } from "../../Shoothill.Core/Utils";
import validator from "validator";
import { accountNameModel } from "../../Models/FormModels/accountNameModel";
import { observable } from "mobx";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";

import { LoginTextfield } from "../../Shoothill.Components/StylesAppSpecific/LoginReg";

export const FormFPW: any = styled.form`
	.submit,
	.cancel {
		border-radius: 3px;
		opacity: 1;
		max-height: 40px;
	}

	.cancel {
		background: #141414 0% 0% no-repeat padding-box;
		color: #ffffff;
	}

	p.req {
		font-size: 16px;
	}
`;

interface IProps {
	onSubmit: (emailFormModel: accountNameModel) => void;
}

export const ForgotPassword: React.FC<IProps> = props => {
	const { onSubmit } = props;

	const btnElement = useRef<HTMLButtonElement>(null);
	const divElement = useRef<HTMLDivElement>(null);
	usePercentageHeightOfWidth([btnElement, divElement], 0.124);
	const { history } = UseRouter();

	const [formState, { email }] = useValidation<accountNameModel>(new accountNameModel());

	function onCancelClick(): void {
		history.push("../");
	}

	const doSubmit: (e: any) => void = (e: any): void => {
		e.preventDefault();
		if (formState.isFormValid()) {
			onSubmit({ ...formState.formData });
		}
	};

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var isIE: boolean = msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

	return (
		<>
			<FormFPW onSubmit={doSubmit}>
				<h2>I've forgotten my password</h2>
				<p className={"req"}>
					<span>So we can reset your password please enter your login email address below:</span>
				</p>

				<FormControl margin="normal" fullWidth>
					<LoginTextfield
						label=""
						fullWidth
						variant={(isIE ? undefined : "outlined") as "outlined"}
						placeholder="Email address (required)"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<MailOutlinedIcon />
								</InputAdornment>
							),
						}}
						ref={divElement}
						{...email("email", {
							validationRules: [
								(v: string) => validator.isEmail(v) || "* Email address is invalid",
								(v: string) => (v && v.length > 0) || "* Email address is required",
							],
						})}
					/>
				</FormControl>

				<FormControl margin="normal" fullWidth>
					<Button
						type="submit"
						ref={btnElement}
						fullWidth
						variant="contained"
						color="primary"
						className="submit"
					>
						Send me my link
					</Button>
				</FormControl>

				<FormControl margin="normal" fullWidth>
					<Button onClick={onCancelClick} ref={btnElement} fullWidth variant="contained" className="cancel">
						Cancel
					</Button>
				</FormControl>
			</FormFPW>
		</>
	);
};
