import { PWEnquiry } from "./EnquiryLocation";
import { observable, computed, action } from "mobx";
import { TimeClinic } from "./TimeClinic";
import { Customer } from "./Customer";
import { EnquiryDetails } from "./EnquiryDetails";
import { DomainStores } from "Stores/DomainStores";

export class EnquiryData {
	@observable public enquiryLocation: PWEnquiry = new PWEnquiry();
	@observable public timeClinic: TimeClinic = new TimeClinic();
	@observable public enquiryDetails: EnquiryDetails = new EnquiryDetails();
	@observable public customerDetails: Customer = new Customer();
	@observable public patientDetails: Customer = new Customer();
	@observable public appointmentType?: Summerfield.Data.appointmentType;
	@observable public deliveryAddress: Summerfield.Data.address = {};
	@observable public selfIsolationAddress: Summerfield.Data.address = {};

	@computed
	public get is18Plus() {
		return (
			this.appointmentType &&
			(this.appointmentType.id === 91 ||
				this.appointmentType.id === 92 ||
				this.appointmentType.id === 97 ||
				this.appointmentType.id === 98)
		);
	}

	@computed
	public get isReadyForClinics() {
		console.log(this.timeClinic.appointmentTypeReference);
		return (
			this.enquiryLocation.travelDistanceMiles !== undefined &&
			this.enquiryLocation.travelDistanceMiles > 0 &&
			this.timeClinic.appointmentTypeReference
		);
	}

	@action
	public setAppointmentType(appointmentType: Summerfield.Data.appointmentType) {
		this.appointmentType = appointmentType;
		this.timeClinic.appointmentTypeReference = appointmentType.reference!;
	}

	@action
	public unsetAppointmentType() {
		this.appointmentType = undefined;
		this.timeClinic.appointmentTypeReference = "";
	}

	public toReserveDto() {
		return {
			enquiry: this.enquiryLocation.toDto(),
			timeClinic: this.timeClinic,
		};
	}

	public toAvailableClinicsDto() {
		return {
			appointmentType: this.appointmentType,
			location: {
				latitude: this.enquiryLocation.latitiude,
				longitude: this.enquiryLocation.longitude,
			},
			travelDistanceMiles: this.enquiryLocation.travelDistanceMiles,
		};
	}

	public toDetailsDto(domainStores: DomainStores) {
		return {
			enquiry: {
				...this.enquiryDetails.toDto(),
				appointmentType: this.appointmentType,
			},
			customerDetails: this.customerDetails.toDto(),
			deliveryAddress: domainStores.EnquiryStore.seperateDeliveryAddress ? this.deliveryAddress : undefined,
			selfIsolationAddress: domainStores.EnquiryStore.seperateSelfIsolationAddress
				? this.selfIsolationAddress
				: undefined,
		};
	}
}
