import BaseLayer from "./BaseViewModel";
import axios, * as Axios from "axios";

// App
import { MergeDefaultConfig } from "Shoothill.Core/Utils/Axios/MergeDefaultConfig";
import { CheckHttpStatus } from "Shoothill.Core/Utils/Axios/CheckHttpStatus";
import * as Models from "Shoothill.Components/Models";
import { request } from "http";

export default class ServiceBaseViewModel extends BaseLayer {
	constructor() {
		super();
	}

	Get = <TPayload = Models.ApiResult<undefined>>(
		url: string,
		model?: any,
		config?: Axios.AxiosRequestConfig,
	): Promise<Models.ApiResult<TPayload>> => {
		const requestConfig = this.getConfig(config);
		this.setIsLoading(true);
		const postPromise = axios
			.get<Models.ApiResult<TPayload>>(url, { ...requestConfig, params: model })
			.then(response => {
				this.setIsLoading(false);
				CheckHttpStatus(response);
				return response.data;
			});

		return postPromise;
	};

	Post = <TPayload = Models.ApiResult<undefined>>(
		url: string,
		model?: any,
		config?: Axios.AxiosRequestConfig,
	): Promise<Models.ApiResult<TPayload>> => {
		const requestConfig = this.getConfig(config);
		this.setIsLoading(true);

		const postPromise = axios
			.post<Models.ApiResult<TPayload>>(url, model, requestConfig)
			.then(response => {
				this.setIsLoading(false);
				CheckHttpStatus(response);
				return response.data;
			})
			.catch(e => {
				if (e.response.status === 400) return e.response.data;
			});

		return postPromise;
	};

	getConfig = (config?: Axios.AxiosRequestConfig) => {
		const requestConfig = MergeDefaultConfig(config);
		requestConfig.headers = {
			Authorization: "Bearer " + (window as any).jwt,
		};
		return requestConfig;
	};
}
