import { Button, Grid } from "@material-ui/core";

import styled from "styled-components";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
}

export const AppointmentUL: any = styled.ul<IComponentProps>`
	padding: ${props => props.padding};
	margin: ${props => props.margin};
	list-style-type: none !important;
	${props => props.additionalcss}
`;

export const AppointmentLI: any = styled.li<IComponentProps>`
	border: solid 1px #cccccc;
	padding: ${props => props.padding};
	margin: ${props => props.margin};
	${props => props.additionalcss}

	.appointment,
	.location {
		display: block;
		width: 100%;
		margin: 0;
	}

	.duration,
	.cost {
		display: inline-block;
		margin-right: 20px;
	}

	.appointment,
	.duration span,
	.cost span {
		font-weight: bold;
		color: #263959;
	}

	.myappointments-item {
		padding: 9px;
	}

	div.result,
	div.result-await {
		height: 100%;
		display: flex;
		align-items: center;
		text-align: center;
		/* padding: 0 20px; */
	}

	div.result {
		cursor: pointer;
		display: inline-flex;
		padding-right: 18px;

		:hover div.link {
			text-decoration: underline;
		}

		svg {
			margin-right: 10px;
		}

		div.link {
			font-weight: bold;
			color: #344f8e;
		}
	}
`;

export const AmendBookingBTN: any = styled(Button)<IComponentProps>`
	width: 114px;
	height: 114px;
	background-color: #337ab7;
	color: #ffffff;
	border-radius: 3px;
	padding: ${props => props.padding};
	margin: ${props => props.margin};
	${props => props.additionalcss}
`;
