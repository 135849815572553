import DateFnsUtils from "@date-io/date-fns";
import { Box, Checkbox, FormControlLabel, Grid, InputLabel, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import validator from "validator";

import { GridSpaced } from "Shoothill.Components/StylesAppSpecific/Customer";
import { AltSelect } from "Shoothill.Components/StylesAppSpecific/LocationSearchStyling";
import { useValidation } from "Shoothill.Core/Utils/Hooks/UseValidation";
import { useMediaQuery } from "Shoothill.Core/Utils/Media/mediaQuery";
import * as Validations from "Shoothill.Core/Utils/Validations";

import * as Constants from "Constants/Constants";
import { Customer } from "Models/Domain";
import { CovidTestAndReleaseDatesModel, CovidTestAndReleaseModel } from "Models/FormModels/covidTestAndReleaseModel";
import { StoresInstance } from "Stores/Stores";

interface IProps {
	onlyForMeQuestion?: boolean;
	hideInformationForTestAndRelease?: boolean;
	forMeQuestion?: boolean;
	isFullyVaccinated?: boolean;
	showFullyVaccinatedSelectOption?: boolean;
}

export interface ICovidTestAndReleaseHandles {
	validate():
		| {
				covid: CovidTestAndReleaseModel;
				patient: Summerfield.Data.customer;
		  }
		| undefined;
}

const CovidTestAndReleaseContainer = styled.div``;

const _CovidTestAndRelease: React.RefForwardingComponent<ICovidTestAndReleaseHandles, IProps> = (
	{
		onlyForMeQuestion,
		hideInformationForTestAndRelease,
		forMeQuestion,
		isFullyVaccinated,
		showFullyVaccinatedSelectOption,
	},
	ref,
) => {
	const [dates] = React.useState(() => new CovidTestAndReleaseDatesModel());
	const [formState, { text }] = useValidation(
		new CovidTestAndReleaseModel(
			isFullyVaccinated ? Constants.VACCINATION_FULLY_VACCINATED : Constants.VACCINATION_DEFAULT_VACCINATED,
		),
		{
			validateOnChange: true,
		},
	);
	const [patientFormState, { text: patientText }] = useValidation(
		StoresInstance.domain.EnquiryStore.enquiry.patientDetails,
		{ validateOnChange: true },
	);
	const [patientDateOB, setPatientDateOB] = React.useState<Date | null>(
		StoresInstance.domain.EnquiryStore.enquiry.customerDetails.dateOfBirth,
	);
	const [ethnicityItems] = React.useState(() => {
		const options = [
			"Asian British",
			"Indian",
			"Pakistani",
			"Bangladeshi",
			"White and Black",
			"White and Asian",
			"Other Mixed",
			"Black British",
			"Caribbean/African",
			"White British",
			"White Irish",
			"Other (please state free text)",
		];
		return options.map(opt => (
			<option value={opt} key={"Ethnicity_" + opt}>
				{opt}
			</option>
		));
	});

	const [vaccinationStatusItems] = React.useState(() => {
		const options = isFullyVaccinated
			? [Constants.VACCINATION_FULLY_VACCINATED]
			: showFullyVaccinatedSelectOption === false
			? [Constants.VACCINATION_NOT_VACCINATED, Constants.VACCINATION_PARTIALLY_VACCINATED]
			: [
					Constants.VACCINATION_NOT_VACCINATED,
					Constants.VACCINATION_PARTIALLY_VACCINATED,
					Constants.VACCINATION_FULLY_VACCINATED,
			  ];

		return options.map(opt => (
			<option key={"VaccinationStatus_" + opt} value={opt}>
				{opt}
			</option>
		));
	});

	const ethnicityChanged = React.useRef(false);
	const vaccinationStatusChanged = React.useRef(false);
	const triedSubmit = React.useRef(false);
	const hasValidatedArrival = React.useRef(false);
	const hasValidatedDeparture = React.useRef(false);
	const hasValidatedPatientDOB = React.useRef(false);
	const customEthnicityDirty = React.useRef(false);
	const [ethnicityCustomValid, setEthnicityCustomValid] = React.useState(true);
	const emailBlur = React.useRef(false);
	const useMobile = !!useMediaQuery("screen and (max-width: 1023px)");

	React.useImperativeHandle(ref, () => ({
		validate: () => {
			triedSubmit.current = true;
			hasValidatedArrival.current = true;
			hasValidatedDeparture.current = true;

			let patientDetailsValid = true;
			let isPatientDateValid = true;
			let isForMeValid = doForMeValidation(formState.formData.forMe !== undefined);
			let isSexValid = doSexValidation(formState.formData.sex !== undefined);
			if (formState.formData.forMe === false) {
				patientDetailsValid = patientFormState.isFormValid();
				hasValidatedPatientDOB.current = true;
				isPatientDateValid = isValidPatientDate();
			}

			const arrivalValid = dates.isArrivalValid;
			const departureValid = dates.isDepartureValid;
			console.log(
				formState.isFormValid(),
				arrivalValid,
				departureValid,
				patientDetailsValid,
				isPatientDateValid,
				isForMeValid,
				isSexValid,
			);
			console.log(formState.validity);

			if (
				formState.isFormValid() &&
				((patientDetailsValid && isPatientDateValid && isForMeValid) || !forMeQuestion) &&
				((arrivalValid && departureValid && isSexValid && ethnicityCustomValid) || onlyForMeQuestion)
			) {
				const covid: CovidTestAndReleaseModel = {
					...formState.formData,
					arrival: dates.arrival,
					departure: dates.departure,
				};

				const patient: Summerfield.Data.customer = {
					...Customer.toDto((patientFormState.formData as unknown) as Customer),
					dateOfBirth: patientDateOB!,
				};

				return {
					covid,
					patient,
				};
			}
		},
	}));

	React.useEffect(() => {
		if (!formState.formData.forMe) {
			patientFormState.remount();
		}
	}, [formState.formData.forMe]);

	React.useEffect(() => {
		if (
			formState.formData.ethnicity.toLowerCase().includes("other") &&
			!formState.formData.ethnicity.toLowerCase().includes("mixed")
		) {
			setEthnicityCustomValid(formState.formData.customEthnicity.length > 0);
			console.log("validated", formState.formData.customEthnicity.length > 0);
		} else {
			setEthnicityCustomValid(true);
		}
	}, [formState.formData.ethnicity, formState.formData]);

	function isValidPatientDate() {
		if (patientDateOB !== null) {
			if (!isNaN(patientDateOB.getTime())) {
				return true;
			}
		}
		return false;
	}

	const doForMeValidation = (checked: boolean) => {
		formState.setValidityState({ forMe: checked });
		formState.setErrorsState({ forMe: checked ? [] : ["You must choose an answer"] });
		return checked;
	};

	const setForMe = (e: React.ChangeEvent<HTMLInputElement>, checkedValue: boolean) => {
		formState.setValue("forMe", e.target.checked ? checkedValue : (undefined as any));
		doForMeValidation(e.target.checked);
	};

	const doSexValidation = (checked: boolean) => {
		formState.setValidityState({ sex: checked });
		formState.setErrorsState({ sex: checked ? [] : ["You must choose an answer"] });
		return checked;
	};

	const setSex = (e: React.ChangeEvent<HTMLInputElement>, checkedValue: "female" | "male") => {
		formState.setValue("sex", e.target.checked ? checkedValue : (undefined as any));
		doSexValidation(e.target.checked);
	};

	let customEthnicityOnChange: (e: any) => void;
	let customEthnicityText: {
		onBlur(e: any): void;
		onFocus(e: any): void;
		checked: boolean;
		value: string | number | string[] | undefined;
		name: string;
		id: string;
		type?: string | undefined;
		error: boolean;
	};

	if (
		!onlyForMeQuestion &&
		formState.formData.ethnicity.includes("Other") &&
		!formState.formData.ethnicity.includes("Mixed")
	) {
		const { onChange: _onChange, ...rest } = text("customEthnicity");
		customEthnicityOnChange = _onChange;
		customEthnicityText = rest;
	}

	return useObserver(() => (
		<>
			<CovidTestAndReleaseContainer>
				{!onlyForMeQuestion && !hideInformationForTestAndRelease && (
					<GridSpaced item xs={12} additionalcss="margin-bottom: 25px;">
						<Typography variant="h4">Information required for Test and Release COVID-19 Test</Typography>
					</GridSpaced>
				)}
				{forMeQuestion && (
					<>
						{onlyForMeQuestion && (
							<div style={{ borderBottom: "1px dashed #337AB7", marginBottom: "25px" }} />
						)}
						<GridSpaced additionalcss="margin-bottom: 25px;" item xs={12} sm={12} md={12}>
							<InputLabel>Is this test for you?</InputLabel>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={formState.formData.forMe === true}
										onChange={e => setForMe(e, true)}
									/>
								}
								label={`Yes`}
							/>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={formState.formData.forMe === false}
										onChange={e => setForMe(e, false)}
									/>
								}
								label={`No`}
							/>
							{formState.showErrors("forMe")}
						</GridSpaced>

						{formState.formData.forMe === false && (
							<>
								<GridSpaced
									container
									spacing={2}
									additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
									className={"formRow"}
								>
									<Grid item xs={12} sm={12} md={12}></Grid>
									<Grid item xs={12} sm={12} md={3}>
										<InputLabel>Patient First name*</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="firstName"
											{...patientText("firstName", {
												validationRules: Validations.baseValidation,
											})}
										/>
										{patientFormState.showErrors("firstName")}
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<InputLabel>Last name*</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="lastName"
											{...patientText("lastName", {
												validationRules: Validations.baseValidation,
											})}
										/>
										{patientFormState.showErrors("lastName")}
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<InputLabel>Date of birth*</InputLabel>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<Grid item className={"adminClasses.datePickerGridContainer"}>
												<Box textAlign="left" className={"adminClasses.datePickerBox"}>
													<KeyboardDatePicker
														className={"datePicker"}
														fullWidth
														margin="normal"
														id="date-of-birth-picker-dialog"
														format="dd/MM/yyyy"
														value={patientDateOB}
														minDate={new Date("1910-01-01")}
														onChange={e => setPatientDateOB(e)}
														inputProps={{
															onBlur: () => {
																hasValidatedPatientDOB.current = true;
															},
														}}
														KeyboardButtonProps={{
															"aria-label": "change date",
														}}
													/>
													{hasValidatedPatientDOB.current && patientDateOB === null && (
														<>
															<div className="error">This value is required</div>
														</>
													)}
												</Box>
											</Grid>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={12} sm={12} md={3}></Grid>
								</GridSpaced>

								<GridSpaced
									container
									spacing={2}
									additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
									className={"formRow"}
								>
									<Grid item xs={12} sm={12} md={3}>
										<InputLabel>Contact no.(mobile)*</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="mobileNumber"
											{...patientText("mobileNumber", {
												validationRules: Validations.baseValidation,
											})}
										/>
										{patientFormState.showErrors("mobileNumber")}
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<InputLabel>Contact no.(home):</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="number"
											{...patientText("number", {})}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<InputLabel>Email address*</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="emailAddress"
											{...patientText("email", {
												validationRules: [
													...Validations.baseValidation,
													(v: string) =>
														!emailBlur.current ||
														validator.isEmail(v) ||
														"This value must be an email",
												],
											})}
											onBlur={e => {
												if (e.target.value) {
													emailBlur.current = true;
													patientFormState.validate("email");
												}
											}}
										/>
										{patientFormState.showErrors("email")}
									</Grid>
								</GridSpaced>

								<div style={{ borderBottom: "1px dashed #337AB7", marginBottom: "25px" }} />

								<GridSpaced
									container
									spacing={2}
									additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
									className={"formRow"}
								>
									<Grid item xs={12} sm={12} md={6}>
										<InputLabel>Patient Address line 1*</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="addressLine1"
											{...patientText("addressLine1", {
												validationRules: Validations.baseValidation,
											})}
										/>
										{patientFormState.showErrors("addressLine1")}
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<InputLabel>Address line 2</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="addressLine2"
											{...patientText("addressLine2", {})}
										/>
										{patientFormState.showErrors("addressLine2")}
									</Grid>
								</GridSpaced>

								<GridSpaced
									container
									spacing={2}
									additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
									className={"formRow"}
								>
									<Grid item xs={12} sm={12} md={6}>
										<InputLabel>Address line 3</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="addressLine3"
											{...patientText("addressLine3", {})}
										/>
										{patientFormState.showErrors("addressLine3")}
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<InputLabel>Town/City*</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="townOrCity"
											{...patientText("townOrCity", {
												validationRules: Validations.baseValidation,
											})}
										/>
										{patientFormState.showErrors("townOrCity")}
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<InputLabel>County</InputLabel>
										<TextField placeholder={""} fullWidth autoComplete="county" />
									</Grid>
								</GridSpaced>

								<GridSpaced
									container
									spacing={2}
									additionalcss={useMobile ? "" : "margin-bottom: 25px;"}
									className={"formRow"}
								>
									<Grid item xs={12} sm={12} md={3}>
										<InputLabel>Postcode*</InputLabel>
										<TextField
											placeholder={""}
											fullWidth
											autoComplete="postcode"
											{...patientText("postcode", {
												validationRules: Validations.baseValidation,
											})}
										/>
										{patientFormState.showErrors("postcode")}
									</Grid>
									<Grid item xs={12} sm={12} md={3}></Grid>
								</GridSpaced>
							</>
						)}

						{onlyForMeQuestion || (
							<div style={{ borderBottom: "1px dashed #337AB7", marginBottom: "25px" }} />
						)}
					</>
				)}

				{onlyForMeQuestion || (
					<>
						<GridSpaced additionalcss="margin-bottom: 25px; padding: 8px 0;" item xs={12} sm={12} md={12}>
							<InputLabel>Please select your sex</InputLabel>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={formState.formData.sex === "male"}
										onChange={e => setSex(e, "male")}
									/>
								}
								label={`Male`}
							/>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={formState.formData.sex === "female"}
										onChange={e => setSex(e, "female")}
									/>
								}
								label={`Female`}
							/>
							{formState.showErrors("sex")}
						</GridSpaced>

						<GridSpaced container spacing={2} className={"formRow"}>
							<Grid item xs={12} sm={12} md={6}>
								<InputLabel>Your ethnicity</InputLabel>
							</Grid>
							{formState.formData.ethnicity.includes("Other") &&
								!formState.formData.ethnicity.includes("Mixed") && (
									<>
										<Grid item xs={12} sm={12} md={6}>
											<InputLabel>Please Specify</InputLabel>
										</Grid>
									</>
								)}
						</GridSpaced>

						<GridSpaced container spacing={2} additionalcss={"margin-bottom: 25px;"} className={"formRow"}>
							<Grid item xs={12} sm={12} md={6}>
								<AltSelect
									className={"appointmentType border-correct"}
									name={"ethnicity"}
									onChange={(e: any) => {
										formState.setValue("ethnicity", e.target.value);
										formState.remount();
										if (!e.target.value) {
											ethnicityChanged.current = false;
										} else {
											ethnicityChanged.current = true;
										}
									}}
									fullWidth
									value={formState.formData.ethnicity}
									native
									fontsize={"17px"}
								>
									<option value="">Please Choose...</option>
									{ethnicityItems}
								</AltSelect>
								{!formState.formData.ethnicity && (ethnicityChanged.current || triedSubmit.current) && (
									<>
										<div className="error">Please select an option</div>
									</>
								)}
							</Grid>
							{formState.formData.ethnicity.includes("Other") &&
								!formState.formData.ethnicity.includes("Mixed") && (
									<>
										<Grid item xs={12} sm={12} md={6}>
											<TextField
												className="marginT-1"
												fullWidth
												{...customEthnicityText}
												onChange={e => {
													customEthnicityDirty.current = true;
													customEthnicityOnChange(e);
												}}
											/>
											{!ethnicityCustomValid &&
												(customEthnicityDirty.current || triedSubmit.current) && (
													<p style={{ color: "red", fontSize: "11px" }}>Required</p>
												)}
										</Grid>
									</>
								)}
						</GridSpaced>

						<GridSpaced
							container
							spacing={2}
							additionalcss={useMobile ? "margin-top: 25px;" : "margin-top: 25px; margin-bottom: 25px;"}
							className={"formRow"}
						>
							<Grid item xs={12} sm={12} md={6}>
								<InputLabel>What is your passport number?</InputLabel>
								<TextField
									placeholder={""}
									fullWidth
									autoComplete="new-password"
									{...text("nhsNumber", {
										validationRules: Validations.baseValidation,
									})}
								/>
								{formState.showErrors("nhsNumber")}
							</Grid>
						</GridSpaced>

						<Grid item xs={12} sm={12} md={3}>
							<InputLabel>Date of arrival in UK</InputLabel>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Grid item className={"adminClasses.datePickerGridContainer"}>
									<Box textAlign="left" className={"adminClasses.datePickerBox"}>
										<KeyboardDatePicker
											className={"datePicker"}
											fullWidth
											margin="normal"
											id="arrival-picker-dialog"
											format="dd/MM/yyyy"
											value={dates.arrival}
											minDate={new Date("1910-01-01")}
											onChange={e => dates.setArrival(e)}
											inputProps={{
												onBlur: () => {
													hasValidatedArrival.current = true;
												},
											}}
											KeyboardButtonProps={{
												"aria-label": "change date",
											}}
										/>
										{hasValidatedArrival.current && dates.arrival === null && (
											<>
												<div className="error">This value is required</div>
											</>
										)}
									</Box>
								</Grid>
							</MuiPickersUtilsProvider>
						</Grid>

						<GridSpaced
							container
							spacing={2}
							additionalcss={useMobile ? "margin-top: 25px;" : "margin-top: 25px; margin-bottom: 25px;"}
							className={"formRow"}
						>
							<Grid item xs={12} sm={12} md={6}>
								<InputLabel>Coach/flight number or vessel name</InputLabel>
								<TextField
									placeholder={""}
									fullWidth
									autoComplete="new-password"
									{...text("coachNumber", {
										validationRules: Validations.baseValidation,
									})}
								/>
								{formState.showErrors("coachNumber")}
							</Grid>
						</GridSpaced>

						<Grid item xs={12} sm={12} md={12}>
							<InputLabel>
								Date of departure or transition through a non-exempt country or territory, or a
								non-exempt region of a country or territory ‒ a destination not on the travel corridors
								list
							</InputLabel>
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Grid item className={"adminClasses.datePickerGridContainer"}>
									<Box textAlign="left" className={"adminClasses.datePickerBox"}>
										<KeyboardDatePicker
											className={"datePicker"}
											fullWidth
											margin="normal"
											id="arrival-picker-dialog"
											format="dd/MM/yyyy"
											value={dates.departure}
											minDate={new Date("1910-01-01")}
											onChange={e => dates.setDeparture(e)}
											inputProps={{
												onBlur: () => {
													hasValidatedDeparture.current = true;
												},
											}}
											KeyboardButtonProps={{
												"aria-label": "change date",
											}}
										/>
										{hasValidatedDeparture.current && dates.departure === null && (
											<>
												<div className="error">This value is required</div>
											</>
										)}
									</Box>
								</Grid>
							</MuiPickersUtilsProvider>
						</Grid>

						<GridSpaced
							container
							spacing={2}
							additionalcss={useMobile ? "margin-top: 25px;" : "margin-top: 25px; margin-bottom: 25px;"}
							className={"formRow"}
						>
							<GridSpaced additionalcss="padding-bottom: 0 !important;" item xs={12} sm={12} md={12}>
								<InputLabel>
									The country or territory travelled from prior to arrival in the UK, and any country
									or territory transited through as part of that journey
								</InputLabel>
							</GridSpaced>
							<GridSpaced additionalcss="padding-top: 0 !important;" item xs={12} sm={12} md={6}>
								<TextField
									placeholder={""}
									fullWidth
									autoComplete="new-password"
									{...text("priorToArrival", {
										validationRules: Validations.baseValidation,
									})}
								/>
								{formState.showErrors("priorToArrival")}
							</GridSpaced>
						</GridSpaced>

						{!isFullyVaccinated && (
							<React.Fragment>
								<GridSpaced container spacing={2} className={"formRow"}>
									<Grid item xs={12} sm={12} md={6}>
										<InputLabel>Vaccination status</InputLabel>
									</Grid>
								</GridSpaced>
								<GridSpaced
									container
									spacing={2}
									additionalcss={"margin-bottom: 25px;"}
									className={"formRow"}
								>
									<Grid item xs={12} sm={12} md={6}>
										<AltSelect
											className={"appointmentType border-correct"}
											name={"vaccinationStatus"}
											onChange={(e: any) => {
												formState.setValue("vaccinationStatus", e.target.value);
												formState.remount();
												console.log(e.target.value);
												if (!e.target.value) {
													vaccinationStatusChanged.current = false;
												} else {
													vaccinationStatusChanged.current = true;
												}
											}}
											fullWidth
											value={formState.formData.vaccinationStatus}
											native
											fontsize={"17px"}
										>
											<option value="">Please Choose...</option>
											{vaccinationStatusItems}
										</AltSelect>
										{!formState.formData.vaccinationStatus &&
											(vaccinationStatusChanged.current || triedSubmit.current) && (
												<div className="error">Please select an option</div>
											)}
									</Grid>
								</GridSpaced>
								{showFullyVaccinatedSelectOption === false && (
									<p style={{ fontSize: "0.75rem" }}>
										Please note ‘fully vaccinated’ is not an option for this appointment type, if
										you are travelling from a non-red country and are fully vaccinated you do not
										need a day 8 test and should choose ‘COVID-19 PCR Test Day 2 (Fully Vacc’d)’
										from our appointment list.
									</p>
								)}
							</React.Fragment>
						)}
					</>
				)}
				<div className="hr product-hr" />
			</CovidTestAndReleaseContainer>
		</>
	));
};

export const CovidTestAndRelease = React.forwardRef(_CovidTestAndRelease);
