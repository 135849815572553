import styled from "styled-components";
import { Grid, Button, Modal, Paper } from "@material-ui/core";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
}

export const SHCModal = styled(Modal)<IComponentProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px #ccc;
`;

export const SHCPaper: any = styled(Paper)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	background-color: ${props => (props.backgroundcolor !== undefined ? props.backgroundcolor : "#ffffff")};
	max-width: 360px;
	width: 100%;

	.customer-mydetails,
	.customer-mydetails label,
	.customer-mydetails .MuiInputBase-input {
		font-size: 15px;
		font-weight: 700;
		color: #141414;
	}

	.customer-mydetails {
		label {
			margin-bottom: 7px;
		}

		.MuiInputBase-input {
			padding: 3px 7px;
			border: solid 1px #ccc;
			font-weight: 100;
		}

		button {
			width: 100%;
		}

		.error {
			font-size: 10px;
			color: red;
			font-weight: 100;
		}
	}

	.customer-paymentheader,
	form {
		padding: 10px;
	}

	.customer-paymentheader {
		background-color: #555964;
		color: #ffffff;
		padding: 15px 10px;
	}
`;
