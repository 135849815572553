import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
// import { fade } from "@material-ui/core/styles/colorManipulator";
import { Theme } from "@material-ui/core/styles";

// import SearchIcon from "@material-ui/icons/Search";
import MailIcon from "@material-ui/icons/Mail";
import { Button, Drawer, AppBar, List } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import styled from "styled-components";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Link from "@material-ui/core/Link";
import { useObserver } from "mobx-react-lite";
import { StoresContext, Stores } from "Stores";

import BackgroundHeader from "../../Content/images/summerfieldLogo@2x.png";

const drawerWidth = 240;

export interface LogoProps {
	width: string;
	height: string;
}
export const Logo = styled.div<LogoProps>`
	background-image: url(${BackgroundHeader});
	background-repeat: no-repeat;
	background-size: ${props => props.width} ${props => props.height};
	width: ${props => props.width};
	height: ${props => props.height};
`;

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		height: "70px",
	},
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: "#FFFFFF",
		height: "70px",
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: "0 8px",
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	routerLink: {
		// color: theme.palette.common.white,
		// textDecoration: "none"
	},
	a: {
		// color: theme.palette.common.white
	},
}));

export const Header: React.FC = () => {
	const classes = useStyles();
	const theme: Theme = useTheme();
	const [open, setOpen] = useState(false);
	const storesContext = useContext<Stores>(StoresContext);

	const handleDrawerOpen = () => setOpen(true);
	const handleDrawerClose = () => setOpen(false);

	function onLogoClick(): void {}

	return useObserver(() => (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				color="default"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar style={{ height: "70px" }}>
					{/* <IconButton
						color="inherit"
						aria-label="Open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, open && classes.hide)}
					> 
						<MenuIcon />
					</IconButton>*/}
					{/* <BackgroundHeader /> */}
					<Typography variant="h3" noWrap>
						<a href="https://www.summerfieldhealthcare.co.uk" target="_self">
							<Logo width="94px" height="38px" />
						</a>
					</Typography>
					{storesContext.domain.AccountStore.IsLoggedIn ? (
						<Button color="inherit" style={{ marginLeft: "auto" }}>
							<Link onClick={() => storesContext.domain.AccountStore.Logout()}>Logout</Link>
						</Button>
					) : (
						<></> /* Nothing to show */
					)}
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</IconButton>
				</div>
				<Divider />
				<List>
					{["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
						<ListItem button key={text}>
							<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
							<ListItemText primary={text} />
						</ListItem>
					))}
				</List>
				<Divider />
				<List>
					{["All mail", "Trash", "Spam"].map((text, index) => (
						<ListItem button key={text}>
							<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
							<ListItemText primary={text} />
						</ListItem>
					))}
				</List>
			</Drawer>
		</div>
	));
};
