import { ApiResult } from "Shoothill.Components/Models";
import { Stores } from "../Stores";
import * as Models from "../../Models";
import * as MobX from "mobx";
import * as ServerApi from "../../ServerApi";

// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";
import { InitialState, SourceOfInterestModel } from "../../Models";

export class SourceOfInterestStore extends BaseStore {
	@observable public sourceofInterestList: SourceOfInterestModel[] = [];
	//@observable public sourceofInterest: SourceOfInterestModel; <--- Later!

	@observable private isAPILoading: boolean = false;

	public constructor() {
		super();
	}

	public init(stores: Stores, initialState: InitialState) {
		this.stores = stores;
	}

	@action
	public ResetSourceOfInterestList(): void {
		this.sourceofInterestList = [];
	}

	@action
	public GetSourceOfInterestList(): Promise<SourceOfInterestModel[]> {
		if (this.isAPILoading === false) {
			this.isAPILoading = true;

			let task = ServerApi.SourceOfInterest.GetAll()
				.then((sourceofInterestList: ApiResult<SourceOfInterestModel[]>) => {
					if (sourceofInterestList.wasSuccessful === true) {
						MobX.runInAction(() => {
							this.sourceofInterestList.length = 0;
							this.sourceofInterestList = sourceofInterestList.payload;
							this.isAPILoading = false;
						});
					} else {
						console.log("Failed to get the Source of Interest");
					}

					return sourceofInterestList.payload;
				})
				.catch((error: any) => {
					console.log("Excepted. Failed to get the Source of Interest. " + error);
					this.isAPILoading = false;
					return [];
				});

			return task;
		}
		return new Promise(resolve => {
			resolve(this.sourceofInterestList);
		});
	}
}
