import { Customer, PWEnquiry } from "Models/Domain";
import { action, runInAction } from "mobx";
import { bookingConfirmationModel, clientpaymentModel } from "Models/FormModels";

import { ApiResult } from "../Shoothill.Components/Models/ApiResult";
import { AppUrls } from "../Globals";
import { AppointmentVouchers } from "../Models/Domain/AppointmentVouchers";
import { EnquiryDetails } from "Models/Domain/EnquiryDetails";
import ServiceBaseViewModel from "../Shoothill.Components/ViewModels/ServiceBaseViewModel";
import { StoresInstance, Stores } from "../Stores/Stores";
import { TimeClinic } from "Models/Domain/TimeClinic";
import moment from "moment";

interface IDetailsFromRegisterResult extends ApiResult {
	registerResult: ApiResult<any>;
}

export default class EnquiryViewModel extends ServiceBaseViewModel {
	constructor() {
		super();
	}

	public async reserveAppointment(token: string): Promise<ApiResult<any>> {
		const body = StoresInstance.domain.EnquiryStore.enquiry.toReserveDto();

		let apiResult = await this.Post<any>(`${AppUrls.Server.Api.Booking.Reserve}?token=${token}`, body);
		if (apiResult.wasSuccessful) {
			StoresInstance.domain.EnquiryStore.enquiry.enquiryDetails.fromDto(apiResult.payload);

			runInAction(() => {
				StoresInstance.domain.EnquiryStore.sameDayAvailable = false;
			});

			this.Post(AppUrls.Server.Api.Booking.CheckSameDayDriveIn, apiResult.payload).then(response => {
				if (response && response.wasSuccessful && response.payload) {
					runInAction(() => {
						StoresInstance.domain.EnquiryStore.sameDayAvailable = true;
					});
				}
			});
		}

		return apiResult;
	}

	public async deleteReservedAppointment() {
		await this.Post<any>(
			AppUrls.Server.Api.Booking.DeleteReserved,
			StoresInstance.domain.EnquiryStore.enquiry.enquiryDetails.toDto(),
		);
	}

	public async userCheck(email: string): Promise<string> {
		const apiResult = await this.Get<boolean>(`${AppUrls.Server.Api.Booking.Details.UserCheck}?email=${email}`);
		if (apiResult && apiResult.wasSuccessful) {
			return apiResult.payload ? "Registration failed as you've already registered." : "";
		} else {
			return "There was a problem connecting to the server to register you.";
		}
	}

	public async purchaseProduct(
		formData: clientpaymentModel,
		customerDetails: any,
	): Promise<string[] | string | boolean> {
		const body = {
			clientPayment: formData,
			appointmentType: StoresInstance.domain.EnquiryStore.enquiry.appointmentType,
			...customerDetails,
			useExpress: StoresInstance.domain.EnquiryStore.useExpress,
			voucherCode: StoresInstance.domain.EnquiryStore.vouchers.voucherCode,
			passport: StoresInstance.domain.EnquiryStore.passport,
			boxes: StoresInstance.domain.EnquiryStore.boxes,
			addons: {
				cert: StoresInstance.domain.EnquiryStore.addCert,
				certLang: StoresInstance.domain.EnquiryStore.certLang,
				sameDay: StoresInstance.domain.EnquiryStore.sameDay,
			},
		};
		const data = new FormData();
		data.append("body", JSON.stringify(body));
		if (StoresInstance.domain.EnquiryStore.requiresId) {
			data.append(
				"idUpload",
				StoresInstance.domain.EnquiryStore.idUpload!,
				StoresInstance.domain.EnquiryStore.idUpload!.name,
			);
		}
		let apiResult = await this.Post<any>(AppUrls.Server.Api.Payment.TakeProductPayment, data, {
			headers: { "Content-Type": "multipart/form-data" },
		});
		if (apiResult.wasSuccessful) {
			if (apiResult.payload.login) {
				StoresInstance.domain.AccountStore.getLoginState(apiResult.payload.login.payload);
			}
			runInAction(() => {
				StoresInstance.domain.EnquiryStore.enquiry.enquiryDetails.appointmentReference =
					apiResult.payload.reference;
			});
			return true;
		} else {
			if (apiResult.errors[0].type === 2) {
				return apiResult.errors[0].message;
			}
			if (apiResult.payload !== undefined) {
				return apiResult.payload;
			}
			return apiResult.errors[0] as any;
		}
	}

	public async redeemVoucher(voucherCode: string) {
		const response = await this.Post<any>(
			`${AppUrls.Server.Api.Payment.RedeemProductVoucher}?voucherCode=${voucherCode}`,
		);

		return response;
	}

	public async upsertDetailsFromRegister(registrationModel: any): Promise<string> {
		const body = StoresInstance.domain.EnquiryStore.enquiry.toDetailsDto(StoresInstance.domain);
		let apiResult = await this.Post<IDetailsFromRegisterResult>(AppUrls.Server.Api.Booking.Details.Registration, {
			...body,
			registration: {
				password: registrationModel.password,
			},
			customerDetails: {
				...body.customerDetails,
				emailConsentAndSoI: {
					...body.customerDetails.emailConsentAndSoI,
					sourceOfInterest: registrationModel.sourceOfInterest,
				},
			},
		});
		if (apiResult.wasSuccessful) {
			if (apiResult.payload.registerResult.wasSuccessful) {
				StoresInstance.domain.AccountStore.getLoginState(apiResult.payload.registerResult.payload);
			} else {
				return apiResult.payload.registerResult.errors[0].message;
			}
		} else {
			return apiResult.errors[0].message;
		}

		return "";
	}

	public async upsertDetails(): Promise<ApiResult<any>> {
		const body = StoresInstance.domain.EnquiryStore.enquiry.toDetailsDto(StoresInstance.domain);

		let apiResult = await this.Post<any>(AppUrls.Server.Api.Booking.Details.Login, body);
		if (apiResult.wasSuccessful) {
		}

		return apiResult;
	}

	public async updateDetails(): Promise<ApiResult<any>> {
		const body = StoresInstance.domain.EnquiryStore.enquiry.customerDetails.toDto();

		let apiResult = await this.Post<any>(
			`${AppUrls.Server.Api.Account.Details.Upsert}?changeDetailsToken=${encodeURIComponent(
				StoresInstance.domain.AccountStore.ChangeDetailsToken,
			)}`,
			body,
		);
		if (apiResult && apiResult.wasSuccessful) {
			if (typeof apiResult.payload === "string") {
				StoresInstance.domain.AccountStore.getLoginState(apiResult.payload);
			}
		}

		return apiResult;
	}

	public async employeeReg(registrationModel: any) {
		const body = StoresInstance.domain.EnquiryStore.enquiry.customerDetails.toDto();

		let apiResult = await this.Post<any>(AppUrls.Server.Api.Account.RegisterEmployee, {
			customerDetails: {
				...body,
				emailConsentAndSoI: {
					...body.emailConsentAndSoI,
					sourceOfInterest: registrationModel.sourceOfInterest,
				},
			},
			registration: {
				password: registrationModel.password,
			},
		});
		if (apiResult.wasSuccessful) {
			if (apiResult.payload.registerResult.wasSuccessful) {
				StoresInstance.domain.AccountStore.getLoginState(apiResult.payload.registerResult.payload);
			} else {
				return apiResult.payload.registerResult.errors[0].message;
			}
		} else {
			return apiResult.errors[0].message;
		}
		return "";
	}

	public async fetchCustomerDetails(): Promise<boolean> {
		let apiResult = await this.Get<any>(AppUrls.Server.Api.Account.Details.Fetch);
		if (apiResult.wasSuccessful) {
			StoresInstance.domain.EnquiryStore.enquiry.customerDetails.fromDto(apiResult.payload);
			return true;
		}
		return false;
	}

	public async upsertPaymentDetails(formData: clientpaymentModel): Promise<string[] | string | boolean> {
		const body = {
			...formData,
			enquiry: StoresInstance.domain.EnquiryStore.enquiry.enquiryDetails.toDto(),
			voucherCode: StoresInstance.domain.EnquiryStore.vouchers.voucherCode,
			passport: StoresInstance.domain.EnquiryStore.passport,
			addons: {
				cert: StoresInstance.domain.EnquiryStore.addCert,
				certLang: StoresInstance.domain.EnquiryStore.certLang,
				sameDay: StoresInstance.domain.EnquiryStore.sameDay,
			},
		};
		const formBody = new FormData();
		formBody.append("body", JSON.stringify(body));
		if (StoresInstance.domain.EnquiryStore.requiresId) {
			formBody.append(
				"idUpload",
				StoresInstance.domain.EnquiryStore.idUpload!,
				StoresInstance.domain.EnquiryStore.idUpload!.name,
			);
		}
		let apiResult = await this.Post<any>(AppUrls.Server.Api.Payment.TakePayment, formBody, {
			headers: { "Content-Type": "multipart/form-data" },
		});
		if (apiResult.wasSuccessful) {
			return true;
		} else {
			if (apiResult.errors[0].type === 2) {
				return apiResult.errors[0].message;
			}
			return apiResult.payload;
		}
	}

	public async doProductRangeCheck(postcode: string) {
		const apiResult = await this.Get<boolean | { cost: number }>(
			`${AppUrls.Server.Api.AppointmentType.ProductRangeCheck}?postcode=${encodeURI(
				postcode,
			)}&appointmentTypeRef=${StoresInstance.domain.EnquiryStore.enquiry.appointmentType!.reference}`,
		);
		if (apiResult && apiResult.wasSuccessful && apiResult.payload) {
			const cost = (apiResult.payload as { cost: number }).cost;
			StoresInstance.domain.EnquiryStore.setExpressCost(cost);
		} else {
			StoresInstance.domain.EnquiryStore.setExpressCost(undefined);
		}
	}

	public async getNextExpressDate() {
		const apiResult = await this.Get<Date | null>(
			`${AppUrls.Server.Api.AppointmentType.NextExpressDeliveryDate}?appointmentTypeRef=${
				StoresInstance.domain.EnquiryStore.enquiry.appointmentType!.reference
			}`,
		);
		if (apiResult && apiResult.wasSuccessful && apiResult.payload) {
			return moment(apiResult.payload).format("dddd Do MMMM");
		}
		return null;
	}

	public appointmentComplete() {
		runInAction(() => (StoresInstance.domain.EnquiryStore.enquiry.enquiryDetails.appointmentStatus = 2));
	}

	@action
	public reset() {
		StoresInstance.domain.EnquiryStore.appointmentCost = 0;
		StoresInstance.domain.EnquiryStore.appointmentDuration = 0;
		StoresInstance.domain.EnquiryStore.isProduct = false;
		StoresInstance.domain.EnquiryStore.expressCost = undefined;
		StoresInstance.domain.EnquiryStore.hasExpress = false;
		StoresInstance.domain.EnquiryStore.idUpload = null;
		StoresInstance.domain.EnquiryStore.isAllowed = undefined;
		StoresInstance.domain.EnquiryStore.requiresId = false;
		StoresInstance.domain.EnquiryStore.hasCert = false;
		StoresInstance.domain.EnquiryStore.allowVoucher = false;
		StoresInstance.domain.EnquiryStore.addCert = false;
		StoresInstance.domain.EnquiryStore.sameDay = false;
		StoresInstance.domain.EnquiryStore.certLang = "";
		StoresInstance.domain.EnquiryStore.passport = undefined;
		StoresInstance.domain.EnquiryStore.showDuration = true;

		StoresInstance.domain.EnquiryStore.enquiry.appointmentType = undefined;
		StoresInstance.domain.EnquiryStore.enquiry.customerDetails = new Customer();
		StoresInstance.domain.EnquiryStore.enquiry.enquiryDetails = new EnquiryDetails();
		StoresInstance.domain.EnquiryStore.enquiry.enquiryLocation = new PWEnquiry();
		StoresInstance.domain.EnquiryStore.enquiry.timeClinic = new TimeClinic();
		StoresInstance.domain.EnquiryStore.vouchers = new AppointmentVouchers();
	}
}
