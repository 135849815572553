import { useState, useRef, useEffect } from "react";

interface UseTimeoutHandler {
	start: () => any;
	clear: () => any;
	stop: () => any;
	isActive: boolean;
}
/**
 *
 * @param cb
 * @param timeoutDelayMs
 *
 * Usage: const { start, clear } = useTimeout(doAlert, 2000);
 *
 */
function useTimeout(cb: () => void, timeoutDelayMs: number = 0): UseTimeoutHandler {
	const [isTimeoutActive, setIsTimeoutActive] = useState(false);
	const savedRefCallback = useRef<() => any>();

	useEffect(() => {
		savedRefCallback.current = cb;
	}, [cb]);

	function clear() {
		setIsTimeoutActive(false);
	}

	function start() {
		setIsTimeoutActive(true);
	}

	useEffect(() => {
		function callback() {
			savedRefCallback.current && savedRefCallback.current();
			clear();
		}

		if (isTimeoutActive) {
			const timeout = window.setTimeout(callback, timeoutDelayMs);
			return () => {
				window.clearTimeout(timeout);
			};
		}
	}, [isTimeoutActive]);

	return {
		clear,
		start,
		stop: clear,
		isActive: isTimeoutActive,
	};
}

export { useTimeout };
