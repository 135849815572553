import moment from "moment-timezone";

export const dateTimeFormat = "DD/MM/YYYY";
export const FormatMoment = (momentDateTime: moment.Moment, format: string) => {
	if (!momentDateTime.isValid()) {
		return "";
	}

	return momentDateTime.tz("Europe/London").format(format);
};
export const FormatMomentString = (rawDateTime: string | undefined, format: string) => {
	if (!rawDateTime) {
		return "";
	}

	const momentDateTime = moment.utc(rawDateTime);

	return FormatMoment(momentDateTime, format);
};

export const dateTimeString = (rawDateTime: string | undefined) => FormatMomentString(rawDateTime, dateTimeFormat);
