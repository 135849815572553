export const Deg2Rad = (deg: number) => {
	return (deg * Math.PI) / 180;
};

export const PythagorasEquirectangular = (lat1: number, lon1: number, lat2: number, lon2: number) => {
	lat1 = Deg2Rad(lat1);
	lat2 = Deg2Rad(lat2);
	lon1 = Deg2Rad(lon1);
	lon2 = Deg2Rad(lon2);
	const R = 6371;
	const x: number = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
	const y: number = lat2 - lat1;
	const d: number = Math.sqrt(x * x + y * y) * R;
	return d;
};
