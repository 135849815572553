import styled from "styled-components";
import { Typography, FormControl, InputLabel, Button, TextField, Box, InputAdornment, Link } from "@material-ui/core";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
}

export const ForgotPwd: any = styled.div`
	font-size: 0.5rem;
	width: 100%;
	text-align: center;
	color: white;
	@media (max-width: 1023px) {
		font-size: 0.9rem;
	}

	a,
	a:hover,
	a:visited {
		color: white;
	}
`;

export const LoginTitle: any = styled.div`
	padding: 30px 0 10px 0;
	width: 100%;
	text-align: left;
	color: #ffffff;
	font-size: 2rem;
	font-weight: bold;
	line-height: 1.7rem;
	@media (max-width: 1023px) {
		text-align: center;
	}
`;

export const LoginTextfield: any = styled(TextField)`
	color: #999999;
	background-color: #ffffff !important;

	.MuiInputBase-adornedStart {
		background-color: #ffffff !important;
		color: #8ba9c4;
		border: 1px solid #cccccc !important;

		.MuiInputAdornment-positionStart {
			font-size: 1em;
			padding: 0 5px;
			svg {
				fill: #8ba9c4;
			}
		}
	}
`;

export const LoginPaper: any = styled.div`
	background-color: #263959;
	color: #ffffff;
	font-weight: normal;
	font-family: "Merriweather Sans", "Roboto Condensed", "Helvetica", Arial, sans-serif;
	box-shadow: none;
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;

	label {
		display: none;
	}

	.MuiGrid-item {
		margin-left: -8px;
		padding: 0;
	}

	.submit {
		margin-top: 8px;
		border-radius: 3px;
	}

	.error {
		color: red !important;
	}

	form {
		width: 100%;
		margin-top: 8px;
		box-shadow: none;
		border-radius: 0;
		border: none;
	}

	div:before,
	div:after,
	div:focus {
		/* Nuke's MUI's annoying border-bottom stuff */
		border-bottom: 0 !important;
	}

	.MuiInput-underline:before,
	.MuiInput-underline:after,
	.MuiInput-underline:before:hover,
	.MuiInput-underline:after:hover {
		transition: none;
		border-bottom: none;
	}
`;
