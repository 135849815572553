import { action, observable, runInAction } from "mobx";

import { ApiResult } from "../ApiResult";
import { Post } from "../../Shoothill.Core/Utils/Axios/Post";

interface IVoucherCodeResponse {
	valid: boolean;
	cost: number;
	allTypes: boolean;
	appointmentTypeId?: number;
	forProduct: boolean;
}

export class AppointmentVouchers {
	private fromActivity: boolean;
	@observable public voucherCode = "";
	@observable public isValid = false;
	@observable public newCost?: number;
	@observable public appointmentTypeId?: number;

	constructor(fromActivity: boolean = false) {
		this.fromActivity = fromActivity;
	}

	public async checkCode(appointmentTypeId?: number) {
		const response = await Post<IVoucherCodeResponse>(
			`/api/voucher/checkcode`,
			`voucherCode=${this.voucherCode}${
				appointmentTypeId !== undefined ? "&appointmentTypeId=" + appointmentTypeId : ""
			}`,
		);

		if (response && response.payload) {
			runInAction(() => {
				this.newCost = response.payload.cost;
				this.appointmentTypeId = response.payload.appointmentTypeId;
			});
			if (this.fromActivity) {
				runInAction(() => {
					this.isValid = !response.payload.allTypes && response.payload.cost === 0 && response.payload.valid;
				});
				if (
					response.payload.allTypes ||
					response.payload.cost !== 0 ||
					(!response.payload.forProduct && response.payload.valid)
				) {
					return false;
				}
			} else {
				runInAction(() => {
					this.isValid = response.payload.valid;
				});
			}
		}
		return true;
	}

	@action
	public updateVoucherCode(code: string, appointmentTypeId?: number) {
		this.voucherCode = code;
		if (code) {
			this.checkCode(appointmentTypeId);
		}
	}

	@action
	public reset() {
		this.voucherCode = "";
		this.isValid = false;
		this.newCost = undefined;
	}
}
