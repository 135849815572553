import styled from "styled-components";
import { Grid, Button, Modal, Paper } from "@material-ui/core";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
}

export const TCModal: any = styled(Modal)<IComponentProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px #ccc;
`;

export const TCPaper: any = styled(Paper)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	background-color: ${props => (props.backgroundcolor !== undefined ? props.backgroundcolor : "#ffffff")};
	max-width: 460px;
	width: 100%;
	max-height: 552px;
	height: 100%;

	.terms {
		padding: 10px;
		overflow: auto;
		height: 500px;
	}

	.customer-mydetails {
		font-size: 15px;
		color: #141414;

		h2 {
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
		}

		h6 {
			font-size: 14px;
			padding: 10px 0px;
			margin: 0;
		}

		button {
			margin: 0 auto;
		}
	}

	.customer-paymentheader {
		background-color: #555964;
		color: #ffffff;
		padding: 15px 10px;

		.MuiButton-label,
		button {
			width: 20px;
			height: 20px;
			margin: 0;
			padding: 0;
		}
		svg {
			fill: #ffffff;
			width: 100%;
			height: 100%;
		}
	}
`;
