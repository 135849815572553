// Libs
import { action, computed, observable } from "mobx";

// App
export class Enquiry {
	private enquiryId: number = -1;
	@observable public reference: string = "";
	@observable public locationText: string = "";
	@observable public latitude?: number = 0;
	@observable public longitude?: number = 0;
	//@observable public location: Summerfield.Data.location = { latitude: this.latitude, longitude: this.longitude };
	@observable public appointmentType: Summerfield.Data.appointmentType | undefined = undefined;
	@observable public travelDistanceMiles: number = 0;
	@observable public customer: Summerfield.Data.customerMin | undefined = undefined;

	private note: Summerfield.Data.note | undefined = undefined;
	private actions?: Summerfield.Data.action[] = [];
	private enquiryStatusId: Summerfield.Data.enquiryStatus = 1; //Summerfield.Data.enquiryStatus.approvalNotRequired;
	private created: Date | string = "";

	@action
	public Init(enquiryId: number, reference: string) {}

	@action
	public fromDTO = (enquiry: Summerfield.Data.enquiry) => {
		this.reference = enquiry.reference != undefined ? enquiry.reference : "";
		this.locationText = enquiry.locationText != undefined ? enquiry.locationText : "";
		this.latitude = enquiry.location!.latitude != undefined ? enquiry.location!.latitude : 0;
		this.longitude = enquiry.location!.longitude != undefined ? enquiry.location!.longitude : 0;
		//this.location = enquiry.location!;
		this.appointmentType = enquiry.appointmentType;
		this.travelDistanceMiles = enquiry.travelDistanceMiles != undefined ? enquiry.travelDistanceMiles : 0;
		this.customer = enquiry.customer;
		this.note = enquiry.note;
		this.actions = enquiry.actions;
		this.enquiryStatusId = enquiry.enquiryStatusId != undefined ? enquiry.enquiryStatusId : 0;
		this.created = enquiry.created != undefined ? enquiry.created : "";
	};

	@action
	public toDTO = (): Summerfield.Data.enquiry => {
		return {
			reference: this.reference,
			locationText: this.locationText,
			location: {
				latitude: this.latitude,
				longitude: this.longitude,
			},
			appointmentType: this.appointmentType,
			travelDistanceMiles: this.travelDistanceMiles,
			customer: this.customer,
			note: this.note,
			actions: this.actions,
			enquiryStatusId: this.enquiryStatusId,
			created: this.created,
		};
	};
}
