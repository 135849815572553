import React, { useState, useContext } from "react";
import { Button, Grid } from "@material-ui/core";
import { Redirect } from "react-router";
import { StoresContext, Stores, StoresInstance } from "../../Stores";

import { GridSpaced } from "../StylesAppSpecific/Customer";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { MyDetailsForm } from "Shoothill.Components/Forms/MyDetailsForm";
import EnquiryViewModel from "../../ViewModels/EnquiryViewModel";

const enquiryViewModel = new EnquiryViewModel();

export const MyDetails: React.FC = () => {
	const accountStore = useContext<Stores>(StoresContext).domain.AccountStore;
	const [showDetails, setShowDetails] = useState<boolean>(accountStore.ChangeDetailsOnly);
	const [success, setSuccess] = useState<boolean>(false);

	if (!accountStore.IsLoggedIn) {
		return <Redirect to={"/"} />;
	}

	function toggleDetails(): void {
		StoresInstance.domain.EnquiryStore.setIsAllowed(true);
		if (showDetails) {
			setShowDetails(false);
			setSuccess(false);
		} else {
			enquiryViewModel.fetchCustomerDetails().then(() => {
				setShowDetails(true);
			});
		}
	}

	const handleCancel = (cancelVal: boolean) => {
		setShowDetails(false);
		setSuccess(false);
	};

	const handleSubmit = (subVal: boolean) => {
		setSuccess(true);
	};

	return (
		<>
			<div className="customer-mydetails">
				<div className="mydetails-header">
					<GridSpaced container spacing={2} padding={"0"}>
						<Grid item xs={9}>
							<h2>My details</h2>
						</Grid>
						{accountStore.ChangeDetailsOnly || (
							<GridSpaced item xs={3} additionalcss="text-align: right;">
								{!showDetails && (
									<>
										<Button onClick={toggleDetails}>
											<AddCircleIcon />
										</Button>
									</>
								)}
								{showDetails && (
									<>
										<Button onClick={toggleDetails}>
											<RemoveCircleIcon />
										</Button>
									</>
								)}
							</GridSpaced>
						)}
					</GridSpaced>
				</div>

				{showDetails && (
					<>
						<div className="customer-mydetails">
							<MyDetailsForm
								showExtra={false}
								showPWD={false}
								saveMsg={"Save Changes"}
								cancelMsg={"Cancel Changes"}
								onCancel={handleCancel}
								onSubmit={handleSubmit}
								showFurtherInfo={false}
								hideCancel={accountStore.ChangeDetailsOnly}
							/>
						</div>
					</>
				)}
				{success && (
					<>
						<p>Saved!</p>
					</>
				)}
			</div>
		</>
	);
};
